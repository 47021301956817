import React, { ReactElement } from 'react';

import { Box, SxProps, Typography } from '@mui/material';

import { ViewsIcon, DiscussionIcon, MembersIcon } from 'assets/icons';

export enum ModalTitleVariant {
    standard = 0,
    alert = 1,
    resolve = 2,
}
function getColor(variant: ModalTitleVariant): string {
    switch (variant) {
        case ModalTitleVariant.alert:
            return 'error.main';
        case ModalTitleVariant.resolve:
            return 'success.main';
        case ModalTitleVariant.standard:
            return 'text.primary';
        default:
            return 'text.primary';
    }
}

export const LabeledIcon = ({
    icon,
    label,
    labelFirst,
    sx,
    variant,
}: {
    icon: ReactElement;
    label: string | ReactElement;
    labelFirst?: boolean;
    sx?: SxProps;
    variant?: ModalTitleVariant;
}): ReactElement => {
    const variantSx = variant ? { color: getColor(variant) } : {};
    return (
        <Box mr={3} sx={{ ...variantSx, ...sx }}>
            <Box display="flex" alignItems="center">
                {labelFirst && label}
                {icon}
                {labelFirst !== true && label}
            </Box>
        </Box>
    );
};
LabeledIcon.defaultProps = {
    labelFirst: false,
    sx: {},
    variant: undefined,
};

export const TitleLabel = ({ icon, label }: { icon: ReactElement; label: string }): ReactElement => {
    return (
        <LabeledIcon
            icon={icon}
            label={<Typography sx={{ fontSize: '12px' }}>{label}</Typography>}
            variant={ModalTitleVariant.standard}
        />
    );
};
export const ModalTitleLabel = ({ icon, label }: { icon: ReactElement; label: string }): ReactElement => {
    return (
        <LabeledIcon
            icon={icon}
            variant={ModalTitleVariant.standard}
            label={
                <Typography sx={{ marginLeft: '0.5rem', fontSize: '1.25rem', fontWeight: 600, color: 'text.primary' }}>
                    {label}
                </Typography>
            }
        />
    );
};

export const PageTitleLabel = ({ icon, label }: { icon: ReactElement; label: string }): ReactElement => {
    return (
        <LabeledIcon
            icon={icon}
            label={
                <Typography component="h1" sx={{ marginLeft: '0.5rem', fontSize: '0.875rem', fontWeight: 700 }}>
                    {label}
                </Typography>
            }
        />
    );
};

export const ViewCount = ({ viewCount }: { viewCount: number }): ReactElement => {
    return (
        <LabeledIcon
            icon={<ViewsIcon sx={{ marginRight: '0.5rem' }} />}
            label={
                <span style={{ whiteSpace: 'nowrap' }}>
                    {viewCount} {viewCount === 1 ? 'view' : 'views'}
                </span>
            }
        />
    );
};

export const ParticipantCount = ({ participantCount }: { participantCount: number }): ReactElement => {
    return (
        <LabeledIcon
            icon={<MembersIcon sx={{ marginRight: '0.5rem' }} />}
            label={
                <span style={{ whiteSpace: 'nowrap' }}>
                    {participantCount} {participantCount === 1 ? 'participant' : 'participants'}
                </span>
            }
        />
    );
};

export const ReplyCount = ({ replyCount }: { replyCount: number }): ReactElement => {
    return (
        <LabeledIcon
            icon={<DiscussionIcon sx={{ marginRight: '0.5rem' }} />}
            label={`${replyCount} ${replyCount === 1 ? 'reply' : 'replies'}`}
        />
    );
};
