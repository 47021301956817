import { ReactElement } from 'react';
import { AnyAction } from 'redux';
import { UPDATE_ALERT } from '../actions/Alert';

export type AlertState = {
    visible: boolean | null;
    message?: string | null | ReactElement;
    severity?: 'error' | 'warning' | 'info' | 'success' | undefined;
};

const initialState: any = null;

export default (state = initialState, action: AnyAction): AlertState => {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_ALERT: {
            const updateAlert = { ...state, ...payload };
            return updateAlert;
        }
        default:
            return state;
    }
};
