import React, { ReactElement } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import logo from 'assets/images/afwerx-logo.svg';
import { redirectToLogin } from 'utilities/utils';
import handshake from './assets/Handshake.png';
import searchStar from './assets/Search-Star.png';
import aflogo from './assets/aflogo_white.png';

const Hero = (): ReactElement => {
    const history = useHistory();

    return (
        <div className="hero">
            <Container sx={{ marginLeft: { xs: 0, md: '50px', lg: '100px' }, py: '50px' }}>
                <Box
                    sx={{
                        backgroundImage: `url(${logo})`,
                        backgroundRepeat: 'no-repeat',
                        height: { xs: '50px', md: '75px', lg: '90px' },
                        width: {
                            xs: '335px',
                            md: '446px',
                            lg: '557px',
                        },
                    }}
                />
            </Container>
            <Container
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gridTemplateRows: '1fr',
                    mb: '50px',
                    mt: { xs: 0, md: '50px' },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifySelf: 'center',
                        maxWidth: '140px',
                    }}
                >
                    <Box className="hero-icon-circle">
                        <img className="hero-icon" src={handshake} alt="Handshake" height="70px" width="70px" />
                    </Box>
                    <Typography
                        sx={{
                            fontSize: { xs: '12px', md: '16px', lg: '20px' },
                            fontWeight: 600,
                            textAlign: 'center',
                        }}
                    >
                        Meet Collaborators
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifySelf: 'center',
                        maxWidth: '140px',
                    }}
                >
                    <Box className="hero-icon-circle">
                        <img className="hero-icon" src={searchStar} alt="Search" height="70px" width="70px" />
                    </Box>
                    <Typography
                        sx={{
                            fontSize: { xs: '12px', md: '16px', lg: '20px' },
                            fontWeight: 600,
                            textAlign: 'center',
                        }}
                    >
                        Find Funding Opportunities
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifySelf: 'center',
                        maxWidth: '140px',
                    }}
                >
                    <Box className="hero-icon-circle">
                        <img className="hero-icon" src={aflogo} alt="Air Force logo" height="70px" width="70px" />
                    </Box>
                    <Typography
                        sx={{
                            fontSize: { xs: '12px', md: '16px', lg: '20px' },
                            fontWeight: 600,
                            textAlign: 'center',
                        }}
                    >
                        Advance USAF Technology
                    </Typography>
                </Box>
            </Container>
            <Box sx={{ display: 'grid', gap: '20px', maxWidth: 'fit-content', mx: 'auto' }}>
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                        height: '50px',
                        mb: '50px',
                        width: '194px',
                    }}
                    onClick={() => redirectToLogin(history)}
                >
                    LOGIN TODAY
                </Button>
            </Box>
        </div>
    );
};

export default Hero;
