import React, { FunctionComponent } from 'react';

import { Typography, TypographyProps } from '@mui/material';

const StrongText: FunctionComponent<TypographyProps> = ({ children, ...typographyProps }) => (
    <Typography component="strong" fontWeight="bold" {...typographyProps}>
        {children}
    </Typography>
);

export default StrongText;
