import { ExplorerFilter } from 'models/ExplorerFilter';
import { Levels } from 'models/Levels';
import { Pagination } from 'models/Pagination';
import { SortInput } from 'typings/generated';
import { AdvancedFilters } from '../reducers/ExplorerSearch';

export const SET_SEARCHTERM = 'SET_SEARCHTERM';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_SEARCHTERMCATEGORY = 'SET_SEARCHTERMCATEGORY';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_SORT = 'SET_SORT';
export const SET_TRLLEVEL = 'SET_TRLLEVEL';
export const SET_MRLLEVEL = 'SET_MRLLEVEL';
export const SET_TECHNOLOGYFILTERS = 'SET_TECHNOLOGYFILTERS';
export const SET_VISIBILITY = 'SET_VISIBILITY';

export const APPLY_ADVANCED_FILTERS = 'APPLY_ADVANCED_FILTERS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';

export const setSearchTerm = (payload: string): any => ({
    type: SET_SEARCHTERM,
    payload,
});

export const setPagination = (payload: Pagination): any => ({
    type: SET_PAGINATION,
    payload,
});

export const setSearchTermAndCategory = (payload: string): any => ({
    type: SET_SEARCHTERMCATEGORY,
    payload,
});

export const setCategory = (payload: string): any => ({
    type: SET_CATEGORY,
    payload,
});

export const setSort = (payload: SortInput): any => ({
    type: SET_SORT,
    payload,
});

export const setTRLLevel = (payload: Levels): any => ({
    type: SET_TRLLEVEL,
    payload,
});

export const setMRLLevel = (payload: Levels): any => ({
    type: SET_MRLLEVEL,
    payload,
});

export const setTechnologyFilters = (payload: Array<ExplorerFilter>): any => ({
    type: SET_TECHNOLOGYFILTERS,
    payload,
});

export const setVisibility = (payload: boolean): any => ({
    type: SET_VISIBILITY,
    payload,
});

export const setAdvancedFilters = (payload: AdvancedFilters): any => ({
    type: APPLY_ADVANCED_FILTERS,
    payload,
});

export const clearFilters = (): any => ({
    type: CLEAR_FILTERS,
});
