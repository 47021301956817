import React, { ReactElement, ReactNode } from 'react';

import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    StandardTextFieldProps,
} from '@mui/material';

import { Control, Controller, DeepMap, FieldError, RegisterOptions } from 'react-hook-form';

export interface ControllerSelectFieldProps extends StandardTextFieldProps {
    label: ReactNode;
    name: string;
    defaultValue: string;
    children?: ReactNode;
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    errors: DeepMap<Record<string, any>, FieldError>;
    control: Control<Record<string, any>>;
    placeholder?: string;
    onValueChange?: (e: SelectChangeEvent<any>) => void;
    valueFormatter?: (value: any) => string;
    testId?: string;
}

export const ControllerSelectField = ({
    label,
    name,
    defaultValue,
    children,
    rules,
    errors,
    control,
    placeholder,
    onValueChange,
    valueFormatter,
    testId,
}: ControllerSelectFieldProps): ReactElement => {
    return (
        <Controller
            defaultValue={defaultValue}
            control={control}
            name={name || ''}
            rules={rules}
            render={({ onChange, value, ...props }) => (
                <Box>
                    <InputLabel
                        htmlFor={name}
                        error={!!errors[name || '']}
                        style={{ width: '100%', whiteSpace: 'unset' }}
                    >
                        {label}
                        <Box sx={{ mt: '0.5rem' }}>
                            <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                                <Select
                                    sx={{ color: value === '' ? 'text.disabled' : 'text.primary' }}
                                    fullWidth
                                    variant="outlined"
                                    value={value}
                                    displayEmpty={!!placeholder}
                                    renderValue={selected => {
                                        const dataValue = !selected && placeholder ? placeholder : selected;
                                        return valueFormatter ? valueFormatter(dataValue) : dataValue;
                                    }}
                                    onChange={e => {
                                        onChange(e);
                                        if (onValueChange) {
                                            onValueChange(e);
                                        }
                                    }}
                                    inputProps={{
                                        'data-testid': testId,
                                        id: name,
                                    }}
                                    {...props}
                                >
                                    {placeholder && !value && (
                                        <MenuItem disabled value="">
                                            <em>{placeholder}</em>
                                        </MenuItem>
                                    )}
                                    {children}
                                </Select>
                            </FormControl>
                        </Box>
                    </InputLabel>
                </Box>
            )}
        />
    );
};

ControllerSelectField.defaultProps = {
    onValueChange: null,
    placeholder: null,
    testId: 'type',
    valueFormatter: undefined,
    children: null,
    rules: null,
};

export default ControllerSelectField;
