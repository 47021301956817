import React, { ReactElement } from 'react';

import { Alert, Box, Snackbar } from '@mui/material';

import { PrimaryOrgBadge } from 'assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateAlert } from 'state/store/actions/Alert';
import { RootStore } from 'state/store/reducers';
import { AlertState } from 'state/store/reducers/Alert';
import { JoinOrganizationStatus } from 'typings/generated';

type RemovableEntity = 'Announcement' | 'Email' | 'Section' | 'Resource' | 'App';

type CommunityAppInput = { appName?: string | null; errorMessage?: string | null };
export interface ToastNotifications {
    joinOrgToast: (status: JoinOrganizationStatus, organizationName: string) => void;
    leaveOrgToast: (leaveSuccessful: boolean, organizationName: string) => void;
    newPrimaryOrgToast: (success: boolean, organizationName: string) => void;
    newPrimaryEmailToast: (success: boolean) => void;
    validateProfileMerge: (success: boolean) => void;
    initiateMergeToast: (success: boolean) => void;
    resendMergeValidationToast: (success: boolean, errorMessage: any) => void;
    confirmMergeProfileToast: (success: boolean) => void;
    resendValidationToast: (success: boolean) => void;
    removeIdentity: (errorMessage: string | undefined | null) => void;
    syncGroups: (success: boolean) => void;
    onboardingUpdatedToast: (success: boolean) => void;
    removeAnnouncement: (errorMessage: string | undefined | null) => void;
    removeCommunity: (success: boolean, communityName: string) => void;
    removeCommunitySection: (errorMessage: string | undefined | null) => void;
    removeCommunityResource: (errorMessage: string | undefined | null) => void;
    communityNotFound: (errorMessage?: string) => void;
    genericError: (errorMessage: string) => void;
    genericSuccess: (message: string) => void;
    genericWarning: (message: string) => void;
    addCommunityApp: ({ appName, errorMessage }: CommunityAppInput) => void;
    updateCommunityApp: ({ appName, errorMessage }: CommunityAppInput) => void;
    removeCommunityApp: (errorMessage?: string | null) => void;
}

export const useToastAlerts: () => ToastNotifications = () => {
    const dispatch = useDispatch();
    const defaultAlert: AlertState = {
        visible: true,
        severity: 'success',
        message: '',
    };
    const joinOrgToast = (status: JoinOrganizationStatus, organizationName: string) => {
        const alert: AlertState = { ...defaultAlert };
        switch (status) {
            case JoinOrganizationStatus.AutoApproved:
                alert.message = (
                    <>
                        You have joined <strong>{organizationName}</strong>
                    </>
                );
                break;
            case JoinOrganizationStatus.RequestSent:
                alert.message = (
                    <>
                        Your request to join <strong>{organizationName}</strong> has been sent
                    </>
                );
                break;
            case JoinOrganizationStatus.UserNotVerified:
                alert.message = (
                    <>
                        Your request to join <strong>{organizationName}</strong> will be sent once you have verified
                        your email address
                    </>
                );
                alert.severity = 'warning';
                break;
            default:
                alert.message = (
                    <>
                        Request to join <strong>{organizationName}</strong> could not be sent, try again
                    </>
                );
                alert.severity = 'error';
                break;
        }
        dispatch(updateAlert(alert));
    };
    const leaveOrgToast = (leaveSuccessful: boolean, organizationName: string) => {
        const alert: AlertState = { ...defaultAlert };
        if (leaveSuccessful) {
            alert.message = (
                <>
                    You have left <strong>{organizationName}</strong>
                </>
            );
            dispatch(updateAlert(alert));
        }
        // else {
        //     alert.message = (
        //         <>
        //             <strong>{organizationName}</strong> could not be left, try again
        //         </>
        //     );
        //     alert.severity = 'error';
        // }
    };
    const newPrimaryOrgToast = (success: boolean, organizationName: string) => {
        const alert: AlertState = { ...defaultAlert };
        if (success) {
            alert.message = (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    Your&nbsp;
                    <PrimaryOrgBadge />
                    &nbsp;<strong>Primary</strong>&nbsp;organization is now&nbsp;
                    <strong>{organizationName}</strong>
                </Box>
            );
        } else {
            alert.message = (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PrimaryOrgBadge />
                    &nbsp;<strong>Primary</strong>&nbsp;could not be set, try again
                </Box>
            );
            alert.severity = 'error';
        }
        dispatch(updateAlert(alert));
    };
    const newPrimaryEmailToast = (success: boolean) => {
        const alert: AlertState = { ...defaultAlert };
        if (success) {
            alert.message = 'Notification email updated';
        } else {
            alert.message = 'Notification email could not be set, try again';
            alert.severity = 'error';
        }
        dispatch(updateAlert(alert));
    };
    const validateProfileMerge = (success: boolean) => {
        const alert: AlertState = { ...defaultAlert };
        if (!success) {
            alert.message = 'Incorrect validation code, try again';
            alert.severity = 'error';
            dispatch(updateAlert(alert));
        }
    };
    const initiateMergeToast = (success: boolean) => {
        const alert: AlertState = { ...defaultAlert };
        if (!success) {
            alert.message = `Email not registered with another ${process.env.REACT_APP_NAME} profile`;
            alert.severity = 'error';
            dispatch(updateAlert(alert));
        }
    };

    const resendValidationToast = (success: boolean) => {
        const alert: AlertState = { ...defaultAlert };
        if (success) {
            alert.message = 'Resending validation email';
        } else {
            alert.message = 'Error, new validation email could not be sent, try again';
            alert.severity = 'error';
        }
        dispatch(updateAlert(alert));
    };

    const resendMergeValidationToast = (success: boolean, errorMessage: any) => {
        const alert: AlertState = { ...defaultAlert };
        if (success) {
            alert.message = 'New validation code email was successfully sent';
        } else {
            alert.message = errorMessage;
        }
    };
    const confirmMergeProfileToast = (success: boolean) => {
        const alert: AlertState = { ...defaultAlert };
        if (success) {
            alert.message = 'Profiles successfully merged';
        } else {
            alert.message = 'Profiles unsuccessfully merged, try again';
            alert.severity = 'error';
        }
        dispatch(updateAlert(alert));
    };

    const removeEntity = (entityName: RemovableEntity, errorMessage: string | undefined | null) => {
        const alert: AlertState = { ...defaultAlert };
        if (!errorMessage) {
            alert.message = `${entityName} removed`;
        } else {
            alert.message = `${entityName} could not be removed: ${errorMessage}`;
            alert.severity = 'error';
        }
        dispatch(updateAlert(alert));
    };
    const removeIdentity = (errorMessage: string | undefined | null) => {
        removeEntity('Email', errorMessage);
    };
    const removeAnnouncement = (errorMessage: string | undefined | null) => {
        removeEntity('Announcement', errorMessage);
    };
    const addCommunityApp = ({ appName, errorMessage }: CommunityAppInput) => {
        const alert: AlertState = { ...defaultAlert };
        alert.message = errorMessage
            ? `Something went wrong adding app: ${errorMessage}`
            : `Successfully added ${appName || 'app'} `;
        alert.severity = errorMessage ? 'error' : 'success';
        dispatch(updateAlert(alert));
    };
    const updateCommunityApp = ({ appName, errorMessage }: CommunityAppInput) => {
        const alert: AlertState = { ...defaultAlert };
        alert.message = errorMessage
            ? `Something went wrong updating app: ${errorMessage}`
            : `Successfully updated ${appName || 'app'} `;
        alert.severity = errorMessage ? 'error' : 'success';
        dispatch(updateAlert(alert));
    };
    const removeCommunityApp = (errorMessage?: string | null) => {
        removeEntity('App', errorMessage);
    };
    const removeCommunity = (success: boolean, communityName: string) => {
        const alert: AlertState = { ...defaultAlert };
        if (success) {
            alert.message = (
                <>
                    <strong>{communityName}</strong> successfully deleted.
                </>
            );
        } else {
            alert.message = (
                <>
                    <strong>{communityName}</strong> delete unsuccessful.
                </>
            );
            alert.severity = 'error';
        }
        dispatch(updateAlert(alert));
    };
    const removeCommunitySection = (errorMessage: string | undefined | null) => {
        removeEntity('Section', errorMessage);
    };

    const removeCommunityResource = (errorMessage: string | undefined | null) => {
        removeEntity('Resource', errorMessage);
    };

    const syncGroups = (success: boolean) => {
        const alert: AlertState = { ...defaultAlert };
        if (success) {
            alert.message = 'Groups successfully updated';
        } else {
            alert.message = 'Groups could not be updated, try again';
            alert.severity = 'error';
        }
        dispatch(updateAlert(alert));
    };

    const onboardingUpdatedToast = (success: boolean) => {
        const alert: AlertState = { ...defaultAlert };
        if (success) {
            alert.message = 'New hire information is updated';
        } else {
            alert.message = 'New hire information could not be updated, try again';
            alert.severity = 'error';
        }
        dispatch(updateAlert(alert));
    };

    const communityNotFound = (errorMessage = '') => {
        const alert: AlertState = { ...defaultAlert };
        alert.message = errorMessage ? `Community not found: ${errorMessage}` : 'Community not found';
        alert.severity = 'warning';
        dispatch(updateAlert(alert));
    };

    const genericError = (errorMessage: string) => {
        const alert: AlertState = { ...defaultAlert };
        alert.message = `Error: ${errorMessage}`;
        alert.severity = 'error';
        dispatch(updateAlert(alert));
    };

    const genericSuccess = (message: string) => {
        const alert: AlertState = { ...defaultAlert };
        alert.message = message;
        alert.severity = 'success';
        dispatch(updateAlert(alert));
    };

    const genericWarning = (message: string) => {
        const alert: AlertState = { ...defaultAlert };
        alert.message = message;
        alert.severity = 'warning';
        dispatch(updateAlert(alert));
    };

    return {
        joinOrgToast,
        leaveOrgToast,
        newPrimaryOrgToast,
        newPrimaryEmailToast,
        resendValidationToast,
        resendMergeValidationToast,
        initiateMergeToast,
        validateProfileMerge,
        confirmMergeProfileToast,
        removeIdentity,
        syncGroups,
        onboardingUpdatedToast,
        removeAnnouncement,
        removeCommunity,
        removeCommunitySection,
        removeCommunityResource,
        communityNotFound,
        genericError,
        genericSuccess,
        genericWarning,
        addCommunityApp,
        updateCommunityApp,
        removeCommunityApp,
    };
};

const ToastAlerts = (): ReactElement => {
    const dispatch = useDispatch();
    const alert = useSelector((storeState: RootStore) => storeState.Alert);

    return (
        <Snackbar
            sx={{ zIndex: 10000 }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={alert?.visible || false}
            autoHideDuration={alert?.severity === 'error' ? null : 3000}
            onClose={(): void => {
                dispatch(updateAlert({ ...alert, visible: false }));
            }}
        >
            <Alert
                variant="filled"
                onClose={(): void => {
                    dispatch(updateAlert({ ...alert, visible: false }));
                }}
                severity={alert?.severity || 'info'}
            >
                {alert?.message}
            </Alert>
        </Snackbar>
    );
};

export default ToastAlerts;
