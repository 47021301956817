import React, { ReactElement, useEffect, useState } from 'react';

import { Box, Button, CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-data-grid-pro';

import Footer from 'components/layouts/footer/Footer';
import BlockHeader from 'components/layouts/header/BlockHeader';
import Header from 'components/layouts/header/Header';
import ToastAlerts from 'components/layouts/header/ToastAlerts';
import TopNav from 'pageComponents/navigation/top-nav/TopNav';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { RootStore } from 'state/store/reducers';
import theme from 'theme';
import { isUserBlocked } from 'utilities/utils';
import Apollo from './Apollo';
import Keycloak from './Keycloak';
import ReactRouter, { appRoutes } from './ReactRouter';
import Redux from './Redux';

const AppProvider = (): ReactElement => {
    const userState = useSelector((storeState: RootStore) => storeState.User);
    const [user, setUser] = useState(userState);
    const [showTopNav, setShowTopNav] = useState(false);
    const [showFooter, setShowFooter] = useState(false);
    const userOnboarded = user && user.onboarded;
    useEffect(() => {
        if (JSON.stringify(user) !== JSON.stringify(userState)) {
            setUser(userState);
        }
    }, [user, userState]);

    const location = useLocation();
    useEffect(() => {
        const routeHidesNav = () =>
            location.pathname === '/onboarding' ||
            location.pathname === '/onboarding/organization' ||
            location.pathname === '/afwerx-onboarding' ||
            location.pathname === appRoutes.onboarding.checklist;
        const hideTopNav = !userOnboarded || isUserBlocked(user) || routeHidesNav();
        setShowFooter(userOnboarded && !isUserBlocked(user));
        setShowTopNav(!hideTopNav);
    }, [location.pathname, user, userOnboarded]);

    const footerHeight = showFooter ? 50 : 0;
    const headerHeight = 60; // header always visible
    const navHeight = showTopNav ? 60 : 0;
    const navAndFooterHeight = footerHeight + navHeight;
    return (
        <>
            <header role="heading" aria-level={1}>
                <Button
                    target="_self"
                    href="#main"
                    sx={{
                        marginRight: '1rem',
                        position: 'absolute',
                        transform: 'translateX(-200%)',
                        transition: 'transform 0.3s',

                        '&:focus': {
                            position: 'static',
                            transform: 'translateX(0)',
                        },
                    }}
                >
                    Skip to Content
                </Button>
                <ToastAlerts />
                <Header />

                {showTopNav && <TopNav />}
                <BlockHeader />
            </header>
            <main role="main" className="main" id="main">
                <Box
                    component="div"
                    className="router-container"
                    sx={{
                        height: {
                            xs: `calc(100vh - ${headerHeight + footerHeight}px)`, // never shows top nav
                            sm: `calc(100vh - ${headerHeight + footerHeight}px)`, // never shows top nav
                            md: `calc(100vh - ${headerHeight + navAndFooterHeight}px)`, // may have footer and top nav pending landing page or not
                            lg: `calc(100vh - ${headerHeight + navAndFooterHeight}px)`, // may have footer and top nav pending landing page or not
                            xl: `calc(100vh - ${headerHeight + navAndFooterHeight}px)`, // may have footer and top nav pending landing page or not
                        },
                        overflowY: 'scroll',
                        overflowX: 'hidden', // ie. searching will offset causing scrolling, we do not want horizontal scroll
                    }}
                >
                    <ReactRouter user={user} />
                </Box>
                {showFooter && <Footer />}
            </main>
        </>
    );
};
const Providers = (): ReactElement => {
    LicenseInfo.setLicenseKey(process.env.REACT_APP_XGRID_LICENSE || '');
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Keycloak>
                    <Apollo>
                        <Router>
                            <Redux>
                                <CssBaseline>
                                    <AppProvider />
                                </CssBaseline>
                            </Redux>
                        </Router>
                    </Apollo>
                </Keycloak>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default Providers;
