import React, { ReactElement } from 'react';

import NavIcon from './NavIcon';

interface AboutNavIconProps {
    overlayOn: boolean;
    opened: () => void;
}

export const AboutNavIcon = ({ opened, overlayOn }: AboutNavIconProps): ReactElement => {
    const onOpened = () => {
        opened();
    };
    return (
        <NavIcon
            iconTitle="About"
            icon="about"
            onOpen={onOpened}
            overlayOn={overlayOn}
            disableOverlay // comment this out to re-enable notifications
        />
    );
};

export default AboutNavIcon;
