import React, { ReactElement } from 'react';

import { Avatar, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { BlockIcon } from 'assets/icons';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        large: {
            width: theme.spacing(10),
            height: theme.spacing(10),
        },
        overlayGray: {
            position: 'absolute',
            opacity: '50%',
            width: theme.spacing(10),
            height: theme.spacing(10),
        },
        overlayIcon: {
            position: 'absolute',
            width: theme.spacing(7.5),
            height: theme.spacing(7.5),
        },
    })
);

export default function BlockedAvatar({ children }: { children: ReactElement }): ReactElement {
    const classes = useStyles();
    return (
        <Avatar className={classes.large}>
            {children}
            <>
                <Avatar aria-hidden="true" alt="" src="unknown.jpg" className={classes.overlayGray}>
                    <div />
                </Avatar>
                <BlockIcon className={classes.overlayIcon} />
            </>
        </Avatar>
    );
}
