/* eslint-disable react/no-danger */
import React, { ReactElement } from 'react';

import { Avatar, Box } from '@mui/material';

import { LabelPairValue } from 'models/LabelValuePair';
import { getOrganizationInitial, rawMarkup, regExpEscape } from 'utilities/utils';

import './Autocomplete.scss';

type OrgType = 'company' | 'organization';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    item: LabelPairValue;
    inputValue: string;
    orgType?: OrgType;
}

const highlight = (result: string, searchTextValue: string): Array<string> => {
    const terms: Array<string> = Array.isArray(searchTextValue) ? searchTextValue : [searchTextValue];
    const escapedTerms = terms.map(term => regExpEscape(term)).filter(term => term);
    return escapedTerms.length ? result.split(new RegExp(`(${escapedTerms.join('|')})`, 'gmi')) : [result];
};

export const highlightItem = (searchTextValue: string, option: LabelPairValue): LabelPairValue => {
    if (
        searchTextValue &&
        searchTextValue.length > 0 &&
        option.name &&
        option.name.toLowerCase().includes(searchTextValue.toLowerCase())
    ) {
        const updatedOption = { ...option };
        updatedOption.highlightedLabel = '';
        const result = highlight(option.name, searchTextValue);
        let newLabel = '';
        result.forEach((item, index) => {
            if (index % 2 === 0) {
                newLabel += item;
            } else {
                newLabel += `<b>${item}</b>`;
            }
        });
        updatedOption.highlightedLabel = newLabel;
        return updatedOption;
    }
    return option;
};

export const OrgAutoCompleteResult = ({ item, inputValue, orgType }: Props): ReactElement => {
    return (
        <Box sx={{ display: 'flex' }} className="org-auto-complete-result content-center">
            <div className="avatar-container">
                <Box sx={{ m: 1 }}>
                    <Avatar aria-hidden="true" alt="" src={item.image || item.avatar} sx={{ display: 'flex' }}>
                        {/* fallback could be a letter too... */}
                        {/* <PersonIcon className={classes.medium} /> */}
                        {item.name && item.name.length > 0 ? getOrganizationInitial(item) : ''}
                    </Avatar>
                </Box>
            </div>
            <div className="autocomplete-content">
                {orgType === 'company' ? (
                    <Box sx={{ m: 1 }}>
                        <div
                            className="autocomplete-content_content"
                            dangerouslySetInnerHTML={rawMarkup(
                                highlightItem(inputValue?.toString(), item).highlightedLabel || ''
                            )}
                        />
                        {item.metadata && (
                            <div className="autocomplete-content_address">
                                <div>
                                    {item.metadata.city}, {item.metadata.state}
                                </div>
                            </div>
                        )}
                        {item.status && (
                            <Box>
                                Permissions:{' '}
                                <Box component="span" sx={{ fontWeight: '600' }}>
                                    {item.status}
                                </Box>
                            </Box>
                        )}
                    </Box>
                ) : (
                    <Box sx={{ m: 1 }}>
                        <div className="autocomplete-content_organization">{inputValue}</div>
                    </Box>
                )}
            </div>
        </Box>
    );
};

OrgAutoCompleteResult.defaultProps = {
    orgType: 'company',
};

export default OrgAutoCompleteResult;
