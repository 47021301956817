import React, { ReactElement } from 'react';

import { Provider } from 'react-redux';
import store from 'state/store';

interface Props {
    children: ReactElement;
}

export const Redux = ({ children }: Props): ReactElement => {
    return <Provider store={store}>{children}</Provider>;
};

export default Redux;
