import React, { ReactElement } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Button, Divider, Typography } from '@mui/material';

import { JoinOrganizationIcon, OrganizationIcon } from 'assets/icons';
import ButtonGroup from 'components/common/button-group/ButtonGroup';
import RouteLeavingGuard from 'components/common/guard/RouteLeavingGuard';
import { PageTitleLabel } from 'components/common/icons/LabeledIcons';
import { InternalButtonLink } from 'components/common/links/Links';
import Panel from 'components/common/panel/Panel';
import { useToastAlerts } from 'components/layouts/header/ToastAlerts';
import CompanyAutocomplete from 'pageComponents/onboarding/CompanyAutocomplete';
import OrganizationCredentialsPanel, { EmailAddReason } from 'pageComponents/organization/OrganizationCredentialsPanel';
import Page from 'pages/Page';
import { setUserState } from 'state/store/actions/User';
import { UserState } from 'state/store/reducers/User';
import { useJoinOrganizationMutation } from 'typings/generated';

const JoinOrganization = (): ReactElement => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { joinOrgToast } = useToastAlerts();

    const methods = useForm({
        // use onChange here to catch the email autocomplete onEnter
        mode: 'onChange',
    });

    const { handleSubmit, formState, errors, control, reset } = methods;
    const { isValid, isDirty } = formState;

    const [joinOrg, { loading: joiningOrg }] = useJoinOrganizationMutation({
        onCompleted: result => {
            // returns the updated user...
            dispatch(setUserState(result.response.updatedUser as UserState));
        },
    });

    const onSubmit = (values: any) => {
        joinOrg({
            variables: {
                data: {
                    organizationId: values.organization.value,
                    roleName: values.role,
                    emailAddress: values.orgEmail.email,
                    relation: values.relation,
                },
            },
        }).then(response => {
            if (response && response.data) {
                joinOrgToast(response.data?.response.responseStatus, response.data?.response.organization.name);
            }
            reset();
            history.push('/');
        });
    };

    return (
        <>
            <RouteLeavingGuard
                title="Are you sure you want to leave?"
                content="If you leave now, this request will not be saved or sent."
                when
                navigate={(path): void => {
                    history.push(path);
                }}
                shouldBlockNavigation={(): boolean => {
                    return isDirty;
                }}
            />
            <LoadingOverlay active={joiningOrg} spinner text="Loading...">
                <Page title={<PageTitleLabel icon={<JoinOrganizationIcon />} label="Join Organization" />}>
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <Panel title={<Typography variant="h2">*Select Organization</Typography>}>
                            <Typography sx={{ mt: '0.25rem' }}>
                                Enhance your presence on AFWERX Portal by requesting to join organizations relevant to
                                you.
                            </Typography>
                            <Box sx={{ my: '1rem' }}>
                                <CompanyAutocomplete control={control} errors={errors} required />
                            </Box>
                            <Divider sx={{ mb: '1rem', mt: '1.75rem' }} />
                            <Typography sx={{ my: '0.25rem' }}>
                                Is your organization not listed as part of AFWERX Portal? Add it!
                            </Typography>
                            <InternalButtonLink
                                sx={{ mb: '0.25rem' }}
                                startIcon={<OrganizationIcon />}
                                variant="outlined"
                                to="/addorganization"
                            >
                                Create Organization
                            </InternalButtonLink>
                        </Panel>
                        <OrganizationCredentialsPanel
                            control={control}
                            errors={errors}
                            emailAddReason={EmailAddReason.JoiningOrganization}
                        />
                        <Box sx={{ mt: '1rem' }}>
                            <ButtonGroup justify="flex-start">
                                <Button
                                    data-testid="cancel"
                                    className="button-continue"
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    disabled={joiningOrg}
                                    onClick={() => {
                                        if (location.pathname.includes('/onboarding')) {
                                            history.push('/');
                                        } else {
                                            history.goBack();
                                        }
                                    }}
                                >
                                    {location.pathname.includes('/onboarding') ? 'Skip' : 'Cancel'}
                                </Button>
                                <Button
                                    className="button-continue"
                                    variant="contained"
                                    color="primary"
                                    data-testid="submit"
                                    type="submit"
                                    disabled={!isValid || joiningOrg}
                                >
                                    Send Request
                                </Button>
                            </ButtonGroup>
                        </Box>
                    </form>
                </Page>
            </LoadingOverlay>
        </>
    );
};

export default JoinOrganization;
