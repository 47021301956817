import React, { ReactElement } from 'react';

import { useSelector } from 'react-redux';
import { RootStore } from 'state/store/reducers';
import NavIcon from './NavIcon';

interface AdminNavIconProps {
    title: string;
    overlayOn: boolean;
    opened: () => void;
}

export const AdminNavIcon = ({ title, opened, overlayOn }: AdminNavIconProps): ReactElement => {
    const count = useSelector((storeState: RootStore) => storeState.Count);
    const onOpened = () => {
        opened();
    };
    return (
        <NavIcon
            iconTitle={title}
            icon="admin"
            onOpen={onOpened}
            overlayOn={overlayOn}
            badgeCount={count.unresolvedReportCount}
            disableOverlay // comment this out to re-enable notifications
        />
    );
};

export default AdminNavIcon;
