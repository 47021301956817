import {
    ExternalLinkFragment,
    NaicsCodeFragment,
    OrganizationCompetencyFragment,
    OrganizationDetailsFragment,
    OrganizationPrincipalFragment,
    OrganizationSbirSttrAwardFragment,
} from 'typings/generated';
import { AboutOrganization } from '../reducers/Organization';

export const SET_ABOUT = 'SET_ABOUT';
export const REMOVE_LINK = 'REMOVE_LINK';
export const ADD_LINK = 'ADD_LINK';
export const REMOVE_COMPETENCY = 'REMOVE_COMPETENCY';
export const ADD_COMPETENCY = 'ADD_COMPETENCY';
export const REMOVE_NAICS_CODE = 'REMOVE_NAICS_CODE';
export const ADD_NAICS_CODE = 'ADD_NAICS_CODE';
export const REMOVE_SBIR_STTR_AWARD = 'REMOVE_SBIR_STTR_AWARD';
export const ADD_SBIR_STTR_AWARD = 'ADD_SBIR_STTR_AWARD';
export const REMOVE_PRINCIPAL = 'REMOVE_PRINCIPAL';
export const ADD_PRINCIPAL = 'ADD_PRINCIPAL';
export const RESET_ORGANIZATION = 'RESET_ORGANIZATION';
export const INITIALIZE_ORGANIZATION = 'INITIALIZE_ORGANIZATION';

export const resetOrganization = (): any => ({
    type: RESET_ORGANIZATION,
});

export const setAbout = (payload: AboutOrganization): any => ({
    type: SET_ABOUT,
    payload,
});

export const addCompanyExternalLink = (payload: ExternalLinkFragment): any => ({
    type: ADD_LINK,
    payload,
});

export const removeCompanyExternalLink = (payload: ExternalLinkFragment): any => ({
    type: REMOVE_LINK,
    payload,
});

export const addCompanyCompetency = (payload: OrganizationCompetencyFragment): any => ({
    type: ADD_COMPETENCY,
    payload,
});

export const removeCompanyCompetency = (payload: OrganizationCompetencyFragment): any => ({
    type: REMOVE_COMPETENCY,
    payload,
});

export const addCompanyNaicsCode = (payload: NaicsCodeFragment): any => ({
    type: ADD_NAICS_CODE,
    payload,
});

export const removeCompanyNaicsCode = (payload: NaicsCodeFragment): any => ({
    type: REMOVE_NAICS_CODE,
    payload,
});

export const addCompanySbirSttrAward = (payload: OrganizationSbirSttrAwardFragment): any => ({
    type: ADD_SBIR_STTR_AWARD,
    payload,
});

export const removeCompanySbirSttrAward = (payload: OrganizationSbirSttrAwardFragment): any => ({
    type: REMOVE_SBIR_STTR_AWARD,
    payload,
});

export const addCompanyPrincipal = (payload: OrganizationPrincipalFragment): any => ({
    type: ADD_PRINCIPAL,
    payload,
});

export const removeCompanyPrincipal = (payload: OrganizationPrincipalFragment): any => ({
    type: REMOVE_PRINCIPAL,
    payload,
});

export const initializeOrganization = (payload: OrganizationDetailsFragment): any => ({
    type: INITIALIZE_ORGANIZATION,
    payload,
});
