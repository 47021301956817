import { AnyAction } from 'redux';
import { SET_STATE_BY_KEY } from '../actions/SearchStates';

export interface ReportSearchState {
    status: string;
    typeFilters: string[];
}

export interface SearchStateDictionary {
    items: SearchStateDictionaryItem[];
}

export interface SearchStateDictionaryItem {
    key: string;
    state: ReportSearchState;
}

const initialState: SearchStateDictionary = {
    items: [
        {
            key: 'adminReports',
            state: {
                status: '',
                typeFilters: ['DiscussionReport', 'PostReport', 'TechnologyReport', 'UserReport', 'OrganizationReport'],
            },
        },
        {
            key: 'adminBlocks',
            state: {
                status: '',
                typeFilters: ['DiscussionBlock', 'PostBlock', 'TechnologyBlock', 'UserBlock', 'OrganizationBlock'],
            },
        },
    ],
};

export function getInitialState(): SearchStateDictionary {
    return initialState;
}

export default (state = initialState, action: AnyAction): SearchStateDictionary => {
    const { type } = action;
    switch (type) {
        case SET_STATE_BY_KEY: {
            const { items } = state;
            const dictionaryItem = action.payload as SearchStateDictionaryItem;
            const newState = { items: [...items.filter(item => item.key !== dictionaryItem.key), dictionaryItem] };
            return { ...newState };
        }
        default:
            return state;
    }
};
