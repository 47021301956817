import React, { ReactElement, ReactNode } from 'react';

import './ContentArea.scss';

type ContentFlexDirection = 'row' | 'column';

interface ContentAreaProps {
    children: ReactNode;
    title?: ReactNode;
    includeBottomBoxShadow?: boolean;
    includePadding?: boolean;
    includeTopBorder?: boolean;
    contentFlexDirection?: ContentFlexDirection;
    noWrap?: boolean;
    display?: boolean;
}

export const ContentArea = ({
    includeBottomBoxShadow,
    includePadding,
    includeTopBorder,
    contentFlexDirection,
    noWrap,
    display,
    title,
    children,
}: ContentAreaProps): ReactElement => {
    let contentArea = 'content-area';
    if (includeBottomBoxShadow) {
        contentArea += ' with-bottom-shadow';
    }
    if (includeTopBorder) {
        contentArea += ' with-top-border';
    }
    if (includePadding) {
        contentArea += ' include-padding';
    }
    if (noWrap) {
        contentArea += ' no-wrap';
    }
    let contentAreaContent = 'content-area_content';
    if (contentFlexDirection === 'column') {
        contentAreaContent += ' content-area_content_column';
    } else {
        contentAreaContent += ' content-area_content_row';
    }

    return (
        <>
            {display ? (
                <div className={contentArea}>
                    <div className="content-area_title">{title}</div>
                    <div className={contentAreaContent}>{children}</div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

ContentArea.defaultProps = {
    title: '',
    includeBottomBoxShadow: true,
    includePadding: true,
    includeTopBorder: false,
    noWrap: false,
    contentFlexDirection: 'row',
    display: true,
};

export default ContentArea;
