import React, { ReactElement, ReactNode } from 'react';

import { Box, InputLabel, StandardTextFieldProps, SxProps, TextField } from '@mui/material';

import { Control, Controller, DeepMap, FieldError, RegisterOptions } from 'react-hook-form';

export interface ControllerTextFieldProps extends StandardTextFieldProps {
    label: ReactNode;
    // testId?: string;
    showCharacterCount?: boolean;
    defaultValue: string;
    maxLength?: number;
    rows?: number;
    children?: ReactNode;
    errormessage?: string;
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    errors: DeepMap<Record<string, any>, FieldError>;
    control: Control<Record<string, any>>;
    onValueChange?: (e: any) => void;
    sx?: SxProps;
}

export const ControllerTextField = ({
    label,
    showCharacterCount,
    maxLength,
    rows,
    children,
    errormessage,
    rules,
    errors,
    control,
    onValueChange,
    placeholder,
    autoFocus,
    sx,
    ...props
}: ControllerTextFieldProps): ReactElement => {
    const { name, multiline, required, inputProps, InputLabelProps, defaultValue, disabled } = props;
    const customInputProps = inputProps || {};

    if (!customInputProps['data-testid']) {
        customInputProps['data-testid'] = name;
    }

    if (maxLength && maxLength > 0) {
        customInputProps.maxLength = maxLength;
    }

    const customInputLabelProps = InputLabelProps || {};
    if (required === true) {
        customInputLabelProps.className = 'required-label';
        customInputLabelProps.required = required;
    }
    const getHelperText = (currentValue: string): ReactNode => {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                {errors[name || ''] && errormessage && <div>{errormessage}</div>}
                {showCharacterCount && (
                    <Box sx={{ order: '2', ml: 'auto', float: 'right', fontWeight: 'lighter' }}>
                        {currentValue.length}/{maxLength}
                    </Box>
                )}
            </Box>
        );
    };

    return (
        <Controller
            control={control}
            name={name || ''}
            rules={rules}
            defaultValue={defaultValue}
            render={({ onChange, onBlur, value, ref }) => (
                <Box sx={{ width: '100%', whiteSpace: 'unset', ...sx }}>
                    <InputLabel
                        htmlFor={name}
                        error={!!errors[name || '']}
                        style={{ width: '100%', whiteSpace: 'unset' }}
                        id={`${name}-label`}
                    >
                        {label} {children}
                        <Box sx={{ mt: '0.5rem' }}>
                            <TextField
                                id={name}
                                name={name}
                                multiline={multiline}
                                onChange={event => {
                                    const inputValue = event.target.value;
                                    onChange(inputValue);
                                    if (onValueChange) {
                                        onValueChange(inputValue);
                                    }
                                }}
                                placeholder={placeholder}
                                autoFocus={autoFocus}
                                disabled={disabled}
                                onBlur={onBlur}
                                rows={rows}
                                value={value || ''}
                                variant="outlined"
                                size={multiline ? 'medium' : 'small'}
                                error={!!errors[name || '']}
                                required={required}
                                style={{ width: '100%' }}
                                fullWidth
                                inputProps={{
                                    ref,
                                    'aria-describedby': `${name}-label`,
                                    ...customInputProps,
                                }}
                                InputLabelProps={customInputLabelProps}
                                FormHelperTextProps={{
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    component: 'div',
                                }}
                                helperText={getHelperText(value || '')}
                            />
                        </Box>
                    </InputLabel>
                </Box>
            )}
        />
    );
};

ControllerTextField.defaultProps = {
    rows: undefined,
    errormessage: '',
    showCharacterCount: false,
    maxLength: 0,
    onValueChange: null,
    sx: null,
    children: null,
    rules: null,
};

export default ControllerTextField;
