import React, { ElementType } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { Link as MuiLink, LinkProps, Button, ButtonProps } from '@mui/material';
import { PopoutIcon, DownloadIcon } from 'assets/icons';
import { getDownloadHrefFromBase64 } from 'utilities/utils';

const defaultButtonStyles = { ':hover': { background: 'transparent' }, padding: '0px' };
const withIconStyles = { alignItems: 'flex-start', '& span': { marginTop: '2px' } };

export const InternalLink: ElementType = (props: LinkProps & RouterLinkProps) => (
    <MuiLink component={RouterLink} target="_self" {...props} />
);

export const InternalButtonLink: ElementType = (props: ButtonProps & RouterLinkProps) => (
    <Button component={RouterLink} target="_self" {...props} />
);

// External links should have an icon and open in new tab
export const ExternalLink: ElementType = ({ href, ...props }: ButtonProps & LinkProps) => {
    const { sx } = props;
    return (
        <Button
            disableRipple
            component={MuiLink}
            href={href}
            // href={href?.startsWith('http') ? href : `https://${href}`}
            target="_blank"
            rel="noopener noreferrer"
            endIcon={<PopoutIcon />}
            {...props}
            sx={{ ...defaultButtonStyles, ...withIconStyles, ...sx }}
        />
    );
};

// Exploratory concept, should localize the image if we want it longterm
export const CalendarLink: ElementType = ({ href, ...props }: ButtonProps & LinkProps) => {
    const { sx } = props;
    return (
        <Button
            disableRipple
            component={MuiLink}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            endIcon={
                <img
                    aria-hidden="true"
                    alt=""
                    style={{ marginLeft: '0.25rem', height: '20px', width: '20px' }}
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Google_Calendar_icon_%282020%29.svg/2048px-Google_Calendar_icon_%282020%29.svg.png"
                />
            }
            {...props}
            sx={{ ...defaultButtonStyles, ...withIconStyles, ...sx }}
        />
    );
};

export const RelativeFileLink: ElementType = ({ href, ...props }: ButtonProps & LinkProps) => {
    const { sx } = props;
    return (
        <Button
            disableRipple
            component={MuiLink}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            endIcon={<PopoutIcon />}
            {...props}
            sx={{ ...defaultButtonStyles, ...withIconStyles, ...sx }}
        />
    );
};

export const DownloadFileLink: ElementType = ({ href, ...props }: ButtonProps & LinkProps) => {
    const { sx } = props;
    return (
        <Button
            disableRipple
            component={MuiLink}
            href={href}
            download
            endIcon={<DownloadIcon />}
            {...props}
            sx={{ ...defaultButtonStyles, ...withIconStyles, ...sx }}
        />
    );
};

export const DeferredDownloadFileLink: ElementType = ({
    fileName,
    fetchFileBase64Data,
    ...props
}: { fetchFileBase64Data: () => string; fileName: string } & ButtonProps & LinkProps) => {
    const { sx } = props;
    const onDownloadData = async () => {
        const base64Data = await fetchFileBase64Data();
        const hrefData = getDownloadHrefFromBase64(base64Data);
        const link = document.createElement('a');
        link.href = hrefData;
        link.setAttribute('download', fileName);
        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        if (link && link.parentNode) {
            link.parentNode.removeChild(link);
        }
    };
    return (
        <Button
            disableRipple
            component={MuiLink}
            onClick={onDownloadData}
            download
            endIcon={<DownloadIcon />}
            {...props}
            sx={{ ...defaultButtonStyles, ...withIconStyles, ...sx }}
        />
    );
};
export const IconLink: ElementType = ({ href, ...props }: ButtonProps & LinkProps) => {
    const defaultStyle = { minWidth: '16px' };
    const { sx } = props;
    return (
        <Button
            disableRipple
            component={MuiLink}
            href={href?.startsWith('https://') ? href : `https://${href}`}
            target="_blank"
            rel="noopener noreferrer"
            {...props}
            sx={{ ...defaultButtonStyles, ...defaultStyle, ...sx }}
        />
    );
};
