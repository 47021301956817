import React, { ReactElement } from 'react';

import DiscussionEditor from 'components/common/discussion-editor/DiscussionEditor';
import { linkTo } from 'components/providers/ReactRouter';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { updateAlert } from 'state/store/actions/Alert';
import { setRaw } from 'utilities/utils';
import {
    Category,
    DiscussionInput,
    useCreateCommunityDiscussionMutation,
    useCreateDiscussionMutation,
    useGetTechnologyCategoriesQuery,
} from '../../typings/generated';

import './AddDiscussion.scss';

export const AddDiscussion = (): ReactElement => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { slug } = useParams<{ slug: string }>();

    const { data: categories, loading: categoriesLoading } = useGetTechnologyCategoriesQuery({
        fetchPolicy: 'cache-and-network',
    });

    const [createPublicDiscussion, { loading: createPublicDiscussionLoading }] = useCreateDiscussionMutation({
        onCompleted: ({ createDiscussion }) => {
            history.push(linkTo.forum.view.discussion.root(createDiscussion.id));
        },
        onError: error => {
            dispatch(
                updateAlert({
                    visible: true,
                    message: error.message,
                    severity: 'error',
                })
            );
        },
    });

    const [
        createPrivateCommunityDiscussion,
        { loading: createPrivateCommunityDiscussionLoading },
    ] = useCreateCommunityDiscussionMutation({
        onCompleted: ({ createCommunityDiscussion }) => {
            history.push(linkTo.forum.view.discussion.root(createCommunityDiscussion.id));
        },
        onError: error => {
            dispatch(
                updateAlert({
                    visible: true,
                    message: error.message,
                    severity: 'error',
                })
            );
        },
    });

    const publish = async (discussion: DiscussionInput): Promise<void> => {
        if (discussion?.isPrivate && !discussion?.communityId) {
            dispatch(
                updateAlert({
                    visible: true,
                    message: 'Error saving community, missing community ID',
                    severity: 'error',
                })
            );

            return;
        }

        if (discussion?.communityId) {
            createPrivateCommunityDiscussion({
                variables: {
                    data: discussion,
                    communityId: discussion.communityId,
                },
            });
        } else {
            createPublicDiscussion({
                variables: {
                    data: {
                        ...discussion,
                    },
                },
            });
        }
    };

    return (
        <DiscussionEditor
            categories={(categories && categories.technologyCategories.map(x => x as Category)) || []}
            publish={publish}
            communityId={slug}
            discussion={{
                discussionId: null,
                title: '',
                content: setRaw(''),
                categories: [],
                isPrivate: undefined,
                communityId: slug,
            }}
            loading={createPublicDiscussionLoading || createPrivateCommunityDiscussionLoading || categoriesLoading}
        />
    );
};
export default AddDiscussion;
