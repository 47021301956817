import React, { ReactElement, useState } from 'react';

import { Alert, Snackbar } from '@mui/material';

import { useSelector } from 'react-redux';
import { RootStore } from 'state/store/reducers';
import NavIcon from './NavIcon';

interface MessageNavIconProps {
    overlayOn: boolean;
    opened: () => void;
}

export const MessageNavIcon = ({ opened, overlayOn }: MessageNavIconProps): ReactElement => {
    const count = useSelector((storeState: RootStore) => storeState.Count);
    const [showAlert, setShowAlert] = useState(false);

    const handleAlertClose = () => {
        setShowAlert(false);
    };

    const onOpened = () => {
        opened();
    };
    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={showAlert}
                autoHideDuration={3000}
                onClose={handleAlertClose}
            >
                <Alert variant="filled" onClose={handleAlertClose} severity="success">
                    You have received a new message
                </Alert>
            </Snackbar>
            <NavIcon
                iconTitle="Messages"
                icon="message"
                onOpen={onOpened}
                overlayOn={overlayOn}
                badgeCount={count.unreadMessageCount}
                disableOverlay // comment this out to re-enable notifications
            />
        </>
    );
};

export default MessageNavIcon;
