import React, { ReactElement, useEffect } from 'react';

import {
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';

import ControllerSelectField from 'components/common/form-controls/ControllerSelectField';
import ControllerTextField from 'components/common/form-controls/ControllerTextField';
import RichTextField from 'components/common/form-controls/RichTextField';
import { Panel } from 'components/common/panel/Panel';
import StrongText from 'components/common/text/StrongText';
import { Controller, useFormContext } from 'react-hook-form';
import { DiscussionInput, Maybe, useGetMyCommunitiesQuery } from 'typings/generated';

export interface DiscussionDetailsEditorProps {
    discussion: DiscussionInput;
    isEditMode?: boolean;
    markFormAsDirty?: () => void;
    isDirty?: boolean;
}

export function DiscussionDetailsEditor({
    discussion,
    isEditMode = false,
    isDirty = false,
    markFormAsDirty,
}: DiscussionDetailsEditorProps): ReactElement {
    const { errors, control, setValue, watch } = useFormContext<DiscussionInput>();
    const isPrivateValue = watch('isPrivate');

    const { data: myCommunities, loading } = useGetMyCommunitiesQuery({
        fetchPolicy: 'cache-and-network',
    });

    const hasCommunities = myCommunities?.getMyCommunities && myCommunities?.getMyCommunities?.totalCount > 0;
    const allCommunities = myCommunities?.getMyCommunities?.items || [];
    const associatedCommunityName = allCommunities.find(community => community.id === discussion?.communityId)?.name;

    const markDirty = () => {
        if (markFormAsDirty) {
            markFormAsDirty();
        }
    };

    useEffect(() => {
        let defaultPrivateValue;

        // Set the initial state of the Radio buttons
        // Ignore if the user has changed anything (isDirty)
        if (!loading && !isDirty) {
            if (discussion?.discussionId) {
                // Editing a discussion
                defaultPrivateValue = discussion?.isPrivate;
            } else {
                // Adding a discussion
                // Let the user choose the discussion privacy if this is a new discussion
                defaultPrivateValue = discussion?.communityId || discussion?.isPrivate ? true : undefined;

                if (!hasCommunities) {
                    // Default to public discussions if the user has no communities
                    defaultPrivateValue = false;
                }
            }

            setValue('isPrivate', defaultPrivateValue);
        }
    }, [discussion, hasCommunities, isDirty, loading, setValue]);

    return (
        <Panel title="*Discussion Details">
            <Grid container spacing={1} className="add-technology-begin">
                <Grid container item spacing={1} xs={12}>
                    {isEditMode && associatedCommunityName ? (
                        <Grid item xs={12}>
                            <Typography>{`This is a private discussion for the ${associatedCommunityName} community.`}</Typography>
                        </Grid>
                    ) : null}
                    {!isEditMode ? (
                        <>
                            <Grid item xs={12}>
                                <Controller
                                    name="isPrivate"
                                    errors={errors}
                                    render={({ onChange, value }) => (
                                        <FormControl>
                                            <fieldset style={{ border: '0', padding: '0' }}>
                                                <FormLabel id="role-radio-buttons-group-label">
                                                    <legend>
                                                        <StrongText color="black">
                                                            *This discussion is accessible to...
                                                        </StrongText>
                                                    </legend>
                                                </FormLabel>
                                                <RadioGroup aria-label="" value={value}>
                                                    <FormControlLabel
                                                        label="Everyone on AFWERX Portal"
                                                        value={false}
                                                        disabled
                                                        control={
                                                            <Radio
                                                                required
                                                                checked={value === false}
                                                                onChange={() => {
                                                                    onChange(false);
                                                                    markDirty();
                                                                }}
                                                            />
                                                        }
                                                    />
                                                    <FormControlLabel
                                                        label="Only a community"
                                                        disabled={!hasCommunities}
                                                        // eslint-disable-next-line react/jsx-boolean-value
                                                        value={true}
                                                        control={
                                                            <Radio
                                                                required
                                                                checked={value === true}
                                                                onChange={() => {
                                                                    onChange(true);
                                                                    markDirty();
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </RadioGroup>
                                            </fieldset>
                                        </FormControl>
                                    )}
                                    control={control}
                                />
                            </Grid>
                        </>
                    ) : null}
                </Grid>
                {!isEditMode && isPrivateValue ? (
                    <Grid item xs={12}>
                        <ControllerSelectField
                            control={control}
                            errors={errors}
                            data-testid="select-community-menu"
                            valueFormatter={(value: Maybe<string>) =>
                                allCommunities.find(community => community.id === value)?.name || ''
                            }
                            label="*Select Community"
                            defaultValue={discussion.communityId || ''}
                            name="communityId"
                            placeholder="-Select community-"
                            rules={{
                                required: 'Community required',
                            }}
                            fullWidth
                        >
                            {allCommunities?.map(({ id, name }) => (
                                <MenuItem data-testid="select-community-menu-item" value={id} key={id}>
                                    {name}
                                </MenuItem>
                            ))}
                        </ControllerSelectField>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <StrongText>*Do not include any CUI or proprietary information</StrongText>
                    <ControllerTextField
                        control={control}
                        errors={errors}
                        name="title"
                        label="*Discussion Title"
                        onValueChange={() => {
                            if (markFormAsDirty) {
                                markFormAsDirty();
                            }
                        }}
                        defaultValue={discussion?.title || ''}
                        errormessage="Discussion Title Required"
                        rules={{ required: 'Discussion Title Required' }}
                        maxLength={200}
                        showCharacterCount
                    />
                    <Box sx={{ mt: '0.5rem' }}>
                        <RichTextField
                            control={control}
                            placeholder="Add discussion content..."
                            errors={errors}
                            name="content"
                            label="*Discussion Content"
                            markFormAsDirty={markFormAsDirty}
                            onValueChange={() => {
                                if (markFormAsDirty) {
                                    markFormAsDirty();
                                }
                            }}
                            defaultValue={discussion?.content || ''}
                            errormessage="Discussion Content Required"
                            rules={{ required: 'Discussion Content Required' }}
                            maxLength={2500}
                            showCharacterCount
                        />
                    </Box>
                </Grid>
            </Grid>
        </Panel>
    );
}

DiscussionDetailsEditor.defaultProps = {
    markFormAsDirty: null,
    isEditMode: false,
    isDirty: false,
};

export default DiscussionDetailsEditor;
