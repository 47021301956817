/* eslint-disable no-return-assign */
import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';

import { Box, createTheme, InputLabel, StandardTextFieldProps, ThemeProvider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { shape } from '@mui/system';

import { convertToRaw, EditorState } from 'draft-js';
import MUIRichTextEditor, { TMUIRichTextEditorRef } from 'mui-rte';
import { Control, Controller, DeepMap, FieldError, RegisterOptions, useFormContext } from 'react-hook-form';

import './RichTextField.scss';

export interface RichTextFieldProps extends StandardTextFieldProps {
    label: ReactNode;
    showCharacterCount?: boolean;
    maxLength?: number;
    children?: ReactNode;
    errormessage?: string;
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    errors: DeepMap<Record<string, any>, FieldError>;
    control: Control<Record<string, any>>;
    setPostCharacterCount?: any;
    markFormAsDirty?: () => void;
    defaultValue: string;
    onValueChange?: (e: any) => void;
    revert?: () => void;
    focusInput?: boolean;
}

const useStyles = makeStyles({
    editor: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: shape.borderRadius,
        cursor: 'text',
        minHeight: '200px',

        '&:hover': {
            borderColor: 'rgba(0, 0, 0, 0.87)',
        },

        '&:focus-within': {
            borderColor: 'var(--primary)',
            borderWidth: '2px',
        },
    },
});

export const RichTextField = ({
    label,
    showCharacterCount,
    maxLength,
    children,
    errormessage,
    rules,
    errors,
    control,
    markFormAsDirty,
    placeholder,
    defaultValue,
    onValueChange,
    focusInput,
    ...props
}: RichTextFieldProps): ReactElement => {
    const { name, inputProps } = props;
    const defaultTheme = createTheme();
    const [plainText, setPlainText] = useState<string>(defaultValue);
    const [shouldRevert, setShouldRevert] = useState(false);
    const [updatedVal, setUpdatedVal] = useState<string>(defaultValue);
    const { getValues, setValue, clearErrors, setError } = useFormContext();
    const currentFormValue = getValues(name || '') ?? defaultValue;
    const [focusEditor, setFocus] = useState(focusInput);
    const [placeholderVisibility, setPlaceholderVisibility] = useState(true);
    const classes = useStyles();

    useEffect(() => {
        setUpdatedVal(currentFormValue);
    }, [currentFormValue]);
    useEffect(() => {
        setShouldRevert(currentFormValue === defaultValue && updatedVal !== defaultValue);
    }, [defaultValue, currentFormValue, updatedVal]);
    useEffect(() => {
        if (shouldRevert) setShouldRevert(false);
    }, [shouldRevert]);
    useEffect(() => {
        if (rules?.required && name) {
            if (!plainText.length) {
                setError(name, {});
            } else {
                clearErrors(name);
            }
        }
    }, [clearErrors, name, plainText.length, rules?.required, setError]);
    const inputRef = useRef<TMUIRichTextEditorRef>(null);
    useEffect(() => {
        if (inputRef.current && focusEditor) {
            inputRef.current.focus();
        }
    }, [focusEditor, inputRef]);

    Object.assign(defaultTheme, {
        overrides: {
            MUIRichTextEditor: {
                root: {
                    marginTop: 20,
                    width: '97%',
                    paddingLeft: 20,
                    fontWeight: '400',
                    overflow: 'auto',

                    '&:focus-within': {
                        marginTop: 19,
                        paddingLeft: 19,
                    },
                },
                editorContainer: {
                    width: '100%',
                    height: '140px',
                },
                container: {
                    height: '200px',
                    position: 'relative',
                },
                error: {
                    border: '0',
                },
            },

            MuiIconButton: {
                root: {
                    marginLeft: '1rem',
                },
                colorPrimary: {
                    color: 'var(--primary)',
                    backgroundColor: 'var(--primary-3)',
                },
            },
        },
    });

    const getCharCountHelperText = (currentValue: string): ReactNode => {
        let val = currentValue;
        if (val.startsWith(`{"blocks":`)) {
            const defaultBlocks: any = name && (typeof val === 'string' ? JSON.parse(val).blocks : []);
            val = defaultBlocks.map((block: { text: string }) => (!block.text.trim() && '') || block.text).join('\n');
        }

        return (
            <Box sx={{ display: 'flex', flexDirection: 'row', ml: 'auto', pr: '0.5rem', py: '0.5rem' }}>
                {errors[name || ''] && errormessage && <div>{errormessage}</div>}
                {showCharacterCount && (
                    <Box
                        sx={{
                            order: '2',
                            ml: 'auto',
                            float: 'right',
                            fontWeight: 'lighter',
                            color: 'var(--text-light)',
                        }}
                    >
                        {val === '' ? `0/${maxLength}` : `${val.length}/${maxLength}`}
                    </Box>
                )}
            </Box>
        );
    };

    const customInputProps = inputProps || {};

    if (!customInputProps['data-testid']) {
        customInputProps['data-testid'] = name;
    }
    const hasError = name && errors[name] !== undefined;
    return (
        <Controller
            control={control}
            name={name || ''}
            rules={rules}
            defaultValue={defaultValue}
            render={({ onChange, onBlur }) => (
                <Box sx={{ mt: '1rem', width: '100%' }}>
                    <InputLabel htmlFor={name} error={!!errors[name || '']} style={{ width: '100%' }}>
                        {label} {children}
                        <Box
                            className="richTextEditor"
                            sx={{ mt: '0.5rem' }}
                            role="textbox"
                            onClick={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                        >
                            <ThemeProvider theme={defaultTheme}>
                                <Box
                                    sx={{ position: 'relative' }}
                                    className={classes.editor}
                                    role="textbox"
                                    aria-label={name}
                                    tabIndex={0}
                                    style={hasError ? { border: '2px solid var(--alert)' } : {}}
                                >
                                    {!shouldRevert && (
                                        <MUIRichTextEditor
                                            data-testid={customInputProps['data-testid']}
                                            defaultValue={defaultValue}
                                            ref={inputRef}
                                            draftEditorProps={{ spellCheck: true }}
                                            onChange={(value: EditorState) => {
                                                const { blocks } = convertToRaw(value.getCurrentContent());
                                                const content = JSON.stringify(convertToRaw(value.getCurrentContent()));
                                                const text = blocks
                                                    .map(block => (!block.text.trim() && '') || block.text)
                                                    .join('\n');
                                                if ((text.length === 0) !== placeholderVisibility) {
                                                    setPlaceholderVisibility(text.length === 0);
                                                }
                                                if (currentFormValue !== content) {
                                                    setPlainText(text);
                                                    if (name) setValue(name, content);
                                                    if (markFormAsDirty) markFormAsDirty();
                                                    onChange(content);
                                                    setUpdatedVal(content);

                                                    if (onValueChange) {
                                                        onValueChange(content);
                                                    }
                                                }

                                                if (rules?.required && currentFormValue === '') {
                                                    clearErrors();
                                                }
                                            }}
                                            onBlur={onBlur}
                                            error={!!errors[name || '']}
                                            maxLength={maxLength}
                                            controls={[
                                                'bold',
                                                'italic',
                                                'underline',
                                                'link',
                                                'quote',
                                                'numberList',
                                                'bulletList',
                                            ]}
                                        />
                                    )}
                                    {placeholderVisibility && <div className="placeholder">{placeholder}</div>}
                                </Box>
                                <Box>{getCharCountHelperText(plainText)}</Box>
                            </ThemeProvider>
                        </Box>
                    </InputLabel>
                </Box>
            )}
        />
    );
};

RichTextField.defaultProps = {
    errormessage: '',
    showCharacterCount: false,
    maxLength: 0,
    onValueChange: null,
    markFormAsDirty: null,
    revert: null,
    focusInput: false,
    children: null,
    rules: null,
    setPostCharacterCount: null,
};

export default RichTextField;
