import React, { ReactElement, ReactNode, SyntheticEvent } from 'react'; // importing FunctionComponent
import { Avatar, Box, SxProps } from '@mui/material';
import BlockedAvatar from '../BlockedAvatar';
import './basic-card.scss';

export type BasicCardBackground = 'white' | 'grey';

interface BasicCardProps {
    children: ReactNode;
    prefix?: ReactNode;
    header?: ReactNode;
    background?: BasicCardBackground;
    title?: ReactNode;
    imagePath?: string;
    emptyAvatarIcon?: string;
    missingAvatar?: string | ReactElement;
    onSelected?: (event?: SyntheticEvent) => void;
    noAvatar?: boolean;
    className?: string;
    alert?: boolean;
}

export const BasicCard = ({
    prefix,
    header,
    imagePath,
    children,
    background,
    title,
    emptyAvatarIcon,
    missingAvatar,
    onSelected,
    noAvatar,
    className,
    alert,
}: BasicCardProps): ReactElement => {
    const imageClassName = (imagePath && imagePath !== '') || emptyAvatarIcon ? 'card_image' : '';
    const cardClassName = `basic-card ${background} ${alert ? 'alert' : ''}`;
    const cardStyles: SxProps = {
        width: '100%',
        p: '0.75rem',
        alignItems: 'center',
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        textAlign: 'inherit',
    };

    const cardContent = (
        <>
            {prefix && <span>{prefix}</span>}
            {!noAvatar && (
                <div className={imageClassName}>
                    {alert ? (
                        <BlockedAvatar>
                            <Avatar
                                aria-hidden="true"
                                alt=""
                                src={imagePath || 'unknown.jpg'}
                                sx={{ width: 80, height: 80 }}
                            >
                                {typeof missingAvatar === 'string' ? missingAvatar.toUpperCase() : missingAvatar}
                            </Avatar>
                        </BlockedAvatar>
                    ) : (
                        <Avatar
                            aria-hidden="true"
                            alt=""
                            src={imagePath || 'unknown.jpg'}
                            sx={{ width: 80, height: 80 }}
                        >
                            {typeof missingAvatar === 'string' ? missingAvatar.toUpperCase() : missingAvatar}
                        </Avatar>
                    )}
                </div>
            )}
            <Box className="card-content" sx={{ flex: '1 1 0%' }}>
                {header && <div className="card-content_header">{header}</div>}

                {title && (
                    <Box
                        component={onSelected ? 'button' : 'div'}
                        sx={{ color: onSelected ? 'primary' : 'text.primary' }}
                        className={className || `card-content_title`}
                    >
                        {title}
                    </Box>
                )}
                {children}
            </Box>
        </>
    );

    return (
        <Box
            className={cardClassName}
            sx={{ ...cardStyles }}
            component="div"
            role={onSelected ? 'button' : 'presentation'}
            onClick={onSelected}
        >
            {cardContent}
        </Box>
    );
};

BasicCard.defaultProps = {
    prefix: null,
    imagePath: null,
    background: 'grey',
    onSelected: null,
    title: null,
    missingAvatar: '',
    emptyAvatarIcon: 'fas fa-image',
    header: null,
    noAvatar: false,
    className: '',
    alert: false,
};

export default BasicCard;
