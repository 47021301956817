import React, { ReactElement, useState } from 'react';

import { Box, MenuItem, Typography } from '@mui/material';

import ControllerSelectField from 'components/common/form-controls/ControllerSelectField';
import ControllerTextField from 'components/common/form-controls/ControllerTextField';
import EmailAutoComplete from 'components/common/form-controls/EmailAutoComplete';
import Panel from 'components/common/panel/Panel';
import { Control, DeepMap, FieldError, FieldValues } from 'react-hook-form';
import { Relation } from 'typings/generated';

export enum EmailAddReason {
    CreatingOrganization,
    JoiningOrganization,
    AddingEmail,
}

interface OrganizationCredientialsPanelProps {
    control: Control<FieldValues>;
    errors: DeepMap<FieldValues, FieldError>;
    emailAddReason?: EmailAddReason;
}

const OrganizationCredentialsPanel = ({
    control,
    errors,
    emailAddReason,
}: OrganizationCredientialsPanelProps): ReactElement => {
    const [relations] = useState<string[]>(() => {
        return Object.keys(Relation);
    });

    return (
        <Panel title={<Typography variant="h2">*Your Credentials</Typography>}>
            {emailAddReason === EmailAddReason.JoiningOrganization ? (
                <>
                    <Typography sx={{ fontWeight: '600' }}>
                        Provide your credentials at this organization you want to join.
                    </Typography>
                    <Typography>
                        After sending, email addresses must be validated before this request this request will be seen
                        by an organization&apos;s managers.
                    </Typography>
                </>
            ) : (
                <Typography sx={{ fontWeight: '600' }}>
                    Provide your credentials at this organization you are creating.
                </Typography>
            )}
            <Box sx={{ my: '1rem' }}>
                <ControllerSelectField
                    defaultValue=""
                    control={control}
                    errors={errors}
                    label="*Relation"
                    name="relation"
                    placeholder="-Select relation-"
                    rules={{
                        required: 'Relation required',
                    }}
                    fullWidth
                >
                    {relations.map((relation, index) => (
                        <MenuItem value={relation} key={index}>
                            {relation}
                        </MenuItem>
                    ))}
                </ControllerSelectField>
            </Box>
            <Box>
                <ControllerTextField
                    control={control}
                    errors={errors}
                    name="role"
                    label="*Role"
                    placeholder="Enter Role"
                    rules={{
                        required: 'Role required',
                    }}
                    defaultValue=""
                    maxLength={50}
                    showCharacterCount
                    errormessage="Role required"
                />
            </Box>
            <Box sx={{ mb: '1rem' }}>
                <EmailAutoComplete control={control} errors={errors} emailAddReason={emailAddReason} />
            </Box>
        </Panel>
    );
};

OrganizationCredentialsPanel.defaultProps = {
    emailAddReason: EmailAddReason.CreatingOrganization,
};

export default OrganizationCredentialsPanel;
