import React, { ReactElement } from 'react';

import { Box, Card, Container, Link, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

import LinkWithHeading from './components/LinkWithHeading';

const subtitleStyles: SxProps = { margin: '.5em', color: 'primary.contrastText' };

const WorkWithAFWERX = (): ReactElement => {
    return (
        <Box sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText' }}>
            <Container
                sx={{
                    display: 'grid',
                    textAlign: 'center',
                    alignItems: 'center',
                    justifyItems: 'center',
                }}
            >
                <Typography variant="h2">Work With AFWERX</Typography>
                <Container>
                    <Typography
                        sx={{
                            mx: '1em',
                            fontSize: '20px',
                        }}
                        variant="h3"
                    >
                        Teaming across academia, industry, investment, interagency and international partners, AFWERX is
                        expanding talent, technology, and transition of dual-use technologies. There are many
                        opportunities to get involved with AFWERX. Here are some great ways to get started.
                    </Typography>
                    <Container
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: { xs: '1fr', md: '1fr', lg: 'repeat(3, 1fr)' },
                            gridTemplateRows: { xs: 'repeat(3, 1fr)', md: 'repeat(3, 1fr)', lg: '1fr' },
                            my: '30px',
                            gridGap: '25px',
                            alignItems: 'center',
                            justifyItems: 'center',
                        }}
                    >
                        <Card
                            sx={{
                                height: '660px',
                                maxWidth: '320px',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'grid',
                                    alignItems: 'center',
                                    justifyItems: 'center',
                                    backgroundColor: 'primary.main',
                                    height: '68px',
                                    margin: '1em',
                                }}
                            >
                                <Typography
                                    sx={{
                                        ...subtitleStyles,
                                    }}
                                    variant="subtitle1"
                                >
                                    COMMERCIAL INVESTOR, INDUSTRY OR ACADEMIA
                                </Typography>
                            </Box>
                            <Container
                                sx={{
                                    display: 'grid',
                                    alignItems: 'left',
                                    justifyItems: 'left',
                                }}
                            >
                                <Typography
                                    sx={{
                                        margin: '.5em 0 1.5em 0',
                                        fontWeight: 900,
                                    }}
                                    variant="body1"
                                >
                                    LEARN ABOUT:
                                </Typography>
                                <LinkWithHeading
                                    headingText="Open crowdsourcing"
                                    linkText="AFWERX Challenges"
                                    href="https://afwerxchallenge.com/"
                                />
                                <LinkWithHeading
                                    headingText="networking"
                                    linkText="colliders"
                                    href="https://afwerx.com/spark_/#collapse-393fbbe0e93d6d77f9a8d400566a4bde-3"
                                />
                                <LinkWithHeading
                                    headingText="funding"
                                    linkText="SBIR/STTR"
                                    href="https://afwerx.com/sbirsttr/"
                                />
                                <LinkWithHeading
                                    headingText="funding"
                                    linkText="STRATFI/TACFI"
                                    href="https://afwerx.com/afventures-overview/"
                                />
                                <LinkWithHeading
                                    headingText="funding"
                                    linkText="prime workshops"
                                    href="https://afwerx.com/prime-overview/"
                                />
                                <LinkWithHeading
                                    headingText="event"
                                    linkText="pitch day"
                                    href="https://www.afsbirsttr.af.mil/Events/Pitch-Days/"
                                />
                            </Container>
                        </Card>

                        <Card
                            sx={{
                                height: '660px',
                                maxWidth: '320px',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'grid',
                                    alignItems: 'center',
                                    justifyItems: 'center',
                                    backgroundColor: 'primary.main',
                                    height: '68px',
                                    margin: '1em',
                                }}
                            >
                                <Typography
                                    sx={{
                                        ...subtitleStyles,
                                    }}
                                    variant="subtitle1"
                                >
                                    GOVERNMENT WITH A MISSION NEED
                                </Typography>
                            </Box>
                            <Container
                                sx={{
                                    display: 'grid',
                                    alignItems: 'left',
                                    justifyItems: 'left',
                                }}
                            >
                                <Typography
                                    sx={{
                                        margin: '.5em 0 1.5em 0',
                                        fontWeight: 900,
                                    }}
                                >
                                    LEARN ABOUT:
                                </Typography>
                                <LinkWithHeading
                                    headingText="open crowdsourcing"
                                    linkText="AFWERX challenges"
                                    href="https://afwerxchallenge.com/"
                                />
                                <LinkWithHeading
                                    headingText="networking"
                                    linkText="colliders"
                                    href="https://afwerx.com/spark_/#collapse-393fbbe0e93d6d77f9a8d400566a4bde-3"
                                />
                                <LinkWithHeading
                                    headingText="funding"
                                    linkText="SBIR/STTR"
                                    href="https://afwerx.com/sbirsttr/"
                                />
                                <LinkWithHeading
                                    headingText="funding"
                                    linkText="STRATFI/TACFI"
                                    href="https://afwerx.com/afventures-overview/"
                                />
                                <LinkWithHeading
                                    headingText="transition"
                                    linkText="prime program"
                                    href="https://afwerx.com/prime-overview/"
                                />
                                <LinkWithHeading
                                    headingText="pitch event"
                                    linkText="Spark Tank"
                                    href="https://afwerx.com/spark_/spark-tank/"
                                />
                                <LinkWithHeading
                                    headingText="problem solving"
                                    linkText="design workshop"
                                    href="https://afwerx.com/events_/"
                                />
                                <LinkWithHeading
                                    headingText="event"
                                    linkText="pitch day"
                                    href="https://www.afsbirsttr.af.mil/Events/Pitch-Days/"
                                />
                            </Container>
                        </Card>
                        <Card
                            sx={{
                                height: '660px',
                                width: '320px',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'grid',
                                    alignItems: 'center',
                                    justifyItems: 'center',
                                    backgroundColor: 'primary.main',
                                    height: '68px',
                                    margin: '1em',
                                }}
                            >
                                <Typography
                                    sx={{
                                        ...subtitleStyles,
                                    }}
                                    variant="subtitle1"
                                >
                                    GOVERNMENT INNOVATOR
                                </Typography>
                            </Box>
                            <Container
                                sx={{
                                    display: 'grid',
                                    alignItems: 'left',
                                    justifyItems: 'left',
                                }}
                            >
                                <Typography
                                    sx={{
                                        margin: '.5em 0 1.5em 0',
                                        fontWeight: 900,
                                    }}
                                >
                                    LEARN ABOUT:
                                </Typography>
                                <LinkWithHeading
                                    headingText="networking"
                                    linkText="colliders"
                                    href="https://afwerx.com/spark_/#collapse-393fbbe0e93d6d77f9a8d400566a4bde-3"
                                />
                                <LinkWithHeading
                                    headingText="funding"
                                    linkText="SBIR/STTR"
                                    href="https://afwerx.com/sbirsttr/"
                                />
                                <LinkWithHeading
                                    headingText="funding"
                                    linkText="squadron innovation fund"
                                    href="https://afwerx.com/spark_/"
                                />
                                <LinkWithHeading
                                    headingText="community"
                                    linkText="spark cells"
                                    href="https://afwerx.com/spark_/"
                                />
                                <LinkWithHeading
                                    headingText="AFWERX Augmentee"
                                    linkText="Join the Bench"
                                    href="https://docs.google.com/forms/d/e/1FAIpQLScNOOObR1qx-Vv_xrmmOJKwFfM-_RaKg7sNZI25jG-l46T6ew/viewform"
                                />
                                <LinkWithHeading
                                    headingText="career"
                                    linkText="AFWERX Fellowships"
                                    href="https://afwerx.com/fellowships/"
                                />
                                <Link
                                    href="https://afwerx.com/afwerx-internships/"
                                    sx={{ mt: '0.1em', fontSize: '16px', fontWeight: 400, textTransform: 'capitalize' }}
                                >
                                    AFWERX Internships
                                </Link>
                                <LinkWithHeading
                                    headingText="pitch event"
                                    linkText="spark tank"
                                    href="https://afwerx.com/spark_/spark-tank/"
                                />
                                <LinkWithHeading
                                    headingText="internal crowdsourcing"
                                    linkText="GAIN"
                                    href="https://gain.il4.afwerx.dso.mil/"
                                />
                                <LinkWithHeading
                                    headingText="vision"
                                    linkText="VISION"
                                    href="https://vision.apps.dso.mil/"
                                />
                            </Container>
                        </Card>
                    </Container>
                </Container>
            </Container>
        </Box>
    );
};

export default WorkWithAFWERX;
