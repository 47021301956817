import React, { ReactElement, ReactNode } from 'react';

import { Autocomplete, AutocompleteRenderInputParams, Box, InputLabel, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import OrgAutoCompleteResult from 'components/common/form-controls/OrgAutoCompleteResult';
import unionBy from 'lodash-es/unionBy';
import { LabelPairValue } from 'models/LabelValuePair';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootStore } from 'state/store/reducers';
import {
    AffiliationFragment,
    IdentityAffiliationStatus,
    OrganizationFragment,
    SearchOnboardingCompaniesQueryVariables,
    useSearchOnboardingCompaniesLazyQuery,
} from 'typings/generated';

interface CompanyAutocompleteProps {
    errors: DeepMap<Record<string, any>, FieldError>;
    control: Control<Record<string, any>>;
    onCompanyChange?: (value: string) => void;
    organizationId?: string | null;
    useOrgId?: boolean; // used in admin page  - use the organizationId instead of the user.organization.id (ie. current user)
    required?: boolean;
    onboardingCreateOrg?: LabelPairValue;
}

export const CompanyAutocomplete = ({
    errors,
    control,
    onCompanyChange,
    organizationId,
    useOrgId,
    required,
    onboardingCreateOrg,
}: CompanyAutocompleteProps): ReactElement => {
    const user = useSelector((storeState: RootStore) => storeState.User);
    const location = useLocation();

    const [getOrganization, { data: organizationData, loading }] = useSearchOnboardingCompaniesLazyQuery({
        fetchPolicy: 'cache-and-network',
    });

    const searchOnboardingOrganizations: SearchOnboardingCompaniesQueryVariables = {
        searchTerm: '',
    };

    const getStatus = (affiliation: AffiliationFragment) => {
        switch (affiliation.identityAffiliationStatus) {
            case IdentityAffiliationStatus.UnverifiedUser.toString(): {
                return 'Needs Validation';
            }
            case IdentityAffiliationStatus.ApprovalPending.toString(): {
                return 'Pending';
            }
            case IdentityAffiliationStatus.Validated.toString(): {
                return 'Validated';
            }
            default:
                return '';
        }
    };

    const organizations =
        organizationData?.results.items.map<LabelPairValue>((org: OrganizationFragment) => {
            return {
                name: org.name || '',
                value: org.id,
                highlightedLabel: org.name,
                image: org.avatar || '',
                status: '',
                metadata: {
                    city: org.city,
                    state: org.state,
                },
            };
        }) || [];

    const affiliatedOrgData = user.userAffiliations
        ? user.userAffiliations.map(item => {
              user.identities.find(i => {
                  return i.affiliations.find(a => a.id === item.id);
              });

              const orgData = {
                  name: item.organization.name,
                  value: item.organization.id,
                  status: getStatus(item),
                  highlightedLabel: item.organization.name,
                  image: item.organization.avatar || '',
                  metadata: {
                      city: item.organization.city,
                      state: item.organization.state,
                  },
              };

              return orgData;
          })
        : [];

    const autoCompleteItems = unionBy<LabelPairValue>(
        affiliatedOrgData,
        organizations,
        onboardingCreateOrg ? [onboardingCreateOrg] : [],
        'value'
    );

    const renderAutocompleteInput = (params: AutocompleteRenderInputParams): ReactNode => {
        return (
            <Box sx={{ mt: '1rem' }}>
                <InputLabel htmlFor="organization" id="organization-label" error={!!errors?.organization}>
                    {required ? '*Organization' : 'Organization'}
                    <Box sx={{ mt: '0.5rem' }}>
                        <TextField
                            {...params}
                            style={{
                                width: '100%',
                            }}
                            id="organization"
                            size="small"
                            variant="outlined"
                            placeholder="-Select an organization-"
                            inputProps={{
                                ...params.inputProps,
                                id: 'organization',
                                'data-testid': 'autocomplete',
                                'aria-describedby': 'organization-label',
                            }}
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    </Box>
                </InputLabel>
            </Box>
        );
    };

    const setDefaultValue = () => {
        let defaultValue = '';
        if (useOrgId) {
            defaultValue = organizationId || '';
        } else if (user?.organization?.id) {
            defaultValue = user?.organization?.id;
        }
        return autoCompleteItems?.find(a => a.value === defaultValue);
    };

    return (
        <>
            <Controller
                control={control}
                name="organization"
                defaultValue={location.pathname === '/joinorganization' ? null : setDefaultValue()}
                rules={{
                    required,
                }}
                render={({ onChange, ...props }) => (
                    <Autocomplete
                        disablePortal
                        data-testid="organization"
                        key={organizationId || user.organization?.id || null}
                        options={autoCompleteItems || []}
                        getOptionDisabled={option => option.status !== ''}
                        loading={loading}
                        getOptionLabel={option => option.name || user?.organization?.name || ''}
                        isOptionEqualToValue={(option, value) => {
                            return option.name === value.name;
                        }}
                        renderOption={(_props, option) => (
                            <Box component="li" {..._props}>
                                <OrgAutoCompleteResult orgType="company" item={option} inputValue={option.value} />
                            </Box>
                        )}
                        renderInput={renderAutocompleteInput}
                        noOptionsText="Organization not found. Use link below to create organization."
                        onChange={(e, data) => {
                            onChange(data);
                            if (onCompanyChange) onCompanyChange(data?.value);
                        }}
                        onInputChange={(event: any, searchTerm: string) => {
                            getOrganization({
                                variables: {
                                    ...searchOnboardingOrganizations,
                                    searchTerm,
                                },
                            });
                        }}
                        onOpen={() => {
                            getOrganization({
                                variables: {
                                    ...searchOnboardingOrganizations,
                                    searchTerm: '',
                                },
                            });
                        }}
                        {...props}
                    />
                )}
            />
        </>
    );
};

CompanyAutocomplete.defaultProps = {
    organizationId: null,
    useOrgId: false, // instead of user org id
    required: false,
    onboardingCreateOrg: undefined,
    onCompanyChange: undefined,
};

export default CompanyAutocomplete;
