import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { RootStore } from 'state/store/reducers';
import { hasCapability } from 'utilities/utils';

import './Viewable.scss';

type ViewableProps = {
    children: ReactNode;
    capabilities?: string[];
    hideFromGovernment?: boolean;
    hideFromCommercial?: boolean;
    orgId?: string;
};

export const Viewable = ({
    capabilities,
    children,
    hideFromGovernment,
    hideFromCommercial,
    orgId,
}: ViewableProps): ReactElement => {
    const user = useSelector((storeState: RootStore) => storeState.User);
    const [canView, setCanView] = useState(false);

    useEffect(() => {
        if (hideFromGovernment && user && user.isGovernment) {
            setCanView(false);
        } else if (hideFromCommercial && user && !user.isGovernment) {
            setCanView(false);
        } else {
            setCanView(hasCapability(user, capabilities, orgId));
        }
    }, [user, capabilities, hideFromGovernment, hideFromCommercial, orgId]);

    return canView ? <>{children}</> : <></>;
};

Viewable.defaultProps = {
    capabilities: null,
    hideFromGovernment: false,
    hideFromCommercial: false,
    orgId: null,
};

export default Viewable;
