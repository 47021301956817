/* eslint-disable import/no-extraneous-dependencies */
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

import { Button, Typography } from '@mui/material';

import Modal from 'components/common/modal/Modal';
import { Location } from 'history';
import { Prompt } from 'react-router-dom';
import ButtonGroup from '../button-group/ButtonGroup';

interface Props {
    when?: boolean;
    title: string;
    navigate: (path: string) => void;
    shouldBlockNavigation: (location: Location) => boolean;
    content: ReactNode;
    onConfirmLeave?: () => void;
    leftButtons?: boolean;
}

const RouteLeavingGuard = ({
    when,
    title,
    content,
    navigate,
    shouldBlockNavigation,
    onConfirmLeave,
    leftButtons,
}: Props): ReactElement => {
    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState<Location | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const [navigated, setNavigated] = useState(false);
    const closeModal = (): void => {
        setModalVisible(false);
    };

    const handleBlockedNavigation = (nextLocation: Location): boolean => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            setModalVisible(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };

    const handleConfirmNavigationClick = (): void => {
        setModalVisible(false);
        setConfirmedNavigation(true);
        if (onConfirmLeave) onConfirmLeave();
    };

    useEffect(() => {
        if (confirmedNavigation && lastLocation && !navigated) {
            // Navigate to the previous blocked location with your navigate function
            setNavigated(true);
            navigate(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation, navigate, navigated]);

    useEffect(() => {
        if (modalVisible) {
            window.history.pushState(null, window.location.href);
            // I do not think this is used - I wasn't able to get it fire
            // window.onpopstate = (event: PopStateEvent) => {
            //     window.history.go(1);
            // };
        }
        // return () => {
        //     window.onpopstate = null;
        // };
    }, [modalVisible]);

    return !leftButtons ? (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
            <Modal display={modalVisible} onClose={(): any => closeModal()} footerStyles={{ display: 'flex' }}>
                {{
                    title: (
                        <Typography variant="h2" sx={{ fontWeight: 700 }}>
                            {title}
                        </Typography>
                    ),
                    content: <div style={{ fontSize: '16px' }}>{content}</div>,
                    footer: (
                        <ButtonGroup justify="space-evenly">
                            <Button variant="outlined" color="primary" onClick={handleConfirmNavigationClick}>
                                Leave
                            </Button>
                            <Button variant="contained" color="primary" onClick={closeModal}>
                                Stay
                            </Button>
                        </ButtonGroup>
                    ),
                }}
            </Modal>
        </>
    ) : (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
            <Modal
                display={modalVisible}
                onClose={(): any => closeModal()}
                footerStyles={{ display: 'inline', m: '0px' }}
            >
                {{
                    title: (
                        <Typography variant="h2" sx={{ fontWeight: 700 }}>
                            {title}
                        </Typography>
                    ),
                    content: <div style={{ fontSize: '16px' }}>{content}</div>,
                    footer: (
                        <ButtonGroup sx={{ mt: '10px' }}>
                            <Button variant="outlined" color="primary" onClick={handleConfirmNavigationClick}>
                                Leave
                            </Button>
                            <Button variant="contained" color="primary" onClick={closeModal}>
                                Stay
                            </Button>
                        </ButtonGroup>
                    ),
                }}
            </Modal>
        </>
    );
};

RouteLeavingGuard.defaultProps = {
    when: false,
    onConfirmLeave: null,
    leftButtons: false,
};

export default RouteLeavingGuard;
