import React, { ReactElement, ReactNode, useState } from 'react';

import { Button, IconButton } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { InfoIcon } from 'assets/icons';

import Modal from '../modal/Modal';

const useStyles = makeStyles({
    toolTipContainer: {
        opacity: 1,
        '& h1': {
            fontWeight: 'var(--font-weight-bold)',
            fontSize: 18,
            lineHeight: '2em',
        },
        '& h2': {
            fontWeight: 'var(--font-weight-semibold)',
            fontSize: 16,
            lineHeight: '2em',
        },
        '& p': {
            lineHeight: '1.25em',
            marginBottom: '1em',
        },
    },
});

interface InfoDialogProps {
    children: {
        content: ReactNode;
        title?: ReactNode;
    };
    iconTestId?: string;
    buttonText?: string;
}

export const InfoDialog = ({ children, iconTestId, buttonText }: InfoDialogProps): ReactElement => {
    const [showInfoModal, setShowInfoModal] = useState(false);
    const classes = useStyles();

    return (
        <>
            <Modal display={showInfoModal} onClose={(): any => setShowInfoModal(false)}>
                {{
                    title: children.title,
                    content: <div className={classes.toolTipContainer}>{children.content}</div>,
                    footer: (
                        <>
                            <Button
                                data-testid="readiness-modal-ok"
                                variant="contained"
                                color="primary"
                                onClick={(): any => setShowInfoModal(false)}
                            >
                                OK
                            </Button>
                        </>
                    ),
                }}
            </Modal>
            {buttonText ? (
                <Button
                    sx={{ pl: 0, fontWeight: '700', display: 'flex', gap: '0.25em' }}
                    data-testid={iconTestId}
                    aria-label="More Information"
                    type="button"
                    onClick={() => setShowInfoModal(true)}
                >
                    {buttonText}
                    <InfoIcon />
                </Button>
            ) : (
                <IconButton
                    data-testid={iconTestId}
                    aria-label="More Information"
                    color="primary"
                    onClick={() => setShowInfoModal(true)}
                >
                    <InfoIcon />
                </IconButton>
            )}
        </>
    );
};

InfoDialog.defaultProps = {
    iconTestId: 'info-icon',
    buttonText: '',
};

export default InfoDialog;
