import { ExplorerFilter } from 'models/ExplorerFilter';
import { Levels } from 'models/Levels';
import { Pagination } from 'models/Pagination';
import { AnyAction } from 'redux';
import { DirType, SortInput } from 'typings/generated';
import {
    APPLY_ADVANCED_FILTERS,
    CLEAR_FILTERS,
    SET_CATEGORY,
    SET_MRLLEVEL,
    SET_PAGINATION,
    SET_SEARCHTERM,
    SET_SEARCHTERMCATEGORY,
    SET_SORT,
    SET_TECHNOLOGYFILTERS,
    SET_TRLLEVEL,
    SET_VISIBILITY,
} from '../actions/ExplorerSearch';

export interface ExplorerSearchState {
    searchTerm: string;
    technologyFilters: ExplorerFilter[];
    trlLevel: Levels;
    mrlLevel: Levels;
    category: any;
    sort: SortInput;
    pagination: Pagination;
    showFilters?: boolean;
    retainForUrl?: string;
}

const initialState: ExplorerSearchState = {
    searchTerm: '',
    category: '',
    sort: { field: 'createDate', direction: DirType.Desc },
    technologyFilters: [],
    trlLevel: { start: 0, end: 9 },
    mrlLevel: { start: 0, end: 10 },
    pagination: { offset: 0, limit: 20 },
    showFilters: true,
};

export interface AdvancedFilters {
    technologyFilters: ExplorerFilter[];
    trlLevel: Levels;
    mrlLevel: Levels;
    searchTerm?: string;
    showFilters?: boolean;
    retainForUrl?: string;
}

export function getInitialState(): ExplorerSearchState {
    return initialState;
}

export default (state = initialState, action: AnyAction): ExplorerSearchState => {
    const { type } = action;
    switch (type) {
        case SET_SEARCHTERM: {
            return { ...state, searchTerm: action.payload as string };
        }
        case SET_PAGINATION: {
            return { ...state, pagination: action.payload as Pagination };
        }
        case SET_CATEGORY: {
            return {
                ...state,
                category: action.payload as string,
            };
        }
        case SET_SORT: {
            return {
                ...state,
                sort: action.payload as SortInput,
                pagination: { offset: 0, limit: initialState.pagination.limit },
            };
        }
        case SET_SEARCHTERMCATEGORY: {
            return state;
        }
        case SET_TRLLEVEL: {
            return { ...state, trlLevel: action.payload as Levels };
        }
        case SET_MRLLEVEL: {
            return { ...state, mrlLevel: action.payload as Levels };
        }
        case SET_TECHNOLOGYFILTERS: {
            return {
                ...state,
                technologyFilters: action.payload as Array<ExplorerFilter>,
            };
        }
        case CLEAR_FILTERS: {
            // clear all advanced filters
            return initialState;
        }
        case APPLY_ADVANCED_FILTERS: {
            return {
                ...state,
                ...(action.payload as AdvancedFilters),
                pagination: { offset: 0, limit: initialState.pagination.limit },
            };
        }
        case SET_VISIBILITY: {
            return { ...state, showFilters: action.payload as boolean };
        }
        default:
            return state;
    }
};
