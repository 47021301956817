import React, { ReactElement } from 'react';
import { Box, Button, Card, CardActions, Container, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import afventuresLogo from './assets/AFVentures-logo.png';
import sparkLogo from './assets/Spark-logo.png';
import primeLogo from './assets/Prime-logo.png';
import sbirSttrLogo from './assets/SBIR-STTR-logo.png';
import spacewerxLogo from './assets/spacewerx_1-80x80.png';
import afwerxLogo from './assets/AFWERX-Solid-White.png';

const cardStyles: SxProps = {
    width: '342px',
    minHeight: '481px',
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
    textAlign: 'center',
    display: 'grid',
    gridTemplateRows: '130px auto 50px',
    justifyContent: 'center',
    py: '30px',
    px: '20px',
};

const buttonStyles: SxProps = {
    width: '176px',
    fontFamily: 'Roboto',
    mx: 'auto',
};

const captionStyles: SxProps = {
    textTransform: 'uppercase',
    fontSize: '16px',
    fontWeight: '600',
    my: '20px',
};

const AboutAFWERX = (): ReactElement => {
    return (
        <Box
            sx={{
                background: 'linear-gradient(180deg, rgba(15, 24, 45, 1), rgba(15, 24, 45, 0.3))',
                backgroundColor: 'white',
                color: 'primary.contrastText',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <Typography variant="h2">Mission</Typography>
                <Typography
                    sx={{
                        mx: '1em',
                        fontSize: '20px',
                    }}
                    variant="h3"
                >
                    AFWERX accelerates agile and affordable capability transitions by teaming leaders in innovative
                    technology with Airman and Guardian talent.
                </Typography>
                <Typography variant="h2">Vision</Typography>
                <Typography
                    sx={{
                        mx: '1em',
                        fontSize: '20px',
                    }}
                    variant="h3"
                >
                    Forge an innovation ecosystem that delivers disruptive Air & Space capabilities.
                </Typography>
                <Typography variant="h2">Mantra</Typography>
                <Typography
                    sx={{
                        mx: '1em',
                        fontSize: '20px',
                    }}
                    variant="h3"
                >
                    Unleashing American Ingenuity
                </Typography>
            </Box>
            <Container
                maxWidth="lg"
                sx={{
                    display: 'grid',
                    gridTemplateColumns: { md: '1fr', lg: 'repeat(3, 1fr)' },
                    gap: '20px',
                    mt: '30px',
                    pb: '50px',
                    width: 'auto',
                }}
            >
                <Card
                    sx={{
                        ...cardStyles,
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box
                            component="img"
                            src={afventuresLogo}
                            alt="United States Air Force"
                            height={80}
                            width={80}
                        />
                        <Typography variant="h3" sx={{ ...captionStyles }}>
                            AFVENTURES
                        </Typography>
                    </Box>
                    <Typography>
                        An investment program that creates simple pathways for commercial innovators and private capital
                        investment to help the Department of the Air Force solve problems. This program continues to
                        evolve with innovative contracting and funding pathways.
                    </Typography>
                    <CardActions>
                        <Button
                            variant="outlined"
                            href="https://afwerx.com/afventures-overview/"
                            color="inherit"
                            sx={{ ...buttonStyles }}
                        >
                            Learn More
                        </Button>
                    </CardActions>
                </Card>
                <Card
                    sx={{
                        ...cardStyles,
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box component="img" src={sparkLogo} alt="SPACE Spark" height={80} width={80} />
                        <Typography variant="h3" sx={{ ...captionStyles }}>
                            Spark
                        </Typography>
                    </Box>
                    <Typography>
                        A program that empowers and develops intrapreneurial Airmen and Guardians, connecting them to
                        commercial innovators using virtual collaboration, immersive training and networking
                        opportunities that inspire ideas and cultivate a creative Force.
                    </Typography>
                    <CardActions>
                        <Button
                            variant="outlined"
                            href="https://afwerx.com/spark_/"
                            color="inherit"
                            sx={{ ...buttonStyles }}
                        >
                            Learn More
                        </Button>
                    </CardActions>
                </Card>
                <Card
                    sx={{
                        ...cardStyles,
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box component="img" src={primeLogo} alt="Prime" height={80} width={120} />
                        <Typography variant="h3" sx={{ ...captionStyles }}>
                            Prime
                        </Typography>
                    </Box>
                    <Typography>
                        An industry accelerator program that expands technology transition pathways to accelerate
                        emerging dual-use markets by leveraging government resources for rapid and affordable fielding
                        while bolstering U.S. tech advantage.
                    </Typography>
                    <CardActions>
                        <Button
                            variant="outlined"
                            href="https://afwerx.com/prime-overview/"
                            color="inherit"
                            sx={{ ...buttonStyles }}
                        >
                            Learn More
                        </Button>
                    </CardActions>
                </Card>
                <Card
                    sx={{
                        ...cardStyles,
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box component="img" src={spacewerxLogo} alt="Spacewerx logo" height={80} width={80} />
                        <Typography variant="h3" sx={{ ...captionStyles }}>
                            Spacewerx
                        </Typography>
                    </Box>
                    <Typography>
                        An innovation arm for the U.S. Space Force, SpaceWERX serves to inspire and empower
                        collaboration with innovators to accelerate capabilities and secure our future in space.
                    </Typography>
                    <CardActions>
                        <Button
                            variant="outlined"
                            href="https://spacewerx.us/"
                            color="inherit"
                            sx={{ ...buttonStyles }}
                        >
                            Learn More
                        </Button>
                    </CardActions>
                </Card>
                <Card
                    sx={{
                        ...cardStyles,
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box component="img" src={afwerxLogo} alt="Afwerx logo" height={80} width={80} />
                    </Box>
                    <Typography
                        variant="subtitle1"
                        component="p"
                        sx={{
                            fontSize: '50px',
                            fontWeight: '600',
                            lineHeight: '80px',
                            mx: '45px',
                            mt: '-0.25em',
                            textTransform: 'uppercase',
                        }}
                    >
                        About Us
                    </Typography>
                    <CardActions>
                        <Button
                            variant="outlined"
                            href="https://afwerx.com/about-us/"
                            color="inherit"
                            sx={{ ...buttonStyles }}
                        >
                            Learn More
                        </Button>
                    </CardActions>
                </Card>
                <Card
                    sx={{
                        ...cardStyles,
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box component="img" src={sbirSttrLogo} alt="SBIR/STTR logo" height={80} width={80} />
                        <Typography variant="h3" sx={{ ...captionStyles }}>
                            SBIR/STTR
                        </Typography>
                    </Box>
                    <Typography>
                        The Small Business Innovation Research (SBIR) and Small Business Technology Transfer (STTR)
                        programs provide early stage funding for U.S. companies and U.S. companies affiliated with a
                        research institution to commercialize innovative technologies that can benefit the warfighter.
                    </Typography>
                    <CardActions>
                        <Button
                            variant="outlined"
                            href="https://afwerx.com/sbirsttr/"
                            color="inherit"
                            sx={{ ...buttonStyles }}
                        >
                            Learn More
                        </Button>
                    </CardActions>
                </Card>
            </Container>
        </Box>
    );
};

export default AboutAFWERX;
