import { combineReducers } from 'redux';
import Block, { BlockState } from './Blocks';
import Count, { CountState } from './Counts';
import ExplorerSearch, { ExplorerSearchState } from './ExplorerSearch';
import Organization, { OrganizationState } from './Organization';
import SearchStates, { SearchStateDictionary } from './SearchStates';
import Technology, { TechnologyState } from './Technology';
import User, { UserState } from './User';
import Alert, { AlertState } from './Alert';

export interface RootStore {
    User: UserState;
    ExplorerSearch: ExplorerSearchState;
    SearchStates: SearchStateDictionary;
    Technology: TechnologyState;
    Organization: OrganizationState;
    Count: CountState;
    Block: BlockState;
    Alert: AlertState;
}

const rootReducer = combineReducers({
    User,
    ExplorerSearch,
    SearchStates,
    Technology,
    Organization,
    Count,
    Block,
    Alert,
});

export default rootReducer;
