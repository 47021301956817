import { UpdateUserPreferencesInput } from 'typings/generated';
import { UserState } from '../reducers/User';

export const SET_USER = 'SET_USER';
export const RESET_USER = 'RESET_USER';
export const SET_USER_EMAIL_PREFERENCE = 'SET_USER_EMAIL_PREFERENCE';

export const setUserState = (payload: UserState): any => ({
    type: SET_USER,
    payload,
});

export const resetUser = (): any => ({
    type: RESET_USER,
});

export const setUserPreferences = (payload: UpdateUserPreferencesInput): any => ({
    type: SET_USER_EMAIL_PREFERENCE,
    payload,
});
