import React, { ReactElement, ReactNode } from 'react';
import { Box, SxProps } from '@mui/material';

export type justify = 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
interface ButtonGroupProps {
    children: ReactNode | ReactNode[];
    justify?: justify;
    mobileReverse?: boolean;
    sx?: SxProps;
}
export const ButtonGroup = ({ children, justify, mobileReverse, sx }: ButtonGroupProps): ReactElement => {
    const getChildComponents = () => {
        return Array.isArray(children) ? (
            children.map((element: React.ReactNode, key: number) => (
                <Box key={key} sx={{ mr: '0.5rem' }}>
                    {element}
                </Box>
            ))
        ) : (
            <Box sx={{ mr: '0.5rem' }}>{children}</Box>
        );
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: `${justify}`,
                flexDirection: { xs: mobileReverse ? 'column-reverse' : 'column', sm: 'row' },
                '& button': { width: '100%' },
                ...sx,
            }}
            data-testid="button-group"
        >
            {getChildComponents()}
        </Box>
    );
};

ButtonGroup.defaultProps = {
    justify: 'flex-start',
    mobileReverse: true,
    sx: {},
};

export default ButtonGroup;
