import React, { ReactElement, ReactNode } from 'react';

import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Box,
    createFilterOptions,
    InputLabel,
    TextField,
    Typography,
} from '@mui/material';

import { InfoIcon, ResolvedIcon, UnresolvedFilledIcon } from 'assets/icons';
import { Control, Controller, DeepMap, FieldError, ValidateResult } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootStore } from 'state/store/reducers';
import { EmailAddReason } from 'pageComponents/organization/OrganizationCredentialsPanel';
import { LabeledIcon, ModalTitleLabel, ModalTitleVariant } from '../icons/LabeledIcons';
import InfoDialog from '../info-dialog/InfoDialog';

interface EmailAutocompleteProps {
    errors: DeepMap<Record<string, any>, FieldError>;
    control: Control<Record<string, any>>;
    emailAddReason?: EmailAddReason;
    onEmailChange?: (e: string) => void;
    requireNewEntry?: boolean;
    label?: string;
    placeholder?: string;
}

export const EmailAutocomplete = ({
    errors,
    control,
    emailAddReason,
    onEmailChange,
    requireNewEntry,
    label,
    placeholder,
}: EmailAutocompleteProps): ReactElement => {
    const user = useSelector((storeState: RootStore) => storeState.User);
    const filter = createFilterOptions();
    const emailFormat = /\S+@\S+\.\S+/;

    const identities = user.identities
        ? user.identities.map(item => {
              const emails = {
                  email: item.emailAddress,
                  verified: item.emailVerified,
              };

              return emails;
          })
        : [];

    const renderAutocompleteInput = (params: AutocompleteRenderInputParams): ReactNode => {
        return (
            <Box>
                <InputLabel htmlFor="orgEmail" id="organization-email-label" error={!!errors.orgEmail}>
                    {label}
                    {emailAddReason !== EmailAddReason.AddingEmail && (
                        <InfoDialog>
                            {{
                                title: <ModalTitleLabel icon={<InfoIcon />} label="Email at Organization" />,
                                content: emailAddReason ? (
                                    <Box component="p" sx={{ mt: '0.5rem' }}>
                                        <Typography component="p" gutterBottom>
                                            Please provide your professional email address at this organization, or a
                                            similar email address you control. This will help the organization’s
                                            managers to identify you.
                                        </Typography>
                                        <Typography component="p" gutterBottom>
                                            If you have not already validated this email address on{' '}
                                            {process.env.REACT_APP_NAME}, after you send your request check that email
                                            for a link to validate.
                                        </Typography>
                                        <Typography component="p" gutterBottom>
                                            Once you have validated this email, managers of the organization will review
                                            your request.
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Box component="p" sx={{ mt: '0.5rem' }}>
                                        <Typography component="p" gutterBottom>
                                            Please provide your professional email address at this organization, or a
                                            similar email address you control.
                                        </Typography>
                                    </Box>
                                ),
                            }}
                        </InfoDialog>
                    )}
                    <Box sx={{ mt: '0.5rem' }}>
                        <TextField
                            {...params}
                            fullWidth
                            id="orgEmail"
                            size="small"
                            type="email"
                            variant="outlined"
                            placeholder={placeholder}
                            inputProps={{
                                ...params.inputProps,
                                id: 'orgEmail',
                                'data-testid': 'autocomplete',
                                'aria-describedby': 'organization-email-label',
                            }}
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                            InputProps={{
                                ...params.InputProps,
                            }}
                            error={!!errors.orgEmail}
                            helperText={errors.orgEmail ? errors.orgEmail.message : ''}
                        />
                    </Box>
                </InputLabel>
            </Box>
        );
    };
    const validateValue = (value: string): ValidateResult => {
        if (!emailFormat.test(value)) {
            return 'Valid email format required';
        }
        if (
            requireNewEntry &&
            identities.some(
                currentIdentity => currentIdentity.email.trim().toLocaleLowerCase() === value.trim().toLocaleLowerCase()
            )
        ) {
            return 'You already have that email associated with your account';
        }
        return true;
    };

    return (
        <>
            <Controller
                control={control}
                name="orgEmail"
                defaultValue={null}
                rules={{
                    required: 'Email at Organization required',
                    validate: value => validateValue(value.email),
                }}
                render={({ onChange, ...props }) => (
                    <Autocomplete
                        freeSolo
                        autoHighlight
                        clearOnBlur
                        disablePortal
                        data-testid="email"
                        options={identities || []}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const { inputValue } = params;
                            const isExisting = options.some(option => inputValue === option.email);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    email: inputValue,
                                    newEmail: inputValue, // used for display
                                });
                            }
                            return filtered;
                        }}
                        getOptionLabel={option => option.email || ''}
                        isOptionEqualToValue={(option, value) => {
                            return option.email === value.email;
                        }}
                        renderOption={(_props, option) => (
                            <Box component="li" {..._props}>
                                {option.newEmail ? (
                                    <Typography sx={{ color: 'primary.main' }}>
                                        Add &quot;{option.newEmail}&quot;
                                    </Typography>
                                ) : (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <Typography sx={{ mr: '0.5rem' }}>{option.email} </Typography>
                                        {option.verified ? (
                                            <LabeledIcon
                                                icon={<ResolvedIcon />}
                                                label="Email validated"
                                                variant={ModalTitleVariant.resolve}
                                            />
                                        ) : (
                                            <LabeledIcon
                                                icon={<UnresolvedFilledIcon />}
                                                label="*Email needs validation"
                                                variant={ModalTitleVariant.alert}
                                            />
                                        )}
                                    </Box>
                                )}
                            </Box>
                        )}
                        renderInput={renderAutocompleteInput}
                        onChange={(e, data) => {
                            onChange(data);
                            if (onEmailChange) onEmailChange(data?.value);
                        }}
                        {...props}
                    />
                )}
            />
        </>
    );
};

EmailAutocomplete.defaultProps = {
    onEmailChange: null,
    emailAddReason: false,
    requireNewEntry: false,
    label: '*Email at Organization',
    placeholder: 'Select or add email address...',
};

export default EmailAutocomplete;
