import React, { ReactElement, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Hero from './Hero';
import WorkWithAFWERX from './WorkWithAFWERX';
import About from './AboutAFWERX';
import SuccessStories from './SuccessStories';
import Footer from './Footer';

import './LandingPage.scss';

const LandingPage = (): ReactElement => {
    const history = useHistory();
    const location = useLocation();

    const afwerxTheme = createTheme({
        palette: {
            primary: {
                main: '#0f182d',
                light: ' #4577DB',
                contrastText: '#fff',
            },
            secondary: {
                main: '#F4DD00',
                dark: '#f4dd00',
                contrastText: '#0f182d',
            },
        },
        typography: {
            fontFamily: ['Roboto', 'sans-serif'].join(','),
            body1: {
                fontSize: '16px',
            },
            subtitle1: {
                fontSize: '16px',
                fontWeight: 900,
            },
            button: {
                fontFamily: ['Roboto', 'sans-serif'].join(','),
                fontSize: '16px',
            },
        },
        components: {
            MuiLink: {
                styleOverrides: {
                    root: {
                        color: '#4577DB',
                        lineHeight: '1.5em',
                    },
                },
            },
        },
    });

    useEffect(() => {
        if (location.pathname !== '/') {
            history.push('/');
        }
    }, [history, location]);

    return (
        <ThemeProvider theme={afwerxTheme}>
            <Box className="LandingPage" sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText' }}>
                <Hero />
                <WorkWithAFWERX />
                <About />
                <SuccessStories />
                <Footer />
            </Box>
        </ThemeProvider>
    );
};

export default LandingPage;
