import { createTheme } from '@mui/material/styles';
import '@mui/x-data-grid-pro/themeAugmentation';
import '@mui/lab/themeAugmentation';

declare module '@mui/material/styles' {
    interface Theme {
        panelBorderRadius?: string;
        chipBorderRadius?: string;
    }
    interface ThemeOptions {
        panelBorderRadius?: string;
        chipBorderRadius?: string;
    }
}

const baseTheme = createTheme({
    // non-MUI theme variables
    panelBorderRadius: '10px',
    chipBorderRadius: '25px',

    palette: {
        primary: {
            light: '#5D8CEA',
            main: '#2E60C3',
            dark: '#2E60C3',
            contrastText: '#fff',
        },
        secondary: {
            main: '#008577',
            contrastText: '#fff',
        },
        error: {
            light: '#F4CEC8',
            main: '#E50101',
            contrastText: '#fff',
        },
        warning: {
            light: '#FFF6D6',
            main: '#FF9800',
        },
        info: {
            main: '#34AEEF',
        },
        success: {
            main: '#008577',
        },
        grey: {
            '50': '#F8FCFC',
            '100': '#f4f8f8',
            '200': '#F1F1F1',
            '900': '#0F182D',
        },
        text: {
            primary: '#0F182D',
        },
        divider: '#d9dde3',
        background: {
            default: '#f4f8f8',
        },
        contrastThreshold: 4.5,
    },
    typography: {
        fontFamily: '"Roboto", sans-serif',
        htmlFontSize: 16, // base font size for rem calcs is 16px
        h1: { margin: '0', fontWeight: 700, fontSize: '1.5rem' },
        h2: { margin: '0', fontWeight: 700, fontSize: '1rem' },
        h3: { fontSize: '1rem', fontWeight: 700 },
        h4: { fontSize: '0.875rem', fontWeight: 600 },
        subtitle1: { fontSize: '0.875rem' },
        body1: { fontSize: '0.875rem' },
        button: {
            textTransform: 'none',
            fontSize: '0.875rem',
        },
    },
    shape: {
        borderRadius: 4,
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

const theme = createTheme(baseTheme, {
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                :root{
                    --primary: ${baseTheme.palette.primary.main};
                    --primary-dark: ${baseTheme.palette.text.primary};
                }
                blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
                     margin: 0;
                }
                ul {
                    margin: 0;
                    padding: 0;
                },
                ol {
                    list-style: auto;
                    margin: 0 0 0 20px;
                    padding: 0;
                }
                a {
                    color: ${baseTheme.palette.primary.main};
                }
                .pagination .active a {
                    background-color: ${baseTheme.palette.primary.main};
                    border: 1px solid ${baseTheme.palette.primary.main};
                    color: ${baseTheme.palette.common.white};
                }
            `,
        },
        MuiAvatar: {
            styleOverrides: {
                colorDefault: { color: `${baseTheme.palette.text.primary}` },
            },
        },
        MuiBadge: {
            styleOverrides: {
                colorPrimary: {
                    color: `${baseTheme.palette.common.white}`,
                    background: `${baseTheme.palette.secondary.main}`,
                },
                anchorOriginTopRightRectangular: {
                    transform: 'scale(1) translate(35%, -35%)',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                endIcon: {
                    marginLeft: 0,
                },
                text: {
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                },
            },
            defaultProps: {
                disableElevation: true, // this prop disables the drop shadow on all Buttons
            },
        },
        MuiChip: {
            styleOverrides: {
                root: { margin: '0 2px 2px 0' },
            },
        },
        MuiDataGrid: {
            defaultProps: {
                showCellRightBorder: true,
                autoHeight: true,
            },
            styleOverrides: {
                root: {
                    '.MuiDataGrid-cell': {
                        background: `${baseTheme.palette.common.white}`,
                        border: '1px',
                        borderColor: `${baseTheme.palette.divider} !important`,
                        // important is to override a transparent border setting we were not able to override
                        borderStyle: 'solid',
                        padding: '0px',
                    },
                    '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    },

                    '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
                        outline: 'none',
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    component: 'div',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: '0.875rem',
                    fontWeight: 600,
                    color: `${baseTheme.palette.text.primary}`,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: '5px',
                    flexDirection: 'column',
                    alignItems: 'center',
                },
            },
        },
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            },
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    zIndex: 9999, // can't think of a reason you wouldn't ever want to have your menu on top
                },
            },
        },
        MuiMenuItem: {
            // For ListItem, change this to MuiListItem
            styleOverrides: {
                root: {
                    '&$selected': {
                        backgroundColor: `${baseTheme.palette.common.white}`,
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: `${baseTheme.palette.primary.light}`,
                },
                colorSecondary: {
                    '&$checked': {
                        color: `${baseTheme.palette.primary.light}`,
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                outlined: {
                    backgroundColor: `${baseTheme.palette.common.white}`,
                    size: 'small',
                },
            },
        },
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    zIndex: 10000,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '0.875rem',
                    lineHeight: 1,
                    opacity: 1,
                    '&.Mui-selected': {
                        fontWeight: 600,
                    },
                    '&::after': {
                        borderRight: '1px solid',
                        borderColor: `${baseTheme.palette.divider}`,
                        content: "''",
                        height: '19px',
                        position: 'absolute',
                        right: '0',
                    },

                    '&:last-child::after': {
                        border: 0,
                    },
                },
            },
        },
        MuiTabPanel: {
            styleOverrides: {
                root: {
                    padding: '4px',
                },
            },
        },
        MuiTabs: {
            defaultProps: {
                indicatorColor: 'secondary',
                textColor: 'inherit',
            },
            styleOverrides: {
                indicator: {
                    height: '3px',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    backgroundColor: `${baseTheme.palette.common.white}`,
                    size: 'small',
                },
            },
        },
    },
});

export default theme;
