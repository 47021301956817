import React, { ReactElement, ReactNode } from 'react';

import './MobileNavItem.scss';

export interface MobileNavContainerProps {
    icon?: any;
    customIcon?: string;
    title: string | ReactNode;
    children: ReactElement | ReactElement[];
}
export function MobileNavContainer({ icon, customIcon, title, children }: MobileNavContainerProps): ReactElement {
    return (
        <div className={`mobile-nav-item-container  with-children ${customIcon ? 'custom-icons' : ''}`}>
            <div className="mobile-nav-item with-content">
                {icon ? (
                    <>{icon}</>
                ) : (
                    <>
                        {customIcon && (
                            <div className="custom-icon">
                                <img src={`/custom-icons/${customIcon}`} alt={customIcon} />
                            </div>
                        )}
                    </>
                )}
                <span className="title-no-button">{title}</span>
            </div>
            <div className="nav-item-content">{children}</div>
        </div>
    );
}
MobileNavContainer.defaultProps = {
    icon: undefined,
    customIcon: undefined,
};
