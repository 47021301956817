import { StoreDataProps } from 'techgauge/dist/store/actions/TrlMrlCalculator';
import { Category, ExternalLinkFragment, GetTechnologyByIdQuery } from 'typings/generated';
import {
    AboutTechnology,
    Document,
    ReadinessLevels,
    SelectedPointOfContact,
    TechnologyCopyright,
    TechnologyDocumentType,
    TechnologyPatent,
    TechnologyTrademark,
} from '../reducers/Technology';

export const RESET_TECHNOLOGY = 'RESET_TECHNOLOGY';
export const SET_ABOUT = 'SET_ABOUT';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_CHILD_CATEGORIES = 'SET_CHILD_CATEGORIES';
export const SET_POC = 'SET_POC';
export const SET_READINESS_LEVELS = 'SET_READINESS_LEVELS';
export const SET_TECHNOLOGY_FILE = 'SET_TECHNOLOGY_FILE';
export const REMOVE_TECHNOLOGY_FILE = 'REMOVE_TECHNOLOGY_FILE';
export const SET_ADDITIONAL_TECHNOLOGY_FILES = 'SET_ADDITIONAL_TECHNOLOGY_FILES';
export const ADD_LINK = 'ADD_LINK';
export const REMOVE_LINK = 'REMOVE_LINK';
export const INITIALIZE_TECHNOLOGY = 'INITIALIZE_TECHNOLOGY';
export const ADD_PATENT = 'ADD_PATENT';
export const REMOVE_PATENT = 'REMOVE_PATENT';
export const ADD_COPYRIGHT = 'ADD_COPYRIGHT';
export const REMOVE_COPYRIGHT = 'REMOVE_COPYRIGHT';
export const ADD_TRADEMARK = 'ADD_TRADEMARK';
export const REMOVE_TRADEMARK = 'REMOVE_TRADEMARK';
export const SET_CALCULATOR_STORE = 'SET_CALCULATOR_STORE';
export const SET_TECHNOLOGY_ORGANIZATION = 'SET_TECHNOLOGY_ORGANIZATION';

export const resetTechnology = (): any => ({
    type: RESET_TECHNOLOGY,
});

export const setAbout = (payload: AboutTechnology): any => ({
    type: SET_ABOUT,
    payload,
});

export const setCategories = (payload: Category[]): any => ({
    type: SET_CATEGORIES,
    payload,
});

export const setPOCs = (payload: SelectedPointOfContact[]): any => ({
    type: SET_POC,
    payload,
});

export const setReadinessLevels = (payload: ReadinessLevels): any => ({
    type: SET_READINESS_LEVELS,
    payload,
});

export const setTechnologyFile = (payload: Document): any => ({
    type: SET_TECHNOLOGY_FILE,
    payload,
});

export const removeTechnologyFile = (payload: TechnologyDocumentType): any => ({
    type: REMOVE_TECHNOLOGY_FILE,
    payload,
});

export const setAdditionalTechnologyFiles = (payload: Document[]): any => ({
    type: SET_ADDITIONAL_TECHNOLOGY_FILES,
    payload,
});

export const addExternalLink = (payload: ExternalLinkFragment): any => ({
    type: ADD_LINK,
    payload,
});

export const removeExternalLink = (payload: ExternalLinkFragment): any => ({
    type: REMOVE_LINK,
    payload,
});

export const initializeTechnology = (payload: GetTechnologyByIdQuery): any => ({
    type: INITIALIZE_TECHNOLOGY,
    payload,
});

export const addPatent = (payload: TechnologyPatent): any => ({
    type: ADD_PATENT,
    payload,
});

export const removePatent = (payload: TechnologyPatent): any => ({
    type: REMOVE_PATENT,
    payload,
});

export const addCopyright = (payload: TechnologyCopyright): any => ({
    type: ADD_COPYRIGHT,
    payload,
});

export const removeCopyright = (payload: TechnologyCopyright): any => ({
    type: REMOVE_COPYRIGHT,
    payload,
});

export const addTrademark = (payload: TechnologyTrademark): any => ({
    type: ADD_TRADEMARK,
    payload,
});

export const removeTrademark = (payload: TechnologyTrademark): any => ({
    type: REMOVE_TRADEMARK,
    payload,
});

export const setCalculatorStore = (payload: StoreDataProps): any => ({
    type: SET_CALCULATOR_STORE,
    payload,
});

export const setTechnologyOrganization = (payload: {
    organizationId: string;
    organizationName: string;
    allowVisibility: boolean;
}): any => ({
    type: SET_TECHNOLOGY_ORGANIZATION,
    payload,
});
