import React, { ReactElement } from 'react';

import { Box, Button } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';

import { linkTo } from 'components/providers/ReactRouter';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootStore } from 'state/store/reducers';
import { useLogoutMutation } from 'typings/generated';
import NavIcon from './NavIcon';

interface ProfileNavIconProps {
    overlayOn: boolean;
    opened: () => void;
    closed: () => void;
}

export const ProfileNavIcon = ({ opened, closed, overlayOn }: ProfileNavIconProps): ReactElement => {
    const history = useHistory();
    const { keycloak } = useKeycloak();
    const user = useSelector((storeState: RootStore) => storeState.User);

    const [logout] = useLogoutMutation();

    const signOutHandler = async () => {
        logout();
        keycloak.logout({
            redirectUri: window.location.origin,
        }); // don't use await - this rediects back to the login page just fine
    };

    return (
        <Box sx={{ zIndex: 999 }}>
            <NavIcon iconTitle="Profile" icon="profile" onOpen={opened} overlayOn={overlayOn}>
                {{
                    title: (
                        <>
                            {user && (
                                <>
                                    {overlayOn}
                                    <div className="heading">{user.name}</div>
                                    <div className="sub-heading">{user.emailAddress}</div>
                                </>
                            )}
                        </>
                    ),
                    content: (
                        <>
                            <Box sx={{ my: '0.5rem' }}>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        closed();
                                        history.push(linkTo.profile.view.root(user?.id));
                                    }}
                                >
                                    View Profile
                                </Button>
                            </Box>
                            {user && user.primaryAffiliation && !user.primaryAffiliation.approvalPending && (
                                <Box sx={{ my: '0.5rem' }}>
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            closed();

                                            history.push(
                                                user && user.isGovernment
                                                    ? `/organization/${user?.primaryAffiliation?.organization?.id}/about`
                                                    : `/company/${user?.primaryAffiliation?.organization?.id}/about`
                                            );
                                        }}
                                    >
                                        {user && user.isGovernment ? 'View Organization' : 'View Company'}
                                    </Button>
                                </Box>
                            )}

                            <Box sx={{ my: '0.5rem' }}>
                                <Button color="primary" onClick={() => signOutHandler()}>
                                    Logout
                                </Button>
                            </Box>
                        </>
                    ),
                }}
            </NavIcon>
        </Box>
    );
};

export default ProfileNavIcon;
