import React, { ReactElement, useEffect, useRef, useState } from 'react';

import { Box, Button, Grid, IconButton, useMediaQuery } from '@mui/material';

import { CollapseIcon } from 'assets/icons';
import ButtonGroup from 'components/common/button-group/ButtonGroup';
import CategoryPicker from 'components/common/categories/CategoryPicker';
import RouteLeavingGuard from 'components/common/guard/RouteLeavingGuard';
import Panel, { CaretStyles, PanelStyles } from 'components/common/panel/Panel';
import StepButton from 'components/common/step-button/StepButton';
import DiscussionDetailsEditor from 'pages/add-discussion/DiscussionDetailsEditor';
import Page from 'pages/Page';
import ProfilePageLayout from 'pages/ProfilePageLayout';
import { FormProvider, useForm } from 'react-hook-form';
import LoadingOverlay from 'react-loading-overlay';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Category, DiscussionInput, useGetCategoriesByCommunityQuery } from 'typings/generated';
import { isRichTextEmpty, setRaw } from 'utilities/utils';
import WithSticky from '../sticky/WithSticky';
import DeleteDiscussion from './DeleteDiscussion';

interface DiscussionEditorProps {
    discussion: DiscussionInput;
    publish: (forum: DiscussionInput) => Promise<void>;
    loading: boolean;
    categories: Category[];
    communityId?: string;
}

export enum EditDiscussionSteps {
    DETAILS = 0,
    CATEGORIES = 1,
    DELETE = 2,
}

export const DiscussionEditor = ({ discussion, publish, loading, categories }: DiscussionEditorProps): ReactElement => {
    const history = useHistory();
    const [valid, setValid] = useState(false); // determines if all the steps are valid for the publish button
    const [dirty, setDirty] = useState(false); // todo...
    const discussionDetailsRef = useRef<HTMLDivElement>(null);
    const discussionCategoriesRef = useRef<HTMLDivElement>(null);
    const discussionDeleteRef = useRef<HTMLDivElement>(null);
    const [selectedCategories, setSelectedCategories] = useState(discussion?.categories || []);

    const isDesktop = useMediaQuery('(min-width:1024px)');
    const isEditMode = !!discussion?.discussionId;

    const methods = useForm<DiscussionInput>({
        mode: 'onChange',
        defaultValues: {
            communityId: discussion?.communityId,
            isPrivate: discussion?.isPrivate, // Other logic in DiscussionEditor for initial state
            categories: selectedCategories,
        },
    });

    const { handleSubmit, formState, reset, getValues, errors, watch } = methods;
    const isPrivateFormValue = watch('isPrivate');
    const selectedCommunityId = watch('communityId');
    const { data: communityCategories, loading: loadingCategories } = useGetCategoriesByCommunityQuery({
        variables: { communityId: selectedCommunityId || '' },
        skip: !selectedCommunityId,
    });

    // Handle add and edit modes
    const isPrivate = isPrivateFormValue || discussion?.isPrivate;
    const showCategories = !isPrivate || communityCategories?.getCategoriesByCommunity?.items?.length;
    const categoriesInUse =
        selectedCommunityId && isPrivate
            ? (communityCategories?.getCategoriesByCommunity.items as Category[])
            : categories;

    const { isDirty, isValid } = formState;

    useEffect(() => {
        if (!discussion.discussionId) {
            setDirty(isDirty);
            setValid(isValid);
        }
    }, [isDirty, isValid, discussion.discussionId]);

    useEffect(() => {
        if (discussion?.discussionId) {
            setDirty(false);
        }
    }, [discussion?.discussionId]);

    const onSubmit = async (values: DiscussionInput) => {
        setDirty(false);
        await publish({
            ...discussion,
            ...values,
            categories: selectedCategories.filter(
                x =>
                    categoriesInUse.some(potentialCat => potentialCat.id === x) ||
                    categoriesInUse.some(
                        cat => cat.children && cat.children.some(potentialCat => potentialCat.id === x)
                    )
            ),
            communityId: values.communityId,
        });
        reset();
    };

    const revertChanges = (): void => {
        reset();
        setDirty(false);
    };

    const scrollTo = (step: any): void => {
        switch (step) {
            case EditDiscussionSteps.DETAILS: {
                discussionDetailsRef.current?.scrollIntoView();
                break;
            }
            case EditDiscussionSteps.CATEGORIES: {
                discussionCategoriesRef.current?.scrollIntoView();
                break;
            }
            case EditDiscussionSteps.DELETE: {
                discussionDeleteRef.current?.scrollIntoView();
                break;
            }
            default:
                discussionDetailsRef.current?.scrollIntoView();
                break;
        }
    };

    return (
        <>
            <RouteLeavingGuard
                title="Are you sure you want to leave?"
                content={<p>This discussion will not be published and progress will not be saved.</p>}
                // When should shouldBlockNavigation be invoked,
                // simply passing a boolean
                // (same as "when" prop of Prompt of React-Router)
                when={dirty}
                // Navigate function
                navigate={path => {
                    // console.log(path);
                    history.push(path);
                }}
                shouldBlockNavigation={(): boolean => {
                    return dirty;
                }}
                onConfirmLeave={() => revertChanges()}
            />
            <Page
                title={
                    <>
                        <IconButton
                            component={RouterLink}
                            to=""
                            onClick={() => history.goBack()}
                            className="back-button"
                            target="_self"
                            sx={{ fontWeight: 400 }}
                        >
                            <CollapseIcon data-testid="back-icon" color="primary" />
                            Back
                        </IconButton>
                        {discussion?.discussionId ? `Edit ${discussion.title}` : 'Start Discussion'}
                    </>
                }
            >
                <LoadingOverlay active={loading || loadingCategories}>
                    <FormProvider {...methods}>
                        <form data-testid="discussionEditorForm" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                            <ProfilePageLayout responsiveRightTopOrder>
                                {{
                                    leftSide: (
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} ref={discussionDetailsRef}>
                                                <DiscussionDetailsEditor
                                                    discussion={discussion}
                                                    markFormAsDirty={() => setDirty(true)}
                                                    isEditMode={isEditMode}
                                                    isDirty={dirty}
                                                />
                                            </Grid>
                                            {showCategories && (
                                                <Grid item xs={12} ref={discussionCategoriesRef}>
                                                    <CategoryPicker
                                                        categories={categoriesInUse}
                                                        loading={loading}
                                                        subTitle="Select categories relevant to this discussion to help others find and engage with it!"
                                                        selectedCategoryIds={selectedCategories}
                                                        onSelectedCategoriesChanges={c => {
                                                            setSelectedCategories(c.map(category => category.id));
                                                        }}
                                                        markFormAsDirty={() => setDirty(true)}
                                                        title="Categories (optional)"
                                                    />
                                                </Grid>
                                            )}
                                            {!discussion?.discussionId ? (
                                                <Grid item xs={12}>
                                                    <ButtonGroup justify="flex-end">
                                                        <Button
                                                            type="submit"
                                                            data-testid="publish-button"
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={
                                                                !valid || !dirty || getValues('content') === setRaw('')
                                                            }
                                                        >
                                                            Publish
                                                        </Button>
                                                    </ButtonGroup>
                                                </Grid>
                                            ) : null}
                                            {discussion?.discussionId ? (
                                                <Grid item xs={12} ref={discussionDeleteRef}>
                                                    <DeleteDiscussion discussionId={discussion?.discussionId} />
                                                </Grid>
                                            ) : null}
                                        </Grid>
                                    ),
                                    rightSide: (
                                        <>
                                            {discussion?.discussionId ? (
                                                <WithSticky>
                                                    <Panel
                                                        title="Edit Discussion"
                                                        panelStyle={
                                                            isDesktop ? PanelStyles.NoCollapse : PanelStyles.Default
                                                        }
                                                        caretStyle={isDesktop ? CaretStyles.None : CaretStyles.Normal}
                                                    >
                                                        <div>
                                                            <StepButton
                                                                testid="details-step-button"
                                                                title="*Discussion Details"
                                                                step={EditDiscussionSteps.DETAILS}
                                                                onSelected={(): void =>
                                                                    scrollTo(EditDiscussionSteps.DETAILS)
                                                                }
                                                                inValid={errors && Object.keys(errors).length > 0}
                                                            />
                                                            {showCategories ? (
                                                                <StepButton
                                                                    testid="categories-step-button"
                                                                    title="Categories (optional)"
                                                                    step={EditDiscussionSteps.CATEGORIES}
                                                                    onSelected={(): void =>
                                                                        scrollTo(EditDiscussionSteps.CATEGORIES)
                                                                    }
                                                                />
                                                            ) : null}
                                                            <StepButton
                                                                testid="remove-step-button"
                                                                title="Remove Discussion"
                                                                step={EditDiscussionSteps.DELETE}
                                                                onSelected={(): void =>
                                                                    scrollTo(EditDiscussionSteps.DELETE)
                                                                }
                                                                // inValid={errors && Object.keys(errors).length > 0}
                                                            />
                                                        </div>
                                                    </Panel>
                                                    <Panel panelStyle={PanelStyles.NoTitle}>
                                                        <ButtonGroup justify="space-between">
                                                            <Button
                                                                variant="outlined"
                                                                color="primary"
                                                                disabled={!valid && !dirty}
                                                                onClick={() => revertChanges()}
                                                            >
                                                                Revert Changes
                                                            </Button>
                                                            <Button
                                                                type="submit"
                                                                data-testid="save-discussion"
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={
                                                                    (!valid && !dirty) ||
                                                                    isRichTextEmpty(getValues('content'))
                                                                }
                                                            >
                                                                Save Changes
                                                            </Button>
                                                        </ButtonGroup>
                                                    </Panel>

                                                    <Box sx={{ m: '0.75rem' }} className="error-summary_message">
                                                        {Object.keys(errors).includes('title') && (
                                                            <Box
                                                                sx={{
                                                                    m: '0.75rem',
                                                                }}
                                                                className="error-summary_message"
                                                            >
                                                                *Discussion Title Required.
                                                            </Box>
                                                        )}
                                                    </Box>
                                                    <Box sx={{ m: '0.75rem' }} className="error-summary_message">
                                                        {Object.keys(errors).includes('content') && (
                                                            <Box
                                                                sx={{
                                                                    m: '0.75rem',
                                                                }}
                                                                className="error-summary_message"
                                                            >
                                                                *Discussion Content Required.
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </WithSticky>
                                            ) : (
                                                ''
                                            )}
                                        </>
                                    ),
                                }}
                            </ProfilePageLayout>
                        </form>
                    </FormProvider>
                </LoadingOverlay>
            </Page>
        </>
    );
};

DiscussionEditor.defaultProps = {
    communityId: '',
};

export default DiscussionEditor;
