import React, { ReactElement } from 'react';
import { Box, Container, Link, Typography } from '@mui/material';
import afLogo from './assets/aflogo_white.png';
import afrlLogo from './assets/AFRL-logo.png';
import afwerxLogo from './assets/AFWERX-Solid-White.png';
import spaceforceLogo from './assets/Spaceforce-logo.png';
import facebook from './assets/social-media/wFacebook.png';
import instagram from './assets/social-media/wInstagram.png';
import linkedin from './assets/social-media/wLinkedin.png';
import twitter from './assets/social-media/wTwitter.png';
import youtube from './assets/social-media/wYoutube.png';

const Footer = (): ReactElement => {
    return (
        <Box sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText' }}>
            <Container
                maxWidth="md"
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    marginBottom: '30px',
                    paddingTop: '30px',
                    alignItems: 'center',
                }}
            >
                <Link href="https://www.af.mil/" target="_blank" rel="noopener" sx={{ justifySelf: 'center' }}>
                    <Box
                        component="img"
                        src={afLogo}
                        alt="Air Force"
                        sx={{ height: { xs: '50px', md: '80px' }, width: { xs: '56px', md: '90px' } }}
                    />
                </Link>
                <Link href="https://www.spaceforce.mil/" target="_blank" rel="noopener" sx={{ justifySelf: 'center' }}>
                    <Box
                        component="img"
                        src={spaceforceLogo}
                        alt="Space Force"
                        sx={{ height: { xs: '50px', md: '80px' }, width: { xs: '34px', md: '54px' } }}
                    />
                </Link>
                <Link href="https://www.afrl.af.mil/" target="_blank" rel="noopener" sx={{ justifySelf: 'center' }}>
                    <Box
                        component="img"
                        src={afrlLogo}
                        alt="Air Force Research Laboratory"
                        sx={{ height: { xs: '20px', md: '30px' }, width: { xs: '116px', md: '175px' } }}
                    />
                </Link>
                <Link href="https://www.afwerx.com/" target="_blank" rel="noopener" sx={{ justifySelf: 'center' }}>
                    <Box
                        component="img"
                        src={afwerxLogo}
                        alt="AFWERX Homepage"
                        sx={{ height: { xs: '50px', md: '80px' }, width: { xs: '50px', md: '80px' } }}
                    />
                </Link>
            </Container>
            <Container
                maxWidth={false}
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(5, 1fr)',
                    mt: '30px',
                    alignItems: 'center',
                    maxWidth: '300px',
                }}
            >
                <Link
                    href="https://www.facebook.com/AFWERX"
                    target="_blank"
                    rel="noopener"
                    sx={{ justifySelf: 'center' }}
                >
                    <Box component="img" src={facebook} alt="Find AFWERX on Facebook" width="30px" />
                </Link>
                <Link href="https://twitter.com/AFWERX" target="_blank" rel="noopener" sx={{ justifySelf: 'center' }}>
                    <Box component="img" src={twitter} alt="Find AFWERX on Twitter" width="30px" />
                </Link>
                <Link
                    href="https://www.youtube.com/channel/UC1B5dVYEgCDIjXVlEDiN0QQ?"
                    target="_blank"
                    rel="noopener"
                    sx={{ justifySelf: 'center' }}
                >
                    <Box component="img" src={youtube} alt="Find AFWERX on YouTube" width="30px" />
                </Link>
                <Link
                    href="https://www.linkedin.com/company/afwerx-usaf/"
                    target="_blank"
                    rel="noopener"
                    sx={{ justifySelf: 'center' }}
                >
                    <Box component="img" src={linkedin} alt="Find AFWERX on LinkedIn" width="30px" />
                </Link>
                <Link
                    href="https://www.instagram.com/afwerx/"
                    target="_blank"
                    rel="noopener"
                    sx={{ justifySelf: 'center' }}
                >
                    <Box component="img" src={instagram} alt="Find AFWERX on Instagram" width="30px" />
                </Link>
            </Container>
            <Container
                maxWidth="md"
                sx={{
                    textAlign: 'center',
                    mx: 'auto',
                    my: '1em',
                    fontSize: '16px',
                    lineHeight: '24px',
                }}
            >
                <Container maxWidth="xs">
                    <Link
                        href="https://afwerx.com/disclaimer"
                        target="_blank"
                        rel="noopener"
                        sx={{ color: 'primary.contrastText', fontWeight: '400' }}
                    >
                        Disclaimer
                    </Link>
                    <Box component="span" sx={{ mx: '5px' }}>
                        |
                    </Box>
                    <Link
                        href="https://afwerx.com/contact/"
                        target="_blank"
                        rel="noopener"
                        sx={{ color: 'primary.contrastText', fontWeight: '400' }}
                    >
                        Contact
                    </Link>
                </Container>
                <Container maxWidth="xs" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography>Media Inquiries</Typography>
                    <Link
                        href="mailto:afrl.pa.inquiry@us.af.mil"
                        target="_blank"
                        rel="noopener"
                        sx={{ color: 'primary.contrastText', fontWeight: '600', ml: '5px' }}
                    >
                        afrl.pa.inquiry@us.af.mil
                    </Link>
                </Container>
                <Typography>
                    &copy; {new Date().getFullYear()} Air Force Research Laboratory All Rights Reserved.
                </Typography>
            </Container>
        </Box>
    );
};

export default Footer;
