import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';

// import Media from 'react-media';
import { useSelector } from 'react-redux';
import { InternalLink } from 'components/common/links/Links';
import { RootStore } from 'state/store/reducers';

import './Header.scss';
import { BlockIcon } from 'assets/icons';

const useStyles = makeStyles({
    headerItem: {
        margin: '5px',
    },
});

const BlockHeader = (): any => {
    const [blockLink, setBlockLink] = useState('');
    const [blockType, setBlockType] = useState('');
    const block = useSelector((storeState: RootStore) => storeState.Block);
    const classes = useStyles();

    useEffect(() => {
        if (block.blockLink) {
            setBlockLink(block.blockLink);
        }
        if (block.blockType) {
            setBlockType(block.blockType);
        }
    }, [block.blockLink, block.blockType]);

    const headerText = (type: string): string => {
        if (type === 'Organization') {
            return 'Blocked companies are removed from AFWERX Portal, along with any employees and content they have contributed.';
        }
        if (type === 'User') {
            return 'Blocked members are removed from AFWERX Portal, along with any content they have contributed.';
        }
        return 'Blocked content is not visible to others. Admin may restore if issues are resolved.';
    };

    return (
        <div>
            {block.blockLink && (
                <div className="desktop">
                    <header role="banner" className="main-head block">
                        <BlockIcon className={classes.headerItem} />
                        <h1 className={classes.headerItem}>{headerText(blockType)}</h1>
                        <h1 className={classes.headerItem}>
                            <InternalLink to={blockLink}> See Block</InternalLink>
                        </h1>
                    </header>
                </div>
            )}
        </div>
    );
};

export default BlockHeader;
