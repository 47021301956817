/* eslint-disable no-restricted-imports */

import { PublishedWithChanges, Report } from '@mui/icons-material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AddIcon from '@mui/icons-material/Add';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import BlockIcon from '@mui/icons-material/Block';
import BusinessIcon from '@mui/icons-material/Business';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import Campaign from '@mui/icons-material/Campaign';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClearIcon from '@mui/icons-material/Clear';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import DraftsIcon from '@mui/icons-material/Drafts';
import EditIcon from '@mui/icons-material/Edit';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Error from '@mui/icons-material/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FacebookIcon from '@mui/icons-material/Facebook';
import FeedIcon from '@mui/icons-material/Feed';
import FlagIcon from '@mui/icons-material/Flag';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import ForumIcon from '@mui/icons-material/Forum';
import GavelIcon from '@mui/icons-material/Gavel';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import GroupsIcon from '@mui/icons-material/Groups';
import HandshakeIcon from '@mui/icons-material/Handshake';
import HomeIcon from '@mui/icons-material/Home';
import HubIcon from '@mui/icons-material/Hub';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ListAlt from '@mui/icons-material/ListAlt';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlined from '@mui/icons-material/PushPinOutlined';
import RuleIcon from '@mui/icons-material/Rule';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import ShareIcon from '@mui/icons-material/Share';
import SlideshowOutlinedIcon from '@mui/icons-material/SlideshowOutlined';
import SpeakerNotesOffOutlinedIcon from '@mui/icons-material/SpeakerNotesOffOutlined';
import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';
import FollowingIcon from '@mui/icons-material/Star';
import FollowIcon from '@mui/icons-material/StarBorderOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import VerifiedIcon from '@mui/icons-material/Verified';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import YoutubeIcon from '@mui/icons-material/YouTube';

// import HighlightOffIcon from '@mui/icons-material/HighlightOff'; // todo this was in SearchTextInput for clear - do we need both clears?

export {
    HandshakeIcon,
    AddIcon,
    BlockIcon,
    HomeIcon,
    ArrowDownwardIcon as DownArrowIcon,
    ArrowDropDownIcon,
    ArrowUpwardIcon as UpArrowIcon,
    PersonIcon,
    ClearIcon,
    SearchIcon,
    FollowIcon,
    FollowingIcon,
    DeleteIcon,
    SendIcon,
    FacebookIcon,
    TwitterIcon,
    YoutubeIcon,
    LinkedInIcon,
    InstagramIcon,
    ShareIcon,
    RuleIcon as OnboardingAdminIcon,
    ErrorRoundedIcon as AlertIcon,
    ContentCopyIcon as CopyIcon,
    GroupRemoveIcon as LeaveOrganizationIcon,
    MenuIcon as HamburgerIcon,
    SpeakerNotesOffOutlinedIcon as MuteIcon,
    SpeakerNotesOutlinedIcon as UnMuteIcon,
    ArchiveOutlinedIcon as ArchiveIcon,
    LaunchOutlinedIcon as PopoutIcon,
    VisibilityOffIcon as UnreadIcon,
    ExpandLessIcon as CollapseUpIcon,
    ExpandMoreIcon as ExpandDownIcon,
    FlagIcon as FlaggedIcon,
    FlagOutlinedIcon as FlagIcon,
    BusinessIcon, // todo - this also means government? Should we consolidate?
    EditOutlinedIcon as EditIcon,
    EditIcon as EditFilledIcon,
    MoreVertIcon as KebabIcon,
    CheckCircleOutlineIcon as ResolvedIcon,
    ErrorOutlineIcon as UnresolvedIcon,
    Error as UnresolvedFilledIcon,
    PublishedWithChanges as UnblockIcon,
    Report as ReportIcon,
    ThumbUpIcon as LikedIcon,
    ThumbUpOutlinedIcon as LikeIcon,
    AttachmentOutlinedIcon as AttachmentIcon,
    CheckBoxIcon as CheckedIcon,
    CheckBoxOutlineBlankIcon as UncheckedIcon,
    VisibilityIcon as ViewsIcon,
    NoteOutlinedIcon as InfoPaperIcon,
    SlideshowOutlinedIcon as SlidesIcon,
    TableChartOutlinedIcon as QuadIcon,
    ChevronLeftIcon as CollapseIcon,
    ChevronRightIcon as ExpandIcon,
    PeopleIcon as MembersIcon,
    InfoOutlinedIcon as AboutIcon,
    InfoOutlinedIcon as InfoIcon,
    PersonAddIcon as SignUpIcon,
    PersonIcon as ProfileIcon,
    NotificationsIcon,
    LightbulbIcon as TechnologyIcon,
    EventNoteIcon as EventIcon,
    BusinessCenterIcon as CommercialIcon,
    GroupsIcon as OrganizationIcon,
    ForumIcon as DiscussionIcon,
    DraftsIcon as InviteIcon,
    FeedIcon as FeedbackIcon,
    NewspaperIcon as NewsIcon,
    AccountBalanceIcon as GovernmentIcon,
    ChatBubbleIcon as MessagesIcon,
    ExitToAppOutlinedIcon as LogoutIcon,
    GavelIcon as AdminIcon,
    GroupAddIcon as JoinOrganizationIcon,
    GroupsIcon as CreateOrganizationIcon,
    VerifiedOutlinedIcon as SecondaryOrgBadge,
    VerifiedIcon as PrimaryOrgBadge,
    VerifiedIcon,
    PersonAddIcon,
    ListAlt as OnboardingIcon,
    DownloadIcon,
    CheckCircleIcon,
    CheckCircleOutlineIcon,
    HubIcon as CommunityIcon,
    Campaign as AnnouncementIcon,
    PushPinOutlined as PinIcon,
    PushPinIcon as UnpinIcon,
};
