import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { loadState } from './middlewares';
import rootReducer from './reducers/index';

const initialState = loadState(); // get localStorage state

export interface Action {
    type: string;
    payload: any;
}

const composeEnhancers =
    process.env.NODE_ENV !== 'development' ? compose() : composeWithDevTools({ trace: true, traceLimit: 10 });

const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware()));
export default store;
