import React, { ReactElement, useEffect, useState } from 'react';

import { Box, Button, Chip, InputLabel, TextField } from '@mui/material';
import { ClearIcon } from 'assets/icons';
import theme from 'theme';

import ContentArea from '../content-area/ContentArea';

import './Chips.scss';

interface ChipProps {
    handler: (values: string[]) => void;
    reset: boolean;
}

export const EmailChips = ({ reset, handler }: ChipProps): ReactElement => {
    const [emailArray, setEmailArray] = useState<string[]>([]);
    const [inputEmail, setInputEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [resetChips, setReset] = useState(reset);

    useEffect(() => {
        if (handler) {
            handler(emailArray);
        }
    }, [emailArray, handler]);

    useEffect(() => {
        if (reset) {
            setEmailArray([]);
            setInputEmail('');
            setReset(false);
        }
    }, [resetChips, reset]);

    const isInList = (email: string): any => {
        return emailArray.includes(email);
    };

    const isEmail = (email: string): any => {
        return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
    };

    const isValid = (email: string): any => {
        let error = null;

        if (isInList(email)) {
            error = `* ${email} has already been added.`;
        }

        if (!isEmail(email)) {
            error = `* ${email} is not a valid email address.`;
        }

        if (error) {
            setEmailError(error);

            return false;
        }

        return true;
    };

    const handleAdd = (): void => {
        const separateEmails = inputEmail.split(/[,| ]/);
        separateEmails.forEach(addedEmail => addedEmail.trim());
        let newArray = emailArray;
        separateEmails.forEach(addedEmail => {
            if (addedEmail && isValid(addedEmail)) {
                newArray = [...newArray, addedEmail];
                setInputEmail('');
            }
            setEmailArray(newArray);
        });
    };

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
        setInputEmail(evt.target.value);
        setEmailError('');
    };

    const handleDelete = (item: any): void => {
        const newArray = emailArray.filter(i => i !== item);
        setEmailArray(newArray);
    };

    return (
        <div className="chips">
            <ContentArea contentFlexDirection="column" includeBottomBoxShadow={false} includePadding={false}>
                <InputLabel htmlFor="email-address" sx={{ fontSize: '1rem', mb: '0.25rem' }}>
                    Email
                </InputLabel>
                <Box className="email-input" sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
                    <TextField
                        className={`input ${emailError && 'required error'}`}
                        sx={{ flex: '1 1 0%', mb: { xs: '1.25rem', md: '0' }, mr: { md: '0.75rem' } }}
                        name="emailAddress"
                        id="email-address"
                        value={inputEmail}
                        inputProps={{
                            'data-testid': 'emailAddress',
                        }}
                        size="small"
                        variant="outlined"
                        onChange={handleChange}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        data-testid="add-email"
                        className="button-invite"
                        onClick={(): any => handleAdd()}
                        disabled={!inputEmail}
                    >
                        Add
                    </Button>
                </Box>
                {emailError && <p className="error">{emailError}</p>}
            </ContentArea>
            <ContentArea title="*Recipients">
                {emailArray.length === 0 && <div>One or more recipients required</div>}
                {emailArray.map(item => (
                    <Chip
                        key={item}
                        style={{ backgroundColor: 'white' }}
                        variant="outlined"
                        label={item}
                        size="medium"
                        onDelete={(): any => handleDelete(item)}
                        deleteIcon={
                            <div data-testid="delete-filter">
                                <ClearIcon
                                    style={{
                                        color: 'var(--white)',
                                        background: 'var(--primary)',
                                        borderRadius: theme.chipBorderRadius,
                                        width: '18.33px',
                                        height: '18.33px',
                                    }}
                                />
                            </div>
                        }
                    />
                ))}
            </ContentArea>
        </div>
    );
};

export default EmailChips;
