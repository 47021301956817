import React, { ReactElement, ReactNode, useState } from 'react';

import { Box, Button } from '@mui/material';

import { FeedbackIcon } from 'assets/icons';
import FeedbackModal from 'pages/modals/FeedbackModal';
import { useSelector } from 'react-redux';
import { RootStore } from 'state/store/reducers';
import { isUserBlocked } from 'utilities/utils';

import './Footer.scss';

export const Footer = (): ReactElement => {
    const user = useSelector((storeState: RootStore) => storeState.User);
    const [versionNumber] = useState(process.env.REACT_APP_VERSION || 'unknown');
    const [showFeedbackModal, toggleShowFeedbackModal] = useState(false);
    const [apiVersion] = useState(() => {
        try {
            // Get from local storage by key
            const item = localStorage.getItem('x-version-backend');
            // Parse stored json or if none return initialValue
            return item;
        } catch (error) {
            // If error also return initialValue
            return '';
        }
    });

    const [apiEnvironment] = useState(() => {
        try {
            // Get from local storage by key
            const item = localStorage.getItem('x-environment-backend');
            // Parse stored json or if none return initialValue
            return item;
        } catch (error) {
            // If error also return initialValue
            return '';
        }
    });

    function FeedbackButton(): ReactNode {
        return (
            <Box sx={{ display: 'flex' }}>
                <FeedbackIcon sx={{ alignSelf: 'center' }} color="inherit" />
                <Button
                    sx={{ color: 'inherit', fontSize: 'inherit', fontWeight: 'inherit' }}
                    onClick={(): any => toggleShowFeedbackModal(true)}
                >
                    Give Feedback
                </Button>
            </Box>
        );
    }

    return (
        <Box component="footer" role="contentinfo" className="footer-container" sx={{ backgroundColor: 'grey.900' }}>
            <FeedbackModal isOpen={showFeedbackModal} onClose={(): any => toggleShowFeedbackModal(false)} />
            <Box sx={{ px: '20px' }} className="footer-content">
                <div className="feedback">{!isUserBlocked(user) && FeedbackButton()}</div>
                <div className="version" title={`API Version ${apiVersion} (${apiEnvironment})`}>
                    Version {versionNumber}
                </div>
            </Box>
        </Box>
    );
};

export default Footer;
