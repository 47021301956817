import { BlockState } from '../reducers/Blocks';

export const SET_BLOCK_LINK = 'SET_BLOCK_LINK';
export const RESET_BLOCK_LINK = 'RESET_BLOCK_LINK';

export const setBlockLink = (payload: BlockState): any => ({
    type: SET_BLOCK_LINK,
    payload,
});
export const resetBlockLink = (): any => ({
    type: RESET_BLOCK_LINK,
});
