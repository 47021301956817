import React, { ReactElement } from 'react';
import { Box, Button, Card, Container, Link, Typography } from '@mui/material';
import { redirectToLogin } from 'utilities/utils';
import { useHistory } from 'react-router-dom';
import story1 from './assets/news/Sstory1.png';
import story2 from './assets/news/Sstory2.png';
import story3 from './assets/news/Sstory3.png';

const SuccessStories = (): ReactElement => {
    const history = useHistory();

    return (
        <Box
            sx={{
                backgroundColor: 'white',
                alignItems: 'center',
                justifyItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                color: 'primary.main',
            }}
        >
            <Typography variant="h2">Success Stories</Typography>
            <Container
                sx={{
                    display: 'grid',
                    gridTemplateColumns: { sm: '1fr', md: 'repeat(3, 1fr)' },
                    my: '30px',
                    width: 'auto',
                    gap: '20px',
                }}
                maxWidth="lg"
            >
                <Card
                    variant="outlined"
                    sx={{
                        height: '480px',
                        maxWidth: '340px',
                        mb: '20px',
                    }}
                >
                    <img
                        src={story1}
                        alt="U.S. airmen push a Lift Aircraft eVTOL platform onto a C-130J Super Hercules."
                    />
                    <Typography component="h3" sx={{ m: '1em' }}>
                        <Link
                            underline="hover"
                            href="https://www.defensenews.com/air/2021/03/31/us-air-force-proves-it-can-transport-a-flying-car-on-a-c-130-now-for-the-fun-part/"
                        >
                            US Air Force proves it can transport a ‘flying car’ on a C-130
                        </Link>
                    </Typography>

                    <Typography
                        sx={{
                            margin: '1em',
                        }}
                        variant="body1"
                    >
                        Could one of these electric vertical-takeoff-and-landing (eVTOL) aircraft fit into a military
                        cargo plane and be safely transported?
                    </Typography>
                </Card>
                <Card
                    variant="outlined"
                    sx={{
                        height: '480px',
                        maxWidth: '340px',
                        mb: '20px',
                    }}
                >
                    <img
                        src={story2}
                        alt="Staff Sgt. Jeremie Anderson, 9th Special Operations instructor, speaks with other members of the unit at Cannon Air Force Base, New Mexico."
                    />
                    <Typography component="h3" sx={{ m: '1em' }}>
                        <Link
                            underline="hover"
                            href=" https://www.cannon.af.mil/News/Article-Display/Article/1802548/ssgt-anderson-shined-a-light-on-a-mid-air-refueling-problem/"
                        >
                            SSgt. Anderson shined a light on a mid-air refueling problem
                        </Link>
                    </Typography>
                    <Typography
                        sx={{
                            margin: '1em',
                        }}
                        variant="body1"
                    >
                        His idea began to look at a feature of his job that wasn’t working at 100 percent.
                    </Typography>
                </Card>

                <Card
                    variant="outlined"
                    sx={{
                        height: '480px',
                        maxWidth: '340px',
                        mb: '20px',
                    }}
                >
                    <img
                        src={story3}
                        alt="Ethan Jacobs, unmanned aerial system engineer, launches a UAS during a field test Sept. 4 at Camp Bullis, Texas."
                    />
                    <Typography component="h3" sx={{ m: '1em' }}>
                        <Link
                            underline="hover"
                            href=" https://www.afimsc.af.mil/News/Article-Display/Article/2110518/afimsc-innovation-project-receives-3-million-award/"
                        >
                            AFIMSC innovation project receives $3 million award
                        </Link>
                    </Typography>
                    <Typography
                        sx={{
                            margin: '1em',
                        }}
                        variant="body1"
                    >
                        The Air Force presented a $3 million Small Business Innovation Research program award to an Air
                        Force installation and Mission Support Center innovation project.
                    </Typography>
                </Card>
            </Container>
            <Button
                variant="contained"
                color="secondary"
                sx={{
                    height: '50px',
                    mb: '50px',
                    width: '194px',
                }}
                onClick={(): any => redirectToLogin(history)}
            >
                LOGIN TODAY
            </Button>
        </Box>
    );
};

export default SuccessStories;
