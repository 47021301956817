import React, { ReactElement, ReactNode } from 'react';

import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Typography } from '@mui/material';

import { CheckedIcon, UncheckedIcon } from 'assets/icons';
import { CaretStyles, Panel, PanelStyles } from 'components/common/panel/Panel';
import LoadingOverlay from 'react-loading-overlay';
import { Category } from 'typings/generated';
import InfoDialog from '../info-dialog/InfoDialog';

export interface CategoryPickerProps {
    title: ReactNode;
    subTitle?: ReactNode;
    selectedCategoryIds: string[];
    onSelectedCategoriesChanges: (categories: Category[]) => void;
    markFormAsDirty?: () => void;
    categories: Category[];
    loading: boolean;
}

export const CategoryPicker = ({
    categories = [],
    loading,
    title,
    subTitle,
    selectedCategoryIds = [],
    onSelectedCategoriesChanges,
    markFormAsDirty,
}: CategoryPickerProps): ReactElement => {
    const selectedCategories = [
        ...categories,
        ...categories.filter(x => x.children).flatMap(x => x.children),
    ].filter(category => selectedCategoryIds.includes(category.id));

    // const selectedCategories = [...selectedParents, ...selectedParents.flatMap(x => x.children)];
    const parentFiltersChanged = async (item: Category) => {
        const children = item.children || [];
        if (selectedCategories.find(selectedCategory => selectedCategory.id === item.id)) {
            // parent removed - remove all children too...
            onSelectedCategoriesChanges(
                selectedCategories.filter(
                    selectedCategory => selectedCategory.id !== item.id && !children.includes(selectedCategory)
                )
            );
            if (markFormAsDirty) {
                markFormAsDirty();
            }
        } else {
            // parent selected - select all the children
            const newChildren: Category[] = [];
            children.forEach(child => (!selectedCategories.includes(child) ? newChildren.push(child) : ''));
            onSelectedCategoriesChanges([...selectedCategories, item, ...newChildren]);
            if (markFormAsDirty) {
                markFormAsDirty();
            }
        }
    };
    const childrenFiltersChanged = (parent: Category, item: Category): void => {
        if (selectedCategories.find(selectedCategory => selectedCategory.id === item.id)) {
            onSelectedCategoriesChanges(
                selectedCategories
                    .filter(selectedCategory => selectedCategory.id !== parent.id)
                    .filter(selectedCategory => selectedCategory.id !== item.id)
            );
            if (markFormAsDirty) {
                markFormAsDirty();
            }
        } else {
            const newCategories = [
                ...selectedCategories.filter(selectedCategory => selectedCategory.id !== parent.id), // filter out the parent if the child is selected....
                item,
            ];
            onSelectedCategoriesChanges(newCategories);
        }
        if (markFormAsDirty) {
            markFormAsDirty();
        }
    };

    // todo: evaluate this again - it has side effects - like it collapses on the user after they clear all the children
    const showExpanded = (item: Category): boolean => {
        if (selectedCategories.includes(item)) {
            return false;
        }

        return selectedCategories.some(r => item.children.includes(r));
    };
    return (
        <LoadingOverlay active={loading}>
            <Panel
                title={
                    <span>
                        {title}
                        <InfoDialog iconTestId="category-information-icon">
                            {{
                                title: <>{title}</>,
                                content: (
                                    <div data-testid="info-modal">
                                        {categories.map((category, index) => (
                                            <div key={index}>
                                                <Typography variant="h2">{category.name}</Typography>
                                                <div>
                                                    <p>{category.description}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ),
                            }}
                        </InfoDialog>
                    </span>
                }
                subTitle={subTitle}
            >
                <FormControl component="fieldset">
                    {categories &&
                        categories.map((item, index) => {
                            return (
                                <React.Fragment key={item.id}>
                                    {item.children ? (
                                        <FormGroup key={index}>
                                            <Panel
                                                key={item.id}
                                                panelStyle={PanelStyles.Tree}
                                                caretStyle={CaretStyles.Normal}
                                                expanded={showExpanded(item)}
                                                title={
                                                    <span>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    id={item.id}
                                                                    name={item.name}
                                                                    color="primary"
                                                                    icon={
                                                                        <UncheckedIcon
                                                                            data-testid="category"
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    checkedIcon={<CheckedIcon />}
                                                                    checked={selectedCategories.includes(item)}
                                                                    onChange={() => parentFiltersChanged(item)}
                                                                />
                                                            }
                                                            label={<span>{item.name}</span>}
                                                        />
                                                    </span>
                                                }
                                            >
                                                {item.children?.map(child => {
                                                    return (
                                                        <Box sx={{ mb: '0.5rem', ml: '5rem' }} key={child.id}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        id={child.id}
                                                                        name={child.name}
                                                                        color="primary"
                                                                        icon={
                                                                            <UncheckedIcon
                                                                                data-testid="category"
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        checkedIcon={<CheckedIcon />}
                                                                        checked={selectedCategories.includes(child)}
                                                                        onChange={() =>
                                                                            childrenFiltersChanged(item, child)
                                                                        }
                                                                    />
                                                                }
                                                                label={<span>{child.name}</span>}
                                                            />
                                                        </Box>
                                                    );
                                                })}
                                            </Panel>
                                        </FormGroup>
                                    ) : (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    id={item.id}
                                                    name={item.name}
                                                    color="primary"
                                                    icon={<UncheckedIcon data-testid="category" color="primary" />}
                                                    checkedIcon={<CheckedIcon />}
                                                    checked={selectedCategories.includes(item)}
                                                    onChange={() => parentFiltersChanged(item)}
                                                />
                                            }
                                            label={<span>{item.name}</span>}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        })}
                </FormControl>
            </Panel>
        </LoadingOverlay>
    );
};

CategoryPicker.defaultProps = {
    subTitle: '',
    markFormAsDirty: null,
};
export default CategoryPicker;
