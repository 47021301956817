import { CollapseUpIcon, ExpandDownIcon } from 'assets/icons';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Box, SxProps, Typography } from '@mui/material';
import theme from 'theme';

import './Panel.scss';

export enum CaretStyles {
    Normal,
    Text,
    Tree,
    None,
}

export enum PanelStyles {
    Default,
    Tree,
    Simple,
    NoCollapse,
    NoTitle,
    Sticky,
}

interface PanelProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'title'> {
    titleDecorator?: ReactElement;
    title?: ReactNode;
    children: ReactNode;
    footer?: ReactNode; // only available for non collapse atm
    subTitle?: ReactNode;
    titleDescription?: ReactNode;
    stickyContent?: ReactNode;
    onToggle?: () => void; // to let parent know search was completed
    caretStyle?: CaretStyles;
    panelStyle?: PanelStyles;
    expanded?: boolean;
    collapse?: boolean;
    useDropDown?: boolean;
    showDivider?: boolean;
    highlight?: boolean;
    alert?: boolean;
    scrollHeight?: number | null;
    sx?: SxProps;
}

export const Panel = ({
    title,
    subTitle,
    titleDescription,
    stickyContent,
    children,
    footer,
    showDivider = true,
    caretStyle = CaretStyles.None,
    panelStyle = PanelStyles.NoCollapse,
    expanded = false,
    highlight = false,
    alert = false,
    onToggle,
    scrollHeight = null,
    titleDecorator = undefined,
    ...props
}: PanelProps): ReactElement => {
    const [opened, setOpened] = useState(expanded);
    const { collapse, sx } = props;

    let component = null;
    let body = null;

    useEffect(() => {
        if (collapse !== null) {
            setOpened(false);
        }
    }, [collapse]);

    useEffect(() => {
        setOpened(expanded);
    }, [expanded]);

    const togglePanel = (): void => {
        setOpened(!opened);
        if (onToggle) {
            onToggle();
        }
    };

    switch (caretStyle) {
        case CaretStyles.Text:
            component = opened ? (
                <Typography sx={{ color: 'primary.main', fontWeight: 600 }}>Hide</Typography>
            ) : (
                <Typography sx={{ color: 'primary.main', fontWeight: 600 }}>Show All</Typography>
            );
            break;
        case CaretStyles.Normal:
            component = opened ? <CollapseUpIcon /> : <ExpandDownIcon />;
            break;
        case CaretStyles.None:
            component = <></>;
            break;
        default:
            component = <></>;
    }

    switch (panelStyle) {
        case PanelStyles.Tree:
            body = (
                <div className="simple-panel">
                    <div className={`simple-panel-heading ${opened ? 'expanded' : ''}`}>
                        <Box
                            className="simple-panel-heading_content treeview"
                            sx={{ mr: '0.75rem' }}
                            component="button"
                            type="button"
                            onClick={() => togglePanel()}
                            data-testid="panel-expand-caret"
                        >
                            {component}
                        </Box>
                        {title}
                    </div>

                    <div className={`${opened ? 'simple-panel-body show-title visible' : 'hidden'}`}>{children}</div>
                </div>
            );
            break;
        case PanelStyles.Simple:
            body = (
                <Box className="panel" data-testid="simple-panel">
                    <Box
                        // component={children ? 'button' : 'div'}
                        // type={children ? 'button' : undefined}
                        sx={{
                            width: '100%',
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: `${theme.panelBorderRadius} ${theme.panelBorderRadius} 0 0`,
                            display: 'grid',
                            rowGap: '0.25em',
                            fontWeight: 600,
                            fontSize: '1rem',
                            backgroundColor: 'common.white',
                            minHeight: '34px',
                            outline: 'none',
                            padding: '1em',
                            paddingTop: titleDecorator ? '0em' : '1em',
                            textAlign: 'left',
                            ...(!opened &&
                                !footer && {
                                    borderRadius: theme.panelBorderRadius,
                                }),
                        }}
                        onClick={() => togglePanel()}
                    >
                        {titleDecorator}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h3" sx={{ color: 'var(--primary-dark)' }}>
                                {title}
                            </Typography>
                            {component}
                        </Box>
                        {subTitle && <Box>{subTitle}</Box>}
                        {titleDescription && <Box sx={{ fontWeight: '400' }}>{titleDescription}</Box>}
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            backgroundColor: 'common.white',
                            borderLeft: '1px solid',
                            borderRight: '1px solid',
                            borderColor: 'divider',
                            borderRadius: `0 0 ${theme.panelBorderRadius} ${theme.panelBorderRadius}`,
                            px: '1em',
                            ...(footer && {
                                borderRadius: '0',
                                borderBottom: '0',
                            }),
                        }}
                    >
                        {children && <Box className={`${opened ? 'visible' : 'hidden'}`}>{children}</Box>}
                    </Box>

                    {footer && (
                        <Box
                            sx={{
                                p: '1em',
                                border: '1px solid',
                                borderColor: 'divider',
                                borderRadius: `0 0 ${theme.panelBorderRadius} ${theme.panelBorderRadius}`,
                                backgroundColor: 'common.white',
                            }}
                        >
                            {footer}
                        </Box>
                    )}
                </Box>
            );
            break;
        case PanelStyles.NoCollapse:
            body = (
                <div className="panel">
                    <div
                        className={`panel-heading panel-no-collapse-heading expanded ${
                            !showDivider ? 'panel-no-heading-divider' : ''
                        } ${highlight ? 'highlight' : ''} ${alert ? 'alert' : ''}`}
                    >
                        <div className="panel-no-collapse-heading_content">{component}</div>
                        <div className="title">{title}</div>
                        {subTitle && <div className="sub-title">{subTitle}</div>}
                    </div>

                    <Box
                        className={`panel-body show-title visible ${footer ? 'has-footer' : ''} ${
                            highlight ? 'highlight' : ''
                        } ${alert ? 'alert' : ''}`}
                        sx={{
                            ...(scrollHeight && {
                                maxHeight: scrollHeight,
                                overflowX: 'scroll',
                            }),
                        }}
                    >
                        {children}
                    </Box>
                    {footer && <div className="panel-footer">{footer}</div>}
                </div>
            );
            break;
        case PanelStyles.NoTitle:
            body = (
                <Box className="panel" sx={sx}>
                    <div
                        className={`panel-body visible no-title ${footer ? 'has-footer' : ''} ${
                            highlight ? 'highlight' : ''
                        } ${alert ? 'alert' : ''}`}
                    >
                        {children}
                    </div>
                    {footer && <div className="panel-footer">{footer}</div>}
                </Box>
            );
            break;
        case PanelStyles.Sticky:
            body = (
                <Box className="panel">
                    <Box
                        component={children ? 'button' : 'div'}
                        type={children ? 'button' : undefined}
                        sx={{
                            width: '100%',
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: `${theme.panelBorderRadius} ${theme.panelBorderRadius} 0 0`,
                            fontWeight: 600,
                            fontSize: '1rem',
                            backgroundColor: 'common.white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            minHeight: '34px',
                            outline: 'none',
                            padding: '1rem',
                        }}
                        onClick={() => togglePanel()}
                    >
                        <span className="title">
                            {title} {subTitle && <span className="sub-title">{subTitle}</span>}
                        </span>
                        <div className="panel-heading_content">{component}</div>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            backgroundColor: 'common.white',
                            borderLeft: '1px solid',
                            borderRight: '1px solid',
                            borderColor: 'divider',
                            ...(!footer && {
                                borderBottom: '1px solid',
                                borderBottomColor: 'divider',
                                borderRadius: `0 0 ${theme.panelBorderRadius} ${theme.panelBorderRadius}`,
                            }),
                        }}
                    >
                        <Box sx={{ padding: '0.5em 1em' }}>{stickyContent}</Box>
                        {children && <Box className={`${opened ? 'visible' : 'hidden'}`}>{children}</Box>}
                    </Box>

                    {footer && <div className="panel-footer">{footer}</div>}
                </Box>
            );
            break;
        default:
            body = (
                <div className="panel">
                    <Box
                        component="button"
                        type="button"
                        sx={{ width: '100%' }}
                        onClick={() => togglePanel()}
                        className={`panel-heading ${opened ? 'expanded' : ''}`}
                    >
                        <div className="panel-heading_content">{component}</div>
                        <span className="title">
                            {title} {subTitle && <span className="sub-title">{subTitle}</span>}
                        </span>
                    </Box>
                    <div className={`${opened ? 'panel-body show-title visible' : 'hidden'}`}>{children}</div>
                </div>
            );
    }

    return body;
};

export default Panel;
