import { AnyAction } from 'redux';
import { StoreDataProps } from 'techgauge/dist/store/actions/TrlMrlCalculator';
import { Category, ExternalLinkFragment, GetTechnologyByIdQuery } from 'typings/generated';
import {
    ADD_COPYRIGHT,
    ADD_LINK,
    ADD_PATENT,
    ADD_TRADEMARK,
    INITIALIZE_TECHNOLOGY,
    REMOVE_COPYRIGHT,
    REMOVE_LINK,
    REMOVE_PATENT,
    REMOVE_TECHNOLOGY_FILE,
    REMOVE_TRADEMARK,
    RESET_TECHNOLOGY,
    SET_ABOUT,
    SET_ADDITIONAL_TECHNOLOGY_FILES,
    SET_CALCULATOR_STORE,
    SET_CATEGORIES,
    SET_POC,
    SET_READINESS_LEVELS,
    SET_TECHNOLOGY_FILE,
    SET_TECHNOLOGY_ORGANIZATION,
} from '../actions/Technology';

export interface AboutTechnology {
    name: string;
    description: string;
    avatar?: string;
}

export interface SelectedPointOfContact {
    id: string;
    avatar: string;
    name: string;
    profession: string;
    firstName: string;
    lastName: string;
    organization: any;
}

export interface SelectedCategory {
    id: string;
    name: string;
    description: string;
}

export interface SelectedChildCategory {
    id: string;
    name: string;
    parentId: string;
}

export interface ReadinessLevels {
    trl: number;
    mrl: number;
    currentReadinessResults: any;
    dateCompleted?: Date;
}

export enum TechnologyDocumentType {
    ADDITIONAL = 0,
    BLUF = 1,
    QUADCHART = 2,
    INFOPAPER = 3,
    SLIDEDECK = 4,
}

export interface Document {
    id?: string;
    name: string;
    originalFileName?: string;
    file?: File;
    type: TechnologyDocumentType;
    isNewFile: boolean;
}

export interface TechnologyPatent {
    name: string;
}

export interface TechnologyCopyright {
    name: string;
}

export interface TechnologyTrademark {
    name: string;
}
export interface TechnologyState {
    id?: string;
    initialized: boolean;
    aboutTechnology: AboutTechnology;
    categories: Category[];
    selectedPointOfContacts: SelectedPointOfContact[];
    readinessLevels?: ReadinessLevels;
    technologyDocuments: Document[];
    externalLinks: ExternalLinkFragment[];
    patents: TechnologyPatent[];
    copyrights: TechnologyCopyright[];
    trademarks: TechnologyTrademark[];
    calculatorStoreData: StoreDataProps;
    organizationId?: string;
    organizationName?: string;
    allowVisibility: boolean;
}

const initialState: TechnologyState = {
    initialized: false,
    aboutTechnology: { name: '', description: '' },
    categories: [],
    selectedPointOfContacts: [],
    technologyDocuments: [],
    externalLinks: [],
    patents: [],
    copyrights: [],
    trademarks: [],
    calculatorStoreData: {},
    organizationId: '',
    organizationName: '',
    allowVisibility: false,
};

export default (state = initialState, action: AnyAction): TechnologyState => {
    const { type } = action;
    switch (type) {
        case SET_ABOUT: {
            return { ...state, aboutTechnology: action.payload as AboutTechnology };
        }
        case SET_CATEGORIES: {
            const selectedCategories = action.payload as Category[];
            return {
                ...state,
                categories: [...selectedCategories],
            };
        }
        case SET_POC: {
            return { ...state, selectedPointOfContacts: [...(action.payload as SelectedPointOfContact[])] };
        }
        case SET_READINESS_LEVELS: {
            return {
                ...state,
                readinessLevels: action.payload as ReadinessLevels,
            };
        }
        case SET_TECHNOLOGY_FILE: {
            const techFile = action.payload as Document;
            techFile.isNewFile = true;
            const existingFiles = state.technologyDocuments.filter(d => d.type !== techFile.type);
            return {
                ...state,
                technologyDocuments: [...existingFiles, techFile],
            };
        }
        case REMOVE_TECHNOLOGY_FILE: {
            const docType = action.payload as TechnologyDocumentType;
            const existingFiles = state.technologyDocuments.filter(d => d.type !== docType);
            return {
                ...state,
                technologyDocuments: [...existingFiles],
            };
        }
        case SET_ADDITIONAL_TECHNOLOGY_FILES: {
            const nonAdditionalFiles = state.technologyDocuments.filter(
                d => d.type !== TechnologyDocumentType.ADDITIONAL
            );
            return {
                ...state,
                technologyDocuments: [...nonAdditionalFiles, ...(action.payload as Document[])],
            };
        }
        case SET_TECHNOLOGY_ORGANIZATION: {
            const data = action.payload as {
                organizationId: string;
                organizationName: string;
                allowVisibility: boolean;
            };
            return {
                ...state,
                organizationId: data ? data.organizationId : '',
                organizationName: data ? data.organizationName : '',
                allowVisibility: data && data.allowVisibility,
            };
        }
        case ADD_LINK: {
            return { ...state, externalLinks: [...state.externalLinks, action.payload as ExternalLinkFragment] };
        }
        case REMOVE_LINK: {
            return { ...state, externalLinks: state.externalLinks.filter(l => l !== action.payload) };
        }
        case ADD_PATENT: {
            return { ...state, patents: [...state.patents, action.payload as TechnologyPatent] };
        }
        case REMOVE_PATENT: {
            return { ...state, patents: state.patents.filter(l => l !== action.payload) };
        }
        case ADD_COPYRIGHT: {
            return { ...state, copyrights: [...state.copyrights, action.payload as TechnologyCopyright] };
        }
        case REMOVE_COPYRIGHT: {
            return { ...state, copyrights: state.copyrights.filter(l => l !== action.payload) };
        }
        case ADD_TRADEMARK: {
            return { ...state, trademarks: [...state.trademarks, action.payload as TechnologyTrademark] };
        }
        case REMOVE_TRADEMARK: {
            return { ...state, trademarks: state.trademarks.filter(l => l !== action.payload) };
        }
        case RESET_TECHNOLOGY: {
            return initialState;
        }
        case INITIALIZE_TECHNOLOGY: {
            const payload = action.payload as GetTechnologyByIdQuery;
            return {
                ...state,
                id: payload.technology.id,
                aboutTechnology: {
                    name: payload.technology.name,
                    description: payload.technology.description,
                    avatar: payload.technology.profileImagePath,
                },
                selectedPointOfContacts:
                    (payload.technology.pointOfContacts &&
                        payload.technology.pointOfContacts?.map(poc => {
                            return {
                                id: poc.id,
                                avatar: poc.avatar || '',
                                name: poc.name,
                                firstName: poc.firstName,
                                lastName: poc.lastName,
                                organization: poc.organization,
                                profession: poc.profession || '',
                            };
                        })) ||
                    [],
                categories: payload.technology.categories.map(x => x as Category),
                readinessLevels: {
                    trl: payload.technology.techReadinessLevel,
                    mrl: payload.technology.mfgReadinessLevel,
                    currentReadinessResults: payload.technology.currentReadinessResults,
                },
                technologyDocuments:
                    payload.technology.documents?.map(document => {
                        return {
                            id: document.id,
                            name: document.fileName,
                            originalFileName: document.originalFileName,
                            type: +document.technologyDocumentType,
                            isNewFile: false,
                        };
                    }) || [],
                externalLinks:
                    payload.technology.externalLinks?.map(e => {
                        return {
                            id: e.id,
                            title: e.title,
                            url: e.url,
                        };
                    }) || [],
                patents: payload.technology.patents || [],
                copyrights: payload.technology.copyrights || [],
                trademarks: payload.technology.trademarks || [],
                initialized: true,
                organizationId: payload.technology.organization.id,
            };
        }
        case SET_CALCULATOR_STORE: {
            return { ...state, calculatorStoreData: action.payload };
        }
        default:
            return state;
    }
};
