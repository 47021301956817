import React, { ReactElement, ReactNode } from 'react';

import { Grid } from '@mui/material';
import './ProfilePageLayout.scss';

interface ProfilePageLayoutProps {
    responsiveRightTopOrder?: boolean;
    leftSideAsMenu?: boolean;
    children: {
        leftSide: ReactNode;
        rightSide: ReactNode;
    };
}
export const ProfilePageLayout = ({
    children,
    leftSideAsMenu,
    responsiveRightTopOrder,
}: ProfilePageLayoutProps): ReactElement => {
    const { leftSide, rightSide } = children;
    const leftSideSpacing = leftSideAsMenu ? 4 : 8;
    const rightSideSpacing = leftSideAsMenu ? 8 : 4;
    return (
        <div className="profile-page-layout">
            <Grid container columnSpacing={2}>
                <Grid item xs={12} md={leftSideSpacing}>
                    <div>{leftSide}</div>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={rightSideSpacing}
                    sx={
                        responsiveRightTopOrder
                            ? {
                                  order: {
                                      xs: '-1',
                                      sm: '-1',
                                      md: '1',
                                      lg: '1',
                                      xl: '1',
                                  },
                              }
                            : {}
                    }
                >
                    <div>{rightSide}</div>
                </Grid>
            </Grid>
        </div>
    );
};

ProfilePageLayout.defaultProps = {
    responsiveRightTopOrder: false,
    leftSideAsMenu: false,
};

export default ProfilePageLayout;
