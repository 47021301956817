import React, { ReactElement, useState } from 'react';

import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Modal from 'components/common/modal/Modal';
import Panel, { CaretStyles, PanelStyles } from 'components/common/panel/Panel';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateAlert } from 'state/store/actions/Alert';
import { useDeleteDiscussionMutation } from '../../../typings/generated';
import ButtonGroup from '../button-group/ButtonGroup';

const useStyles = makeStyles({
    RemoveContent: {
        display: 'grid',
        gridTemplateRows: '1fr 1fr 1fr',
        height: '12em',
    },
    RemoveButton: {
        height: '2.5em',
        width: '16em',
    },
});

export const DeleteDiscussion = ({ discussionId }: { discussionId: string }): ReactElement => {
    const [modalOpen, setModalOpen] = useState(false);
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const [deleteDiscussion] = useDeleteDiscussionMutation();

    const closeModal = (): void => {
        setModalOpen(false);
    };

    const openModal = (): void => {
        setModalOpen(true);
    };

    const onDeleteDiscussion = (): void => {
        deleteDiscussion({ variables: { id: discussionId || '' } }).then(() => {
            // send toast
            dispatch(
                updateAlert({
                    visible: true,
                    message: 'Discussion removed',
                    severity: 'success',
                })
            );
            history.push('/');
        });
    };

    return (
        <>
            <>
                <Modal display={modalOpen} onClose={(): any => closeModal()}>
                    {{
                        title: <Typography variant="h2"> Are you sure you want to remove this discussion? </Typography>,
                        content: (
                            <>
                                <div style={{ fontSize: '16px' }}>
                                    Removing this discussion will delete all its associated posts and replies from{' '}
                                    {process.env.REACT_APP_NAME}.
                                </div>
                                <div style={{ fontSize: '16px', fontWeight: 'bolder', marginTop: '1em' }}>
                                    This action cannot be undone.
                                </div>
                            </>
                        ),
                        footer: (
                            <ButtonGroup justify="space-evenly">
                                <Button variant="outlined" color="primary" onClick={onDeleteDiscussion}>
                                    Remove Discussion
                                </Button>
                                <Button variant="contained" color="primary" onClick={closeModal}>
                                    Cancel
                                </Button>
                            </ButtonGroup>
                        ),
                    }}
                </Modal>
            </>
            <Panel
                title="Remove Discussion"
                panelStyle={isDesktop ? PanelStyles.NoCollapse : PanelStyles.Default}
                caretStyle={isDesktop ? CaretStyles.None : CaretStyles.Normal}
            >
                <div className={classes.RemoveContent}>
                    <Box component="p" sx={{ mt: '0.75rem' }}>
                        Removing this discussion will delete all its associated posts and replies from{' '}
                        {process.env.REACT_APP_NAME}.
                    </Box>
                    <Box component="strong" sx={{ mt: '0.75rem', fontWeight: 700 }}>
                        This action cannot be undone.
                    </Box>

                    <Button
                        className={classes.RemoveButton}
                        // type="submit"
                        data-testid="remove-discussion"
                        variant="contained"
                        color="primary"
                        onClick={openModal}
                    >
                        Remove Discussion
                    </Button>
                </div>
            </Panel>
        </>
    );
};

export default DeleteDiscussion;
