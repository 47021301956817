import { AnyAction } from 'redux';
import { OrganizationDetailsFragment } from 'typings/generated';
import {
    ADD_COMPETENCY,
    ADD_LINK,
    ADD_NAICS_CODE,
    ADD_PRINCIPAL,
    ADD_SBIR_STTR_AWARD,
    INITIALIZE_ORGANIZATION,
    REMOVE_COMPETENCY,
    REMOVE_LINK,
    REMOVE_NAICS_CODE,
    REMOVE_PRINCIPAL,
    REMOVE_SBIR_STTR_AWARD,
    RESET_ORGANIZATION,
    SET_ABOUT,
} from '../actions/Organization';

export interface AboutOrganization {
    name: string;
    description: string;
    avatar?: string;
    cageCode?: string;
    dunsNumber?: string;
}

export interface EmailDomain {
    id: string;
    domain: string;
}

// export interface OrganizationState {
//     id?: string;
//     initialized: boolean;
//     aboutOrganization: AboutOrganization;
//     externalLinks: ExternalLink[];
//     coreCompetencies: Competency[];
//     naicsCodes: NaicsCode[];
//     sbirSttrAwards: SbirSttrAward[];
//     principals: Principal[];
//     emailDomains: EmailDomain[];
// }

export interface OrganizationState extends OrganizationDetailsFragment {
    initialized: boolean;
}

const initialState: OrganizationState = {
    initialized: false,
    id: '',
    name: '',
    isCompany: false,
    city: '',
    state: '',
    createDate: undefined,
    latestActivityDate: undefined,
    viewCount: 0,
    likeCount: 0,
    OrganizationChildren: [],
    externalLinks: [],
    coreCompetencies: [],
    naicsCodes: [],
    sbirSttrAwards: [],
    principals: [],
    emailDomains: [],
};

export default (state = initialState, action: AnyAction): OrganizationState => {
    const { type } = action;
    switch (type) {
        case SET_ABOUT: {
            return {
                ...state,
                name: action.payload.name,
                description: action.payload.description,
                avatar: action.payload.avatar,
                cageCode: action.payload.cageCode,
                dunsNumber: action.payload.dunsNumber,
            };
        }
        case ADD_LINK: {
            return { ...state, externalLinks: [...(state.externalLinks || []), action.payload] };
        }
        case REMOVE_LINK: {
            return { ...state, externalLinks: state.externalLinks?.filter(l => l !== action.payload) };
        }
        case ADD_COMPETENCY: {
            return { ...state, coreCompetencies: [...(state.coreCompetencies || []), action.payload] };
        }
        case REMOVE_COMPETENCY: {
            return { ...state, coreCompetencies: state.coreCompetencies?.filter(c => c !== action.payload) };
        }
        case ADD_NAICS_CODE: {
            return { ...state, naicsCodes: [...(state.naicsCodes || []), action.payload] };
        }
        case REMOVE_NAICS_CODE: {
            return { ...state, naicsCodes: state.naicsCodes?.filter(c => c !== action.payload) };
        }
        case ADD_SBIR_STTR_AWARD: {
            return { ...state, sbirSttrAwards: [...(state.sbirSttrAwards || []), action.payload] };
        }
        case REMOVE_SBIR_STTR_AWARD: {
            return { ...state, sbirSttrAwards: state.sbirSttrAwards?.filter(s => s !== action.payload) };
        }
        case ADD_PRINCIPAL: {
            return { ...state, principals: [...(state.principals || []), action.payload] };
        }
        case REMOVE_PRINCIPAL: {
            return { ...state, principals: state.principals?.filter(p => p !== action.payload) };
        }
        case RESET_ORGANIZATION: {
            return initialState;
        }
        case INITIALIZE_ORGANIZATION: {
            const payload = action.payload as OrganizationDetailsFragment;
            return {
                ...state,
                externalLinks:
                    payload.externalLinks?.map(e => {
                        return {
                            id: e.id || '',
                            title: e.title,
                            url: e.url,
                        };
                    }) || [],
                coreCompetencies:
                    payload.coreCompetencies?.map(c => {
                        return {
                            id: c.id || '',
                            name: c.name,
                            description: c.description,
                        };
                    }) || [],
                naicsCodes:
                    payload.naicsCodes?.map(c => {
                        return {
                            id: c.id || '',
                            code: c.code,
                            descriptor: c.descriptor,
                        };
                    }) || [],
                sbirSttrAwards:
                    payload.sbirSttrAwards?.map(s => {
                        return {
                            id: s.id || '',
                            link: s.link,
                            name: s.name,
                            type: s.type,
                            year: s.year,
                            phase: s.phase,
                        };
                    }) || [],
                principals:
                    payload.principals?.map(p => {
                        return {
                            id: p.id || '',
                            name: p.name,
                            title: p.title,
                        };
                    }) || [],
                emailDomains:
                    payload.emailDomains?.map(p => {
                        return {
                            id: p.id,
                            domain: p.domain,
                        };
                    }) || [],
                initialized: true,
                ...action.payload,
            };
        }

        default:
            return state;
    }
};
