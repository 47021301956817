import React, { ReactElement } from 'react';
import { Link, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

const linkHeadingStyles: SxProps = { mt: '0.1em', fontWeight: 600, fontSize: '16px', textTransform: 'uppercase' };
const linkStyles: SxProps = { mt: '0.1em', fontSize: '16px', fontWeight: 400, textTransform: 'capitalize' };

type Props = {
    headingText: string;
    linkText: string;
    href: string;
};

const LinkWithHeading = ({ headingText, linkText, href }: Props): ReactElement => {
    return (
        <>
            <Typography variant="subtitle2" sx={{ ...linkHeadingStyles }}>
                {headingText}
            </Typography>
            <Link href={href} sx={{ ...linkStyles }}>
                {linkText}
            </Link>
        </>
    );
};

export default LinkWithHeading;
