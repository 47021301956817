import React, { ReactElement, ReactNode } from 'react';

import Sticky from 'react-sticky-el';

export interface WithStickyProps {
    children: ReactNode;
}

export const WithSticky = ({ children }: WithStickyProps): ReactElement => {
    return (
        <div>
            <div className="mobile">{children}</div>
            <div className="desktop">
                <Sticky stickyStyle={{ zIndex: 998 }} scrollElement=".router-container">
                    {children}
                </Sticky>
            </div>
        </div>
    );
};

export default WithSticky;
