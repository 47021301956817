import React, { ReactElement } from 'react';

import NavIcon from './NavIcon';

interface SignOutNavIconProps {
    overlayOn: boolean;
    opened: () => void;
}

export const SignOutNavIcon = ({ opened, overlayOn }: SignOutNavIconProps): ReactElement => {
    const onOpened = () => {
        opened();
    };
    return (
        <NavIcon
            iconTitle="Logoff"
            icon="signout"
            onOpen={onOpened}
            overlayOn={overlayOn}
            disableOverlay // comment this out to re-enable notifications
        />
    );
};

export default SignOutNavIcon;
