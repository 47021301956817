import React from 'react';

import Providers from 'components/providers/Providers';
import ReactDOM from 'react-dom';

import './index.scss';
import 'loadingOverlayUtil';

if (module.hot) {
    module.hot.accept();
}

const app = <Providers />;
if (process.env.NODE_ENV === 'development') {
    const axe = require('@axe-core/react');
    axe(React, ReactDOM, 1000, {});
    ReactDOM.render(app, document.getElementById('root'));
} else {
    ReactDOM.render(app, document.getElementById('root'));
}

// console.log(process.env.REACT_APP_TECHSCOUT_API, process.env.REACT_APP_VERSION);
