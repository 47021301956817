import React, { ReactElement, useEffect, useState } from 'react';

import { Button } from '@mui/material';

import { InviteIcon } from 'assets/icons';
import qrcode from 'assets/images/qr-code.svg';
import ButtonGroup from 'components/common/button-group/ButtonGroup';
import EmailChips from 'components/common/chips/EmailChips';
import ContentArea from 'components/common/content-area/ContentArea';
import ControllerTextField from 'components/common/form-controls/ControllerTextField';
import { ModalTitleLabel } from 'components/common/icons/LabeledIcons';
import Modal from 'components/common/modal/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateAlert } from 'state/store/actions/Alert';
import { useCreateInvitesMutation } from 'typings/generated';

import './Modals.scss';

interface InviteModalProps {
    isOpen: boolean;
    onClose?: () => void;
}

export const InviteModal = ({ isOpen, onClose }: InviteModalProps): ReactElement => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [emails, setEmails] = useState<string[]>([]);
    const [resetChips, setResetChips] = useState(false);
    const [showModal, toggleShowModal] = useState(isOpen);

    const { handleSubmit, control, errors, reset } = useForm({
        mode: 'onChange',
    });

    const [createInvites] = useCreateInvitesMutation();

    function closeModal(): void {
        toggleShowModal(false);
        if (onClose) {
            onClose();
        }
    }

    useEffect(() => {
        toggleShowModal(isOpen);
    }, [isOpen]);

    const handleOnInvitationsSent = (): void => {
        reset();
        setEmails([]);
        setResetChips(true);
        closeModal();
    };

    const handleQRCodeView = () => {
        history.push('/qr-code');
        closeModal();
    };

    const onSubmit = (values: any): void => {
        const postValues = {
            message: values.message,
            emailAddressList: emails.join(','),
        };
        createInvites({ variables: { data: { ...postValues } } }).then(() => handleOnInvitationsSent());
        dispatch(
            updateAlert({
                visible: true,
                message: 'Thank you for inviting others.',
                severity: 'success',
            })
        );
    };

    return (
        <Modal display={showModal} onClose={(): any => closeModal()} contentLabel="Invite Member">
            {{
                title: (
                    <div className="title-wrapper">
                        <ModalTitleLabel icon={<InviteIcon />} label="Invite Members" />
                        <button className="qr-code-container" data-testid="qrcode" onClick={() => handleQRCodeView()}>
                            QR Code
                            <img className="qr-code" src={qrcode} alt={`${process.env.REACT_APP_NAME} qr code`} />
                        </button>
                    </div>
                ),
                content: (
                    <div className="main-wrapper" id="app-base">
                        <div className="email-modal-body">
                            <div className="body-main">
                                Grow your network by inviting peers and colleagues to {process.env.REACT_APP_NAME}!
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                <EmailChips reset={resetChips} handler={(e: string[]): void => setEmails(e)} />
                                <ContentArea title="Message" includeBottomBoxShadow={false}>
                                    <ControllerTextField
                                        defaultValue=""
                                        multiline
                                        control={control}
                                        errors={errors}
                                        name="message"
                                        label={`Tell your invitee why they should join the ${process.env.REACT_APP_NAME} community!`}
                                        maxLength={500}
                                        showCharacterCount
                                    />
                                </ContentArea>
                                <ButtonGroup>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        data-testid="cancel"
                                        className="button-cancel"
                                        onClick={(): any => closeModal()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        data-testid="invite"
                                        className="button-submit"
                                        type="submit"
                                        disabled={emails.length === 0}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Invite
                                    </Button>
                                </ButtonGroup>
                            </form>
                        </div>
                    </div>
                ),
            }}
        </Modal>
    );
};

InviteModal.defaultProps = {
    onClose: null,
};

export default InviteModal;
