import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

import { Box, SxProps } from '@mui/material';

import ReactModal from 'react-modal';

import './Modal.scss';

interface Props {
    display: boolean;
    children: {
        content: ReactNode;
        footer?: ReactNode;
        title?: ReactNode;
    };
    onClose?: () => void;
    contentLabel?: string;
    footerStyles?: SxProps;
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '667px', // for min mobile and max desktop
        minWidth: '375px', // for min mobile and max desktop
        overflow: 'auto',
    },
};

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root');
ReactModal.defaultStyles = {
    ...ReactModal.defaultStyles,
    overlay: {
        ...ReactModal.defaultStyles.overlay,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
};

export const Modal = ({ display, children, onClose, contentLabel, footerStyles }: Props): ReactElement => {
    const { content, footer, title } = children;
    const [modalIsOpen, setIsOpen] = useState(display);

    const closeModal = () => {
        setIsOpen(false);
        if (onClose) {
            onClose();
        }
    };

    useEffect(() => {
        setIsOpen(display);
    }, [display]);

    return (
        <ReactModal
            data-test-id="modal"
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={false}
            style={customStyles}
            contentLabel={contentLabel}
        >
            <Box sx={{ my: '0.5rem', width: { xs: 'auto', md: '500px' } }} className="modal-container">
                {title && (
                    <Box sx={{ mt: '0.5rem' }} className="title">
                        {title}
                    </Box>
                )}
                <Box sx={{ my: '0.5rem' }} className="content">
                    {content}
                </Box>

                {footer && (
                    <Box
                        sx={{
                            m: '10px',
                            '&button': { m: '10px' },
                            display: 'flex',
                            marginBottom: '20px',
                            ...footerStyles,
                        }}
                        className="footer"
                    >
                        {footer}
                    </Box>
                )}
            </Box>
        </ReactModal>
    );
};

Modal.defaultProps = {
    onClose: null,
    contentLabel: 'Example Modal',
    footerStyles: {},
};

export default Modal;
