import React, { ReactElement, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { FeedbackIcon } from 'assets/icons';
import ButtonGroup from 'components/common/button-group/ButtonGroup';
import ContentArea from 'components/common/content-area/ContentArea';
import ControllerTextField from 'components/common/form-controls/ControllerTextField';
import ControllerSelectField from 'components/common/form-controls/ControllerSelectField';
import { ModalTitleLabel } from 'components/common/icons/LabeledIcons';
import Modal from 'components/common/modal/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateAlert } from 'state/store/actions/Alert';
import { useCreateFeedbackMutation } from 'typings/generated';

import './Modals.scss';

interface FeedbackModalProps {
    isOpen: boolean;
    onClose?: () => void;
}

export const FeedbackModal = ({ isOpen, onClose }: FeedbackModalProps): ReactElement => {
    const [showModal, toggleShowModal] = useState(isOpen);
    const dispatch = useDispatch();
    const [type, setType] = useState<string | null>('general');
    // const [feedback, setFeedback] = useState<string | null>('');

    const { handleSubmit, control, errors, formState, reset } = useForm({
        mode: 'onChange',
    });
    const { isValid } = formState;

    const [createFeedback] = useCreateFeedbackMutation();

    function closeModal(): void {
        toggleShowModal(false);
        if (onClose) {
            onClose();
        }
    }

    useEffect(() => {
        toggleShowModal(isOpen);
    }, [isOpen]);

    const handleOnFeedbackSent = (): void => {
        reset();
        closeModal();
    };

    const onSubmit = (dataValues: any): void => {
        const postValues = {
            message: dataValues.feedback || '',
            type: type || '',
            location: window.location.pathname,
        };
        createFeedback({ variables: { data: { ...postValues } } }).then(() => handleOnFeedbackSent());
        dispatch(
            updateAlert({
                visible: true,
                message: 'Thank you for your feedback',
                severity: 'success',
            })
        );
    };

    const handleTypeChange = (e: any): void => {
        setType(e.target.value);
    };

    return (
        <Modal display={showModal} onClose={(): any => closeModal()} contentLabel="Give Feedback">
            {{
                title: (
                    <div className="title-wrapper">
                        <ModalTitleLabel icon={<FeedbackIcon />} label="Give Feedback" />
                    </div>
                ),
                content: (
                    <div className="main-wrapper" id="app-base">
                        <div className="email-modal-body">
                            <div className="body-main">
                                Please provide constructive feedback based on your experience of{' '}
                                {process.env.REACT_APP_NAME}. We value our members input and will use it to improve this
                                product.
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                <ContentArea>
                                    <Box sx={{ minWidth: 120, '.MuiFormLabel-root': { fontSize: '1rem' } }}>
                                        <ControllerSelectField
                                            label="Select type"
                                            value={type || ''}
                                            onChange={handleTypeChange}
                                            data-testid="type"
                                            name="type"
                                            defaultValue="General Feedback"
                                            control={control}
                                            errors={errors}
                                            fullWidth
                                        >
                                            <MenuItem value="general">General Feedback</MenuItem>
                                            <MenuItem value="bug">Bug</MenuItem>
                                        </ControllerSelectField>
                                    </Box>
                                </ContentArea>
                                <ContentArea title="Feedback" includeBottomBoxShadow={false}>
                                    <ControllerTextField
                                        multiline
                                        defaultValue=""
                                        control={control}
                                        errors={errors}
                                        data-testid="message"
                                        rules={{ required: true }}
                                        name="feedback"
                                        label="Please describe as much detail about the problem below."
                                        maxLength={1000}
                                        showCharacterCount
                                    />
                                </ContentArea>
                                <ButtonGroup>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        data-testid="cancel"
                                        className="button-cancel"
                                        onClick={(): any => closeModal()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        data-testid="send"
                                        className="button-submit"
                                        type="submit"
                                        disabled={!isValid}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Send
                                    </Button>
                                </ButtonGroup>
                            </form>
                        </div>
                    </div>
                ),
            }}
        </Modal>
    );
};

FeedbackModal.defaultProps = {
    onClose: null,
};

export default FeedbackModal;
