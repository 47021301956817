import { AnyAction } from 'redux';
import { SET_BLOCK_LINK, RESET_BLOCK_LINK } from '../actions/Blocks';

export interface BlockState {
    blockLink: string;
    blockType: 'Discussion' | 'Technology' | 'Organization' | 'Post' | 'User' | '';
}

const initialState: BlockState = {
    blockLink: '',
    blockType: '',
};

export default (state = initialState, action: AnyAction): BlockState => {
    const { type, payload } = action;
    switch (type) {
        case SET_BLOCK_LINK: {
            const updatedBlocks = {
                ...state,
                blockLink: payload.blockLink,
                blockType: payload.blockType,
            };
            return updatedBlocks;
        }
        case RESET_BLOCK_LINK: {
            const updatedBlocks = {
                ...state,
                blockLink: '',
            };
            return updatedBlocks;
        }
        default:
            return state;
    }
};
