import React, { ReactElement, useState } from 'react';

import { Button, Typography } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';

import BasicCard from 'components/common/cards/basic-cards/basic-card';
import Modal from 'components/common/modal/Modal';
import { linkTo } from 'components/providers/ReactRouter';
import { upperFirst } from 'lodash-es';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { UserState } from 'state/store/reducers/User';
import { hasCapability, hasRole } from 'utilities/utils';
import ButtonGroup from './components/common/button-group/ButtonGroup';

interface PrivateRouteProps {
    user: UserState;
    component: any;
    message?: any;
    topic?: any;
    requiresAdmin?: boolean;
    loginModal?: boolean;
    requiresGovt?: boolean;
    exact?: boolean;
    capabilities?: string[];
    callback?: (props: any) => boolean;
    icon?: ReactElement;
    path: string;
    community?: boolean;
}

const PrivateRoute = ({
    user,
    component: Component,
    loginModal,
    message,
    topic,
    icon,
    requiresAdmin,
    requiresGovt,
    community,
    ...rest
}: PrivateRouteProps) => {
    const { keycloak, initialized } = useKeycloak();
    const isLoggedIn = (keycloak && keycloak.authenticated) || false;
    const [modalVisible, setModalVisible] = useState(true);
    const baseUri = window.location.origin;
    const history = useHistory();

    const closeModal = (): void => {
        history.push('/');
        setModalVisible(false);
    };

    const loginModalEl = (props: any): ReactElement => {
        return (
            <Modal display={modalVisible} onClose={(): any => closeModal()}>
                {{
                    title: <Typography variant="h2">To view and engage in {topic}, sign up now!</Typography>,
                    content: (
                        <BasicCard missingAvatar={icon} title={`${upperFirst(topic)} on ${process.env.REACT_APP_NAME}`}>
                            {message}
                        </BasicCard>
                    ),
                    footer: (
                        <ButtonGroup justify="space-evenly">
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={(): any =>
                                    keycloak.login({
                                        redirectUri: `${baseUri}/${props.location.pathname}`,
                                    })
                                }
                                data-testid="login-button"
                            >
                                Login
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                data-testid="register-button"
                                onClick={(): any => keycloak.register()}
                            >
                                Sign Up
                            </Button>
                        </ButtonGroup>
                    ),
                }}
            </Modal>
        );
    };

    const displayRoute = (props: any): React.ReactNode => {
        if (initialized && !isLoggedIn) {
            if (loginModal) {
                return loginModalEl(props);
            }
            return (
                <div className="not-logged-in">
                    {initialized && keycloak && keycloak.login({ redirectUri: window.location.href })}
                </div>
            );
        }

        const { capabilities, callback } = rest;
        if (requiresAdmin) {
            if (!hasRole(user, ['Super Admin', 'Moderator'])) {
                history.push(linkTo.admin.unauthorized);
            }
        }
        if (requiresGovt) {
            if (!user.isGovernment) {
                history.push('/');
            }
        }
        let communityRedirectUrl;
        if (community) {
            communityRedirectUrl = linkTo.community.view.root(props.match.params.id);
        }

        if (callback) {
            return callback(props) ? <Component {...props} /> : <Redirect to={communityRedirectUrl || '/'} />;
        }

        if (!capabilities) {
            return <Component {...props} />;
        }

        return hasCapability(user, capabilities) ? <Component {...props} /> : <Redirect to="/" />;
    };

    return <Route {...rest} render={props => displayRoute(props)} />;
};
PrivateRoute.defaultProps = {
    requiresAdmin: false,
    requiresGovt: false,
    exact: false,
    loginModal: false,
    capabilities: undefined,
    callback: undefined,
    topic: undefined,
    icon: undefined,
    message: undefined,
    community: false,
};
export default React.memo(PrivateRoute);
