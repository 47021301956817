import React, { ReactElement, ReactNode } from 'react';

import { Badge, BadgePropsColorOverrides } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { ExpandIcon } from 'assets/icons';

import './MobileNavItem.scss';

export interface MobileNavItemProps {
    icon?: any;
    customIcon?: string;
    title: string | ReactNode;
    handler: () => void;
    datatestid?: string;
    badgeCount?: number;
    color?: OverridableStringUnion<
        'primary' | 'secondary' | 'default' | 'error' | 'info' | 'success' | 'warning',
        BadgePropsColorOverrides
    >;
}

export const MobileNavItem = ({
    handler,
    icon,
    customIcon,
    title,
    datatestid,
    badgeCount,
    color,
}: MobileNavItemProps): ReactElement => {
    const className = `mobile-nav-item ${icon ? '' : 'no-icon'}`;
    return (
        <div className={`mobile-nav-item-container ${customIcon ? 'custom-icons' : ''}`} data-testid={datatestid}>
            <div role="button" tabIndex={0} onClick={handler} onKeyPress={handler} className={className}>
                {icon ? (
                    <>
                        {badgeCount && badgeCount > 0 ? (
                            <Badge badgeContent={badgeCount} color={color} max={99}>
                                {icon}
                            </Badge>
                        ) : (
                            <>{icon}</>
                        )}
                    </>
                ) : (
                    <>
                        {customIcon && (
                            <div className="custom-icon">
                                <img src={`/custom-icons/${customIcon}`} alt={customIcon} />
                            </div>
                        )}
                    </>
                )}
                <>
                    <div className="burger-button">{title}</div>
                    <div className="menu-chevron">
                        <ExpandIcon data-testid="chevron-icon" />
                    </div>
                </>
            </div>
        </div>
    );
};
MobileNavItem.defaultProps = {
    icon: undefined,
    customIcon: undefined,
    datatestid: undefined,
    badgeCount: undefined,
    color: undefined,
};

MobileNavItem.defaultProps = {
    icon: null,
    customIcon: '',
    datatestid: '',
    badgeCount: 0,
    color: '',
};

export default MobileNavItem;
