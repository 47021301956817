import React, { ReactElement, ReactNode } from 'react';

import { Box } from '@mui/material';

import './Page.scss';

export interface PageProps {
    title?: ReactNode;
    styleTitle?: boolean;
    children: ReactNode;
    singlePage?: boolean;
}
export const Page = ({ title, children, singlePage, styleTitle }: PageProps): ReactElement => {
    const pageContainer = singlePage ? 'page-container single-page' : 'page-container';
    return (
        <Box mt={3} pb={3} className={pageContainer}>
            {!singlePage && <div className={styleTitle ? 'page-container_title' : ''}>{title}</div>}
            <div className="page-container_body">{children}</div>
        </Box>
    );
};

Page.defaultProps = {
    singlePage: false,
    styleTitle: true,
    title: '',
};
export default Page;
